.faq-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 36px;
}

.faq-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #e2dfcf;
}

.faq-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.faq-item {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.faq-question {
  width: 100%;
  padding: 15px;
  background: #e2dfcf;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.faq-question:hover {
  background: #e8e8e8;
}

.faq-icon {
  color: #666;
}

.faq-answer {
  padding: 15px;
  background: white;
  font-size: 16px;
  color: #333;
  border-top: 1px solid #ccc;
  text-align: start;
}

@media (max-width: 768px) {
  .faq-container {
    padding: 36px;
width: 100%;
  }

  .faq-title {
    font-size: 20px;
    text-align: start;
  }

  .faq-question {
    font-size: 16px;
    padding: 12px;
    text-align: start;
  }

  .faq-answer {
    font-size: 14px;
    padding: 12px;
  }
}
