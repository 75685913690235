@media only screen and (max-width: 480px) and (orientation: portrait) {
  .viewinsight {
    width: 100%;
    height: auto;
  }

  .insight__section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px 20px;
  }

  .insight__selectors {
    display: none;
  }

  .insight__selectors .insight__help {
    display: none;
  }

  .insight__selectors .button__div {
    display: none;
  }

  .insight__selectors button {
    display: none;
  }

  .insight__div {
    display: none;
  }

  .insight__div::before {
    display: none;
  }

  .insight__div:hover::before {
    display: none;
  }

  .insight__options {
    display: none;
  }

  .insight__options button {
    display: none;
  }

  .insight__graphs {
    display: none;
  }

  .insight__graphs__block {
    display: none;
  }

  .insight__story {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: start;
    margin-bottom: 24px;
  }

  .insight__buttons {
    display: none;
  }

  .button--square {
    display: none;
  }

  .insights__signals {
    display: none;
  }

  .insights__signals .little_square {
    display: none;
  }
}

@media only screen and (min-width: 481px) and (max-width: 767px) and (orientation: portrait) {
  .viewinsight {
    width: 100%;
    height: auto;
  }

  .insight__section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px 20px;
  }

  .insight__selectors {
    display: none;
  }

  .insight__selectors .insight__help {
    display: none;
  }

  .insight__selectors .button__div {
    display: none;
  }

  .insight__selectors button {
    display: none;
  }

  .insight__div {
    display: none;
  }

  .insight__div::before {
    display: none;
  }

  .insight__div:hover::before {
    display: none;
  }

  .insight__options {
    display: none;
  }

  .insight__options button {
    display: none;
  }

  .insight__graphs {
    display: none;
  }

  .insight__graphs__block {
    display: none;
  }

  .insight__story {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: start;
    margin-bottom: 24px;
  }

  .insight__buttons {
    display: none;
  }

  .button--square {
    max-height: 50px;
    max-width: 50px;
  }

  .insights__signals {
    display: none;
  }

  .insights__signals .little_square {
    display: none;
  }
}

@media only screen and (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {
  .viewinsight {
    width: 100%;
    height: 100vh;
    margin-bottom: 72px;
  }

  .insight__section {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(20, 38px);
  }

  .insight__selectors {
    display: none;
  }

  .insight__selectors .insight__help {
    display: none;
  }

  .insight__selectors .button__div {
    display: none;
  }

  .insight__selectors button {
    display: none;
  }

  .insight__div {
    display: none;
  }

  .insight__div::before {
    display: none;
  }

  .insight__div:hover::before {
    display: none;
  }

  .insight__options {
    display: none;
  }

  .insight__options button {
    display: none;
  }

  .insight__graphs {
    grid-row: 1 / 12;
    grid-column: 1 / 21;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6px;
    border-radius: 5px;
    background-color: #004a22;
  }

  .insight__graphs__block {
    background-color: #004a22;
    border-radius: 5px;
    padding: 0 12px 24px 12px;
    width: 100%;
    height: 388px;
    z-index: 1000;
  }

  .insight__story {
    display: none;
  }

  .insight__buttons {
    display: none;
  }

  .insight__div .insight__buttons:hover {
    display: none;
  }

  .button--square {
    max-height: 50px;
    max-width: 50px;
  }

  .insights__signals {
    grid-row: 8 / 11;
    grid-column: 1 / 21;
    background-color: #004a22;
    padding: 12px;
    border-radius: 5px;
    margin: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #e2dfcf;
  }

  .insights__signals .little_square {
    display: inline-block;
    height: 12.5px;
    width: 12.5px;
    background-color: rgba(103, 36, 131, 1);
    border: 1px solid #e2dfcf;
    border-radius: 1.25px;
    margin: 0 2.5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .viewinsight {
    width: 100%;
    height: auto;
  }

  .insight__section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 8px 20px;
  }

  .insight__selectors {
    display: none;
  }

  .insight__selectors .insight__help {
    display: none;
  }

  .insight__selectors .button__div {
    display: none;
  }

  .insight__selectors button {
    display: none;
  }

  .insight__div {
    display: none;
  }

  .insight__div::before {
    display: none;
  }

  .insight__div:hover::before {
    display: none;
  }

  .insight__options {
    display: none;
  }

  .insight__options button {
    display: none;
  }

  .insight__graphs {
    display: none;
  }

  .insight__graphs__block {
    display: none;
  }

  .insight__story {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: start;
    margin-bottom: 24px;
  }

  .insight__buttons {
    display: none;
  }

  .button--square {
    max-height: 50px;
    max-width: 50px;
  }

  .insights__signals {
    display: none;
  }

  .insights__signals .little_square {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .viewinsight {
    width: 100%;
    height: auto;
    display: flex;
  }

  .insight__section {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(20, 38px);
  }

  .insight__selectors {
    display: none;
  }

  .insight__selectors .insight__help {
    display: none;
  }

  .insight__selectors .button__div {
    display: none;
  }

  .insight__selectors button {
    display: none;
  }

  .insight__div {
    display: none;
  }

  .insight__div::before {
    display: none;
  }

  .insight__div:hover::before {
    display: none;
  }

  .insight__options {
    display: none;
  }

  .insight__options button {
    display: none;
  }

  .insight__graphs {
    grid-row: 1 / 13;
    grid-column: 1 / 21;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 12px;
  }

  .insight__graphs__block {
    background-color: #004a22;
    border-radius: 5px;
    padding: 12px;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }

  .insight__story {
    display: none;
  }

  .insight__buttons {
    display: none;
  }

  .insight__div .insight__buttons:hover {
    display: none;
  }

  .button--square {
    max-height: 50px;
    max-width: 50px;
  }

  .insights__signals {
    grid-row: 13 / 15;
    grid-column: 1 / 21;
    background-color: #004a22;
    padding: 12px;
    border-radius: 5px;
    margin: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #e2dfcf;
  }

  .insights__signals .little_square {
    display: inline-block;
    height: 12.5px;
    width: 12.5px;
    background-color: rgba(103, 36, 131, 1);
    border: 1px solid #e2dfcf;
    border-radius: 1.25px;
    margin: 0 2.5px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) and (orientation: landscape) {
  .viewinsight {
    width: 100%;
    height: 100vh;
  }

  .insight__section {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(20, 38px);
  }

  .insight__selectors {
    grid-row: 3 / 15;
    grid-column: 2 / 4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding: 12px;
  }

  .insight__selectors.tablet {
    display: none;
  }

  .insight__selectors .insight__help {
    width: 100%;
    max-height: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 12px;
    background-color: #004a22;
    color: #e2dfcf;
    border-radius: 5px;
  }

  .insight__selectors .button__div {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .insight__selectors button {
    margin-top: 12px;
  }

  .insight__div {
    position: relative;
    display: inline-block;
  }

  .insight__div::before {
    content: attr(data-tooltip);
    position: absolute;
    left: -175%;
    bottom: 25%;
    background-color: #004a22;
    color: #e2dfcf;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }

  .insight__div:hover::before {
    opacity: 1;
  }

  .insight__options {
    grid-row: 1 / 3;
    grid-column: 4 / 20;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px;
  }

  .insight__options.tablet {
    display: none;
  }

  .insight__options button {
    margin-right: 12px;
  }

  .insight__graphs.tablet {
    grid-row: 1 / 13;
    grid-column: 1 / 21;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 12px;
  }

  .insight__graphs.desktop {
    grid-row: 3 / 15;
    grid-column: 4 / 20;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 12px;
  }

  .insight__graphs__block {
    background-color: #004a22;
    border-radius: 5px;
    padding: 12px;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }

  .insight__story.desktop {
    display: none;
  }

  .insight__story.tablet {
    display: none;
  }

  .insight__buttons {
    min-height: 50px;
    min-width: 50px;
    padding: 12px;
    border-radius: 5px;
    background-color: #004a22;
    color: #e2dfcf;
    border: none;
    box-shadow: none;
  }

  .insight__div .insight__buttons:hover {
    min-height: 50px;
    min-width: 50px;
    padding: 12px;
    border-radius: 5px;
    background-color: #e2dfcf;
    border: none;
    box-shadow: none;
  }

  .button--square {
    max-height: 50px;
    max-width: 50px;
  }

  .insights__signals {
    grid-row: 13 / 15;
    grid-column: 1 / 21;
    background-color: #004a22;
    padding: 12px;
    border-radius: 5px;
    margin: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #e2dfcf;
  }

  .insights__signals .little_square {
    display: inline-block;
    height: 12.5px;
    width: 12.5px;
    background-color: rgba(103, 36, 131, 1);
    border: 1px solid #e2dfcf;
    border-radius: 1.25px;
    margin: 0 2.5px;
  }
}

@media only screen and (min-width: 1201px) {
  .viewinsight {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .insight__section {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(20, 38px);
  }

  .insight__selectors {
    grid-row: 3 / 15;
    grid-column: 2 / 4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding: 12px;
  }

  .insight__selectors.tablet {
    display: none;
  }

  .insight__selectors .insight__help {
    width: 100%;
    max-height: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 12px;
    background-color: #004a22;
    color: #e2dfcf;
    border-radius: 5px;
  }

  .insight__selectors .button__div {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .insight__selectors button {
    margin-top: 12px;
  }

  .insight__div {
    position: relative;
    display: inline-block;
  }

  .insight__div::before {
    content: attr(data-tooltip);
    position: absolute;
    left: -175%;
    bottom: 25%;
    background-color: #004a22;
    color: #e2dfcf;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }

  .insight__div:hover::before {
    opacity: 1;
  }

  .insight__options {
    grid-row: 1 / 3;
    grid-column: 4 / 21;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px;
    overflow-x: auto;
  }

  .insight__options.tablet {
    display: none;
  }

  .insight__options button {
    margin-right: 12px;
  }

  .insight__graphs {
    grid-row: 3 / 15;
    grid-column: 4 / 15;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 12px;
  }

  .insight__graphs.tablet {
    grid-row: 1 / 13;
    grid-column: 1 / 21;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 12px;
  }

  .insight__graphs__block {
    background-color: #004a22;
    border-radius: 5px;
    padding: 12px;
    width: 100%;
    height: 100%;
    z-index: 1000;
  }

  .insight__story {
    grid-row: 3 / 15;
    grid-column: 15 / 20;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 12px;
  }

  .insight__story.tablet {
    display: none;
  }

  .insight__buttons {
    min-height: 50px;
    min-width: 50px;
    padding: 12px;
    border-radius: 5px;
    background-color: #004a22;
    color: #e2dfcf;
    border: none;
    box-shadow: none;
  }

  .insight__div .insight__buttons:hover {
    min-height: 50px;
    min-width: 50px;
    padding: 12px;
    border-radius: 5px;
    background-color: #e2dfcf;
    border: none;
    box-shadow: none;
  }

  .button--square {
    max-height: 50px;
    max-width: 50px;
  }

  .insights__signals {
    grid-row: 15 / 17;
    grid-column: 4 / 15;
    background-color: #004a22;
    padding: 12px;
    border-radius: 5px;
    margin: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #e2dfcf;
  }

  .insights__signals.tablet {
    grid-row: 13 / 15;
    grid-column: 1 / 21;
    background-color: #004a22;
    padding: 12px;
    border-radius: 5px;
    margin: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #e2dfcf;
  }

  .insights__signals .little_square {
    display: inline-block;
    height: 12.5px;
    width: 12.5px;
    background-color: rgba(103, 36, 131, 1);
    border: 1px solid #e2dfcf;
    border-radius: 1.25px;
    margin: 0 2.5px;
  }
}
