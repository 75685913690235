@media (max-width: 480px) {
}

@media (min-width: 481px) and (max-width: 767px) {
}

@media (min-width: 768px) and (max-width: 1024px) {
}

@media (min-width: 1025px) and (max-width: 1200px) {
}

@media (min-width: 1201px) {
  .survey__main {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: aqua;
  }

  .survey__matrix {
  }
}
