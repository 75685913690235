@media (max-width: 480px) {
  #login-screen {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 24px 0;
  }

  .loginContainer {
    width: 90%;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  text-align: start;
    padding: 16px 20px; 
    border-radius: 5px;
    box-shadow: -15px 15px 15px rgba(0,0,0,0.05);
    z-index: 1;
  }
  
  .loginContainer h3, 
  .loginContainer span a {
    color: #004a22;
  }
  
  .loginContainer span {
    margin-top: 12px;
    opacity: 0.5;
    font-size: 0.7em;
  }

  .loginButton {
    background-color:#004a22;
    color: #e2dfcf;
    border: 1px solid #004a22;
    padding: 3px 6px;
    border-radius: 5px;
  }
  
}

@media (min-width: 481px) and (max-width: 767px) {
  #login-screen {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 24px 0;
  }

  .loginContainer {
    width: 90%;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  text-align: start;
    padding: 16px 20px; 
    border-radius: 5px;
    box-shadow: -15px 15px 15px rgba(0,0,0,0.05);
    z-index: 1;
  }
  
  .loginContainer h3, 
  .loginContainer span a {
    color: #004a22;
  }
  
  .loginContainer span {
    margin-top: 12px;
    opacity: 0.5;
    font-size: 0.7em;
  }

  .loginButton {
    background-color:#004a22;
    color: #e2dfcf;
    border: 1px solid #004a22;
    padding: 3px 6px;
    border-radius: 5px;
  }
  
}

@media (min-width: 768px) and (max-width: 1024px) {
  #login-screen {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 24px 0;
  }

  .loginContainer {
    width: 90%;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  text-align: start;
    padding: 16px 20px; 
    border-radius: 5px;
    box-shadow: -15px 15px 15px rgba(0,0,0,0.05);
    z-index: 1;
  }
  
  .loginContainer h3, 
  .loginContainer span a {
    color: #004a22;
  }
  
  .loginContainer span {
    margin-top: 12px;
    opacity: 0.5;
    font-size: 0.7em;
  }

  .loginButton {
    background-color:#004a22;
    color: #e2dfcf;
    border: 1px solid #004a22;
    padding: 3px 6px;
    border-radius: 5px;
  }
  
}

@media (min-width: 1025px) and (max-width: 1200px) {
/* Login */
#login-screen {
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginContainer {
  height: 388px;
  width: 388px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
text-align: start;
  padding: 16px 20px; 
  border-radius: 5px;
  box-shadow: -15px 15px 15px rgba(0,0,0,0.05);
  z-index: 1;
}

.loginContainer h3, 
.loginContainer span a {
  color: #004a22;
}

.loginContainer span {
  margin-top: 12px;
  opacity: 0.5;
  font-size: 0.7em;
}


.loginButton {
  background-color:#004a22;
  color: #e2dfcf;
  border: 1px solid #004a22;
  padding: 2px 6px;
  border-radius: 5px;
}

}

@media (min-width: 1201px) {
/* Login */
#login-screen {
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginContainer {
  height: 388px;
  width: 388px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
text-align: start;
  padding: 16px 20px; 
  border-radius: 5px;
  box-shadow: -15px 15px 15px rgba(0,0,0,0.05);
  z-index: 1;
}

.loginContainer h3, 
.loginContainer span a {
  color: #004a22;
}

.loginContainer span {
  margin-top: 12px;
  opacity: 0.5;
  font-size: 0.7em;
}


.loginButton {
  background-color:#004a22;
  color: #e2dfcf;
  border: 1px solid #004a22;
  padding: 2px 6px;
  border-radius: 5px;
}

}