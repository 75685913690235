@media (max-width: 767px) {

  .tooltip__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  align-items:center ;
  }

    .tooltip__container {
        width: 95%;
        height: auto;
        background-color: rgba(0,74,34,1);
        padding: 24px 12px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 10px 10px rgba(0, 74,34, 0.2);
    }

    .tooltip--icon {
      height: 36px;
      width: 36px;
      transform: translateY(-24px)
    }

    .tooltip--icon--close {
    
        height: 36px;
        width: 36px;
        transform: translate(96px, -48px)
 
    }

    .sickTooltip {
        width: auto;
        font-weight: 700;
        color: #e2dfcf;
        opacity: 1;
      }
      .tooltip--grid{
        display: inline-flex;
        flex-direction: column;
        opacity: 1;
        background-color: rgba(0,74,34,1);
        padding: 24px;
        border-radius: 20px;
      }
    
      .tooltip--column {
        height: 100%;
        padding-bottom: 24px;
      }
    
      .tooltip--row-top {
        width: 100%;
        height: 20%;
     display: flex;
     font-weight: 700;
     color: #e2dfcf;
      }
    
      .tooltip--row-bottom {
        width: 100%;
        height: auto;
     display: flex;
     color: #e2dfcf;
      }
}

@media (min-width: 768px) {

.tooltip__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
align-items:flex-end;
}
    .tooltip__container {
        width: auto;
        height: auto;
        padding: 24px;
        background-color: rgba(0,74,34,1);
        border-radius: 8px;
        box-shadow: 0 10px 10px rgba(0, 74,34, 0.2);
    }

    .tooltip--icon {
      height: 36px;
      width: 36px;
      transform: translate(-24px, -24px)
    }

    .sickTooltip {
        width: auto;
        font-weight: 700;
        color: #e2dfcf;
        opacity: 1;
      }
    
      p.sickTooltip   {
        width: auto;
        color: #e2dfcf;
        opacity: 1;
      }

      .tooltip--grid{
        display: inline-flex;
        opacity: 1;
        background-color: rgba(0,74,34,1);
        padding: 24px;
        border-radius: 20px;
      }
    
      .tooltip--column {
        height: 100%;
      }
    
      .tooltip--row-top {
        width: 100%;
        height: 20%;
     display: flex;
     font-weight: 700;
     color: #e2dfcf;
      }
    
      .tooltip--row-bottom {
        width: 100%;
        height: auto;
     display: flex;
     color: #e2dfcf;
      }

}