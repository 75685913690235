@media (max-width: 480px) {
  .introStageMain {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 12px;
  }

  .introContainer {
    height: auto;
    background-color: #f5f5f5;
    color: #004a22;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    padding: 16px 20px;
    border-radius: 5px;
    margin-bottom: 24px;
    border: 2px solid #004a22;
  }

  .menuRow {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 6px;
  }

  .debriefMenu {
    height: auto;
    width: auto;
    display: grid;
    grid-template-columns: repeat(2, 125px); /* Changed the column definition */
    grid-template-rows: repeat(20, 125px);
    row-gap: 12px;
    column-gap: 12px;
  }

  .debriefMenu .card {
    background-color: #004a22;
    color: #e2dfcf;
    margin: 6px;
    padding: 6px 8px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .explanationContainer,
  .specificityContainer,
  .finalDisplayContainer {
    height: 60vh;
    overflow: scroll;
    background-color: whitesmoke;
    color: #004a22;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    padding: 16px 20px;
    border-radius: 5px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: flex-start;
    margin-bottom: 24px;
    border: 2px solid #004a22;
  }

  .stepsList {
    margin-top: 12px;
    list-style-type: none;
    padding: 0;
  }

  .foundationContainer,
  .consistencyContainer,
  .experienceContainer {
    background-color: whitesmoke;
    color: #004a22;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    padding: 16px 20px;
    border-radius: 5px;
    justify-content: center;
    text-align: start;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;
    border: 2px solid #004a22;
  }

  .vpTextInput {
    padding-left: 12px;
    font-size: 1.5em;
    height: auto;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    text-indent: 6px;
    width: 100%;
  }

  .vpTextInput::placeholder {
    font-weight: 200;
    color: #004a22;
  }

  .vpTextInput:focus,
  .vpTextInput:active {
    font-size: 1.5em;
    height: auto;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    padding-left: 6px;
  }

  .fourFiveDiv {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 6px 0;
  }

  .fourFiveDiv textarea {
    height: 15vh;
    width: 100%;
  }

  .examplesButton {
    background-color: #004a22;
    color: #f5f5f5;
    font-size: 0.75em;
    border: 1px solid #004a22;
    margin-bottom: 12px;
    margin-top: 24px;
    border-radius: 5px;
    padding: 2px 4px;
  }

  .explanationButton,
  .valuesButton {
    background-color: #004a22;
    color: #e2dfcf;
    font-size: 0.75em;
    border: 1px solid #004a22;
    margin: 12px 0;
    border-radius: 5px;
    padding: 4px 6px;
    transition:
      padding 0.5s ease-in-out,
      background-color 0.5s ease-out;
  }

  .valuesIntBox.mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: flex-start;
  }

  .valueExplainer {
    width: 100%;
    white-space: normal;
    padding-left: 12px;
    font-size: 1.2em;
    font-weight: 200;
    height: auto;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    text-indent: 6px;
    width: 100%;
  }

  .valueExplainer:focus,
  .valueExplainer:active {
    font-size: 1.2em;
    font-weight: 200;
    height: auto;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    padding-left: 6px;
  }

  .valueExplainer::placeholder {
    font-weight: 200;
    color: #004a22;
  }

  .debriefContainer {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .debriefRow_Cols {
    height: 65vh;
    width: 100%;
    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .debriefRow_buttons {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: space-between;
    align-items: center;
  }

  .debriefRow_title {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: auto;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .debriefRow_title div {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 12px;
  }

  .debriefRow_title div:last-child {
    margin-bottom: 24px;
  }

  .debriefRow_title label {
    white-space: normal;
    width: fit-content;
    font-weight: bold;
    color: #004a22;
  }

  .showHideButtons {
    display: flex;
    justify-content: flex-end;
    margin-right: 12px;
  }

  .showHideButtons button:first-child {
    margin-right: 12px;
  }

  .column {
    height: 20vh;
    width: 100%;
    background-color: #f5f5f5;
    color: #004a22;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    margin-bottom: 24px;
  }

  .column h3,
  .column h2 {
    color: #e2dfcf;
    padding: 12px;
  }

  .column.active {
    height: 65vh;
    width: 100%;
    background-color: #f5f5f5;
    color: #004a22;
    border-radius: 5px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    overflow: scroll;
  }

  .debriefInput {
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    padding: 4px 8px 4px 6px;
  }

  .debriefInput_small {
    height: auto;
    width: 100%;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    padding: 4px 8px 4px 6px;
    color: #004a22;
    font-size: 1.1em;
    white-space: normal;
  }

  .valuesButton {
    text-decoration: none;
    font-weight: 700;
  }

  .debriefLine {
    background-color: rgba(0, 74, 35, 0.2);
    height: 3px;
    width: 90%;
    border: 3px solid rgba(0, 74, 35, 0.2);
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .introStageMain {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 12px;
  }

  .introContainer {
    height: auto;
    background-color: #004a22;
    color: #e2dfcf;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    padding: 16px 20px;
    border-radius: 5px;
    margin-bottom: 24px;
  }

  .menuRow {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 6px;
  }

  .debriefMenu {
    height: auto;
    width: auto;
    display: grid;
    grid-template-columns: repeat(2, 125px); /* Changed the column definition */
    grid-template-rows: repeat(20, 125px);
    row-gap: 12px;
    column-gap: 12px;
  }

  .debriefMenu .card {
    background-color: #004a22;
    color: #e2dfcf;
    margin: 6px;
    padding: 6px 8px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .explanationContainer,
  .specificityContainer,
  .finalDisplayContainer {
    height: 60vh;
    overflow: scroll;
    background-color: whitesmoke;
    color: #004a22;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    padding: 16px 20px;
    border-radius: 5px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: flex-start;
    margin-bottom: 24px;
  }

  .stepsList {
    margin-top: 12px;
    list-style-type: none;
    padding: 0;
  }

  .foundationContainer,
  .consistencyContainer,
  .experienceContainer {
    background-color: whitesmoke;
    color: #004a22;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    padding: 16px 20px;
    border-radius: 5px;
    justify-content: center;
    text-align: start;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;
  }

  .vpTextInput {
    padding-left: 12px;
    font-size: 1.5em;
    height: auto;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    text-indent: 6px;
    width: 100%;
  }

  .vpTextInput::placeholder {
    font-weight: 200;
    color: #004a22;
  }

  .vpTextInput:focus,
  .vpTextInput:active {
    font-size: 1.5em;
    height: auto;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    padding-left: 6px;
  }

  .fourFiveDiv {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 6px 0;
  }

  .fourFiveDiv textarea {
    height: 15vh;
    width: 100%;
  }

  .examplesButton {
    background-color: #004a22;
    color: #f5f5f5;
    font-size: 0.75em;
    border: 1px solid #004a22;
    margin-bottom: 12px;
    margin-top: 24px;
    border-radius: 5px;
    padding: 2px 4px;
  }

  .explanationButton,
  .valuesButton {
    background-color: #004a22;
    color: #e2dfcf;
    font-size: 0.75em;
    border: 1px solid #004a22;
    margin: 12px 0;
    border-radius: 5px;
    padding: 4px 6px;
    transition:
      padding 0.5s ease-in-out,
      background-color 0.5s ease-out;
  }

  .valuesIntBox.mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: flex-start;
  }

  .valueExplainer {
    width: 100%;
    white-space: normal;
    padding-left: 12px;
    font-size: 1.2em;
    font-weight: 200;
    height: auto;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    text-indent: 6px;
    width: 100%;
  }

  .valueExplainer:focus,
  .valueExplainer:active {
    font-size: 1.2em;
    font-weight: 200;
    height: auto;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    padding-left: 6px;
  }

  .valueExplainer::placeholder {
    font-weight: 200;
    color: #004a22;
  }

  .debriefContainer {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .debriefRow_Cols {
    height: 65vh;
    width: 100%;
    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .debriefRow_buttons {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: space-between;
    align-items: center;
  }

  .debriefRow_title {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: auto;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .debriefRow_title div {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 12px;
  }

  .debriefRow_title div:last-child {
    margin-bottom: 24px;
  }

  .debriefRow_title label {
    white-space: normal;
    width: fit-content;
    font-weight: bold;
    color: #004a22;
  }

  .showHideButtons {
    display: flex;
    justify-content: flex-end;
    margin-right: 12px;
  }

  .showHideButtons button:first-child {
    margin-right: 12px;
  }

  .column {
    height: 20vh;
    width: 100%;
    background-color: #f5f5f5;
    color: #004a22;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    margin-bottom: 24px;
  }

  .column h3,
  .column h2 {
    color: #e2dfcf;
    padding: 12px;
  }

  .column.active {
    height: 65vh;
    width: 100%;
    background-color: #f5f5f5;
    color: #004a22;
    border-radius: 5px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    overflow: scroll;
  }

  .debriefInput {
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    padding: 4px 8px 4px 6px;
  }

  .debriefInput_small {
    height: auto;
    width: 100%;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    padding: 4px 8px 4px 6px;
    color: #004a22;
    font-size: 1.1em;
    white-space: normal;
  }

  .valuesButton {
    text-decoration: none;
    font-weight: 700;
  }

  .debriefLine {
    background-color: rgba(0, 74, 35, 0.2);
    height: 3px;
    width: 90%;
    border: 3px solid rgba(0, 74, 35, 0.2);
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .introStageMain {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 12px;
  }

  .introContainer {
    height: auto;
    background-color: #004a22;
    color: #e2dfcf;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    padding: 16px 20px;
    border-radius: 5px;
    margin-bottom: 24px;
  }

  .menuRow {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 6px;
  }

  .debriefMenu {
    height: auto;
    width: auto;
    display: grid;
    grid-template-columns: repeat(2, 125px); /* Changed the column definition */
    grid-template-rows: repeat(20, 125px);
    row-gap: 12px;
    column-gap: 12px;
  }

  .debriefMenu .card {
    background-color: #004a22;
    color: #e2dfcf;
    margin: 6px;
    padding: 6px 8px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .explanationContainer,
  .specificityContainer,
  .finalDisplayContainer {
    height: 60vh;
    overflow: scroll;
    background-color: whitesmoke;
    color: #004a22;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    padding: 16px 20px;
    border-radius: 5px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: flex-start;
    margin-bottom: 24px;
  }

  .stepsList {
    margin-top: 12px;
    list-style-type: none;
    padding: 0;
  }

  .foundationContainer,
  .consistencyContainer,
  .experienceContainer {
    background-color: whitesmoke;
    color: #004a22;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    padding: 16px 20px;
    border-radius: 5px;
    justify-content: center;
    text-align: start;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;
  }

  .vpTextInput {
    padding-left: 12px;
    font-size: 1.5em;
    height: auto;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    text-indent: 6px;
    width: 100%;
  }

  .vpTextInput::placeholder {
    font-weight: 200;
    color: #004a22;
  }

  .vpTextInput:focus,
  .vpTextInput:active {
    font-size: 1.5em;
    height: auto;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    padding-left: 6px;
  }

  .fourFiveDiv {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 6px 0;
  }

  .fourFiveDiv textarea {
    height: 15vh;
    width: 100%;
  }

  .examplesButton {
    background-color: #004a22;
    color: #f5f5f5;
    font-size: 0.75em;
    border: 1px solid #004a22;
    margin-bottom: 12px;
    margin-top: 24px;
    border-radius: 5px;
    padding: 2px 4px;
  }

  .explanationButton,
  .valuesButton {
    background-color: #004a22;
    color: #e2dfcf;
    font-size: 0.75em;
    border: 1px solid #004a22;
    margin: 12px 0;
    border-radius: 5px;
    padding: 4px 6px;
    transition:
      padding 0.5s ease-in-out,
      background-color 0.5s ease-out;
  }

  .valuesIntBox.mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: flex-start;
  }

  .valueExplainer {
    width: 100%;
    white-space: normal;
    padding-left: 12px;
    font-size: 1.2em;
    font-weight: 200;
    height: auto;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    text-indent: 6px;
    width: 100%;
  }

  .valueExplainer:focus,
  .valueExplainer:active {
    font-size: 1.2em;
    font-weight: 200;
    height: auto;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    padding-left: 6px;
  }

  .valueExplainer::placeholder {
    font-weight: 200;
    color: #004a22;
  }

  .debriefContainer {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .debriefRow_Cols {
    height: 65vh;
    width: 100%;
    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .debriefRow_buttons {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: space-between;
    align-items: center;
  }

  .debriefRow_title {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: auto;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .debriefRow_title div {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 12px;
  }

  .debriefRow_title div:last-child {
    margin-bottom: 24px;
  }

  .debriefRow_title label {
    white-space: normal;
    width: fit-content;
    font-weight: bold;
    color: #004a22;
  }

  .showHideButtons {
    display: flex;
    justify-content: flex-end;
    margin-right: 12px;
  }

  .showHideButtons button:first-child {
    margin-right: 12px;
  }

  .column {
    height: 20vh;
    width: 100%;
    background-color: #f5f5f5;
    color: #004a22;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    margin-bottom: 24px;
  }

  .column h3,
  .column h2 {
    color: #e2dfcf;
    padding: 12px;
  }

  .column.active {
    height: 65vh;
    width: 100%;
    background-color: #f5f5f5;
    color: #004a22;
    border-radius: 5px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    overflow: scroll;
  }

  .debriefInput {
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    padding: 4px 8px 4px 6px;
  }

  .debriefInput_small {
    height: auto;
    width: 100%;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    padding: 4px 8px 4px 6px;
    color: #004a22;
    font-size: 1.1em;
    white-space: normal;
  }

  .valuesButton {
    text-decoration: none;
    font-weight: 700;
  }

  .debriefLine {
    background-color: rgba(0, 74, 35, 0.2);
    height: 3px;
    width: 90%;
    border: 3px solid rgba(0, 74, 35, 0.2);
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  /* Containers */
  .introStageMain {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .introContainer {
    background-color: #004a22;
    color: #e2dfcf;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 24px;
    border-radius: 5px;
  }

  .introContainer h1 {
    font-size: clamp(2.5vw, 3vw, 4vw);
  }

  .menuRow {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 6px;
    padding: 0 20px;
  }

  .debriefMenu {
    min-height: 20vh;
    height: auto;
    min-width: 50%;
    width: auto;
    padding: 16px 20px;
    display: grid;
    grid-template-columns: repeat(5, 175px); /* Changed the column definition */
    grid-template-rows: repeat(20, 175px);
    row-gap: 12px;
    column-gap: 12px;
  }

  .debriefMenu .card {
    background-color: #004a22;
    color: #e2dfcf;
    margin: 6px;
    padding: 6px 8px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .debriefMenu .card:hover {
    background-color: #e2dfcf;
    color: #004a22;
    border: 2px solid #004a22;
    margin: 6px;
    padding: 6px 8px;
    border-radius: 5px;
    cursor: pointer;
  }

  .valueExplanation {
    white-space: normal;
  }

  .addIcon,
  .minusIcon {
    cursor: pointer;
  }

  #titleOn {
    color: #004a22;
  }

  .stepsList {
    margin-top: 12px;
    list-style-type: none;
    padding: 0;
  }

  .stepsList li {
    margin-top: 12px;
  }

  .staticHelp {
    display: none;
  }

  .instructionLabel {
    font-size: 0.8em;
  }

  .hoverHelp {
    font-size: inherit;
    text-decoration: underline dotted;
    font-weight: 500;
  }

  .hoverHelp:hover {
    cursor: pointer;
  }

  .examplesButton {
    background-color: #004a22;
    color: #e2dfcf;
    font-size: 0.75em;
    border: 1px solid #004a22;
    margin-bottom: 12px;
    margin-top: 24px;
    border-radius: 5px;
    padding: 2px 4px;
  }

  .hoverHelp:hover::after,
  .examplesButton:hover::after {
    content: attr(data-tooltip);
    background-color: #004a22;
    font-size: 0.8em;
    color: #e2dfcf;
    height: auto;
    min-width: auto;
    max-width: 33%;
    white-space: normal;
    padding: 5px 7px;
    margin: 6px;
    position: absolute;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    z-index: 50;
  }

  .explanationContainer,
  .specificityContainer,
  .finalDisplayContainer {
    background-color: whitesmoke;
    color: #004a22;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    padding: 24px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: flex-start;
  }

  .editDeleteValues1 {
    display: none;
  }

  .valuesBlock {
    width: 100%;
    display: flex;
  }

  .valuesIntBox.desktop {
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: flex-start;
    padding-right: 12px;
  }

  .valuesIntBox1:hover .editDeleteValues1,
  .valuesIntBox1:hover .editDeleteValuesText1,
  .valuesIntBox2:hover .editDeleteValues2,
  .valuesIntBox2:hover .editDeleteValuesText2,
  .valuesIntBox3:hover .editDeleteValues3,
  .valuesIntBox3:hover .editDeleteValuesText3,
  .valuesIntBox4:hover .editDeleteValues4,
  .valuesIntBox4:hover .editDeleteValuesText4,
  .valuesIntBox5:hover .editDeleteValues5,
  .valuesIntBox5:hover .editDeleteValuesText5 {
    display: block;
  }

  .explanationTitle {
    display: flex;
    text-align: center;
  }

  .explanationTitle h1 {
    font-size: clamp(2.5vw, 3vw, 4vw);
  }

  .foundationContainer,
  .consistencyContainer,
  .experienceContainer {
    background-color: whitesmoke;
    color: #004a22;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    padding: 24px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    text-align: start;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .fourFiveDiv {
    height: 36px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .consistencyContainer input,
  .foundationContainer input,
  .experienceContainer input,
  .specificityContainer input {
    font-weight: 200;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 3px;
    padding-bottom: 2px;
  }

  .consistencyContainer input::placeholder,
  .foundationContainer input::placeholder,
  .experienceContainer input::placeholder,
  .specificityContainer input::placeholder {
    opacity: 0.5;
    font-weight: 200;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 3px;
    padding-bottom: 2px;
  }

  .specificityContainer {
    width: 100%;
    background-color: whitesmoke;
    color: #004a22;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
    border-radius: 5px;
  }

  .explanationButton,
  .valuesButton {
    background-color: #004a22;
    color: #e2dfcf;
    font-weight: 700;
    border: 1px solid #004a22;
    margin-bottom: 12px;
    margin-top: 24px;
    border-radius: 5px;
    padding: 4px 6px;
    text-decoration: none;
    transition:
      padding 0.5s ease-in-out,
      background-color 0.5s ease-out;
  }

  .valuesButton:hover,
  .valuesButton:focus {
    background-color: #004a22;
    color: #e2dfcf;
    border: 1px solid #004a22;
    box-shadow: none;
    border-radius: 5px;
    padding: 4px 6px;
  }

  .examplesButton:hover,
  .examplesButton:focus {
    background-color: #004a22;
    color: #e2dfcf;
    border: 1px solid #004a22;
    box-shadow: none;
    border-radius: 5px;
    padding: 2px 4px;
    cursor: pointer;
  }

  .vpTextInput {
    padding-left: 12px;
    font-size: 1.5em;
    height: auto;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    text-indent: 6px;
    width: 100%;
  }

  .vpTextInput::placeholder {
    font-weight: 200;
    color: #004a22;
  }

  .vpTextInput:focus,
  .vpTextInput:active {
    font-size: 1.5em;
    height: auto;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    padding-left: 6px;
  }

  .fiveDiv {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .vpDescriptionInput {
    padding-top: 6px;
    font-size: 0.8em;
    height: auto;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    font-weight: 300;
  }

  .vpDescriptionInput::placeholder {
    font-weight: 200;
    color: #004a22;
    opacity: 0.5;
  }

  .vpDescriptionInput:focus,
  .vpDescriptionInput:active {
    font-size: 0.8em;
    height: auto;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
  }

  .debriefContainer {
    margin-top: 72px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
  }

  .debriefRow_Cols {
    height: 65vh;
    display: flex;
    height: auto;
    justify-content: space-between;
    align-items: center;
  }

  .debriefRow_buttons {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: space-between;
    align-items: center;
  }

  .debriefRow_title {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: auto;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .debriefRow_title div {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 6px;
  }

  .debriefRow_title label {
    white-space: normal;
    width: fit-content;
    font-weight: bold;
    color: #004a22;
  }

  .showHideButtons {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    margin-right: 12px;
  }

  .showHideButtons button:first-child {
    margin-right: 12px;
  }

  .column {
    height: 65vh;
    width: 25%;
    background-color: #004a22;
    color: #f5f5f5;
    border-radius: 5px;
    display: flex;
    margin-right: 12px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: start;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
  }

  .column {
    min-height: 45vh;
    height: 50vh;
    max-height: 65vh;
    width: 25%;
    background-color: #004a22;
    color: #f5f5f5;
    border-radius: 5px;
    display: flex;
    margin-right: 12px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: start;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
  }

  .column h3,
  .column h2 {
    color: #e2dfcf;
    padding: 12px;
  }

  .column.active {
    min-height: 45vh;
    height: 50vh;
    max-height: 65vh;
    width: 25%;
    background-color: #f5f5f5;
    color: #004a22;
    border-radius: 5px;
    margin-right: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    overflow: scroll;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
  }

  .debriefInput {
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    padding: 4px 8px 4px 6px;
  }

  .debriefInput_small {
    height: auto;
    min-width: 60%;
    width: fit-content;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    padding: 4px 8px 4px 6px;
    color: #004a22;
    font-size: 1.1em;
  }

  .vertical-line {
    position: relative;
    width: 3px;
    height: 75%; /* Adjust the height as needed */
    background-color: rgba(0, 74, 35, 0.2); /* Adjust the color as needed */
    margin-right: 12px;
  }

  .vertical-line::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* border-left: 1px solid rgba(0, 74, 35, 0.2); */
  }
}

@media (min-width: 1201px) {
  /* Containers */
  .introStageMain {
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .introContainer {
    background-color: #004a22;
    color: #e2dfcf;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 24px;
    border-radius: 5px;
  }

  .introContainer h1 {
    font-size: clamp(2.5vw, 3vw, 4vw);
  }

  .menuRow {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 6px;
    padding: 0 20px;
  }

  .debriefMenu {
    min-height: 20vh;
    height: auto;
    min-width: 50%;
    width: auto;
    padding: 16px 20px;
    display: grid;
    grid-template-columns: repeat(5, 175px); /* Changed the column definition */
    grid-template-rows: repeat(20, 175px);
    row-gap: 12px;
    column-gap: 12px;
  }

  .debriefMenu .card {
    background-color: #004a22;
    color: #e2dfcf;
    margin: 6px;
    padding: 6px 8px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .debriefMenu .card:hover {
    background-color: #e2dfcf;
    color: #004a22;
    border: 2px solid #004a22;
    margin: 6px;
    padding: 6px 8px;
    border-radius: 5px;
    cursor: pointer;
  }

  .valueExplanation {
    white-space: normal;
  }

  .addIcon,
  .minusIcon {
    cursor: pointer;
  }

  #titleOn {
    color: #004a22;
  }

  .stepsList {
    margin-top: 12px;
    list-style-type: none;
    padding: 0;
  }

  .stepsList li {
    margin-top: 12px;
  }

  .staticHelp {
    display: none;
  }

  .instructionLabel {
    font-size: 0.8em;
  }

  .hoverHelp {
    font-size: inherit;
    text-decoration: underline dotted;
    font-weight: 500;
  }

  .hoverHelp:hover {
    cursor: pointer;
  }

  .examplesButton {
    background-color: #004a22;
    color: #e2dfcf;
    font-size: 0.75em;
    border: 1px solid #004a22;
    margin-bottom: 12px;
    margin-top: 24px;
    border-radius: 5px;
    padding: 2px 4px;
  }

  .hoverHelp:hover::after,
  .examplesButton:hover::after {
    content: attr(data-tooltip);
    background-color: #004a22;
    font-size: 0.8em;
    color: #e2dfcf;
    height: auto;
    min-width: auto;
    max-width: 33%;
    white-space: normal;
    padding: 5px 7px;
    margin: 6px;
    position: absolute;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    z-index: 50;
  }

  .explanationContainer,
  .specificityContainer,
  .finalDisplayContainer {
    margin-top: 72px;
    background-color: whitesmoke;
    color: #004a22;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    padding: 24px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: flex-start;
    border: 2px solid #004a22;
  }

  .editDeleteValues1 {
    display: none;
  }

  .valuesBlock {
    width: 100%;
    display: flex;
  }

  .valuesIntBox.desktop {
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: flex-start;
    padding-right: 12px;
  }

  .valuesIntBox1:hover .editDeleteValues1,
  .valuesIntBox1:hover .editDeleteValuesText1,
  .valuesIntBox2:hover .editDeleteValues2,
  .valuesIntBox2:hover .editDeleteValuesText2,
  .valuesIntBox3:hover .editDeleteValues3,
  .valuesIntBox3:hover .editDeleteValuesText3,
  .valuesIntBox4:hover .editDeleteValues4,
  .valuesIntBox4:hover .editDeleteValuesText4,
  .valuesIntBox5:hover .editDeleteValues5,
  .valuesIntBox5:hover .editDeleteValuesText5 {
    display: block;
  }

  .explanationTitle {
    display: flex;
    text-align: center;
  }

  .explanationTitle h1 {
    font-size: clamp(2.5vw, 3vw, 4vw);
  }

  .foundationContainer,
  .consistencyContainer,
  .experienceContainer {
    background-color: whitesmoke;
    color: #004a22;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    padding: 24px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    text-align: start;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .fourFiveDiv {
    height: 36px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .consistencyContainer input,
  .foundationContainer input,
  .experienceContainer input,
  .specificityContainer input {
    font-weight: 200;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 3px;
    padding-bottom: 2px;
  }

  .consistencyContainer input::placeholder,
  .foundationContainer input::placeholder,
  .experienceContainer input::placeholder,
  .specificityContainer input::placeholder {
    opacity: 0.5;
    font-weight: 200;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 3px;
    padding-bottom: 2px;
  }

  .specificityContainer {
    width: 100%;
    background-color: whitesmoke;
    color: #004a22;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
    border-radius: 5px;
  }

  .explanationButton,
  .valuesButton {
    background-color: #004a22;
    color: #e2dfcf;
    font-weight: 700;
    border: 1px solid #004a22;
    margin-bottom: 12px;
    margin-top: 24px;
    border-radius: 5px;
    padding: 4px 6px;
    text-decoration: none;
    transition:
      padding 0.5s ease-in-out,
      background-color 0.5s ease-out;
  }

  .valuesButton:hover,
  .valuesButton:focus {
    background-color: #004a22;
    color: #e2dfcf;
    border: 1px solid #004a22;
    box-shadow: none;
    border-radius: 5px;
    padding: 4px 6px;
  }

  .examplesButton:hover,
  .examplesButton:focus {
    background-color: #004a22;
    color: #e2dfcf;
    border: 1px solid #004a22;
    box-shadow: none;
    border-radius: 5px;
    padding: 2px 4px;
    cursor: pointer;
  }

  .vpTextInput {
    padding-left: 12px;
    font-size: 1.5em;
    height: auto;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    text-indent: 6px;
    width: 100%;
  }

  .vpTextInput::placeholder {
    font-weight: 200;
    color: #004a22;
  }

  .vpTextInput:focus,
  .vpTextInput:active {
    font-size: 1.5em;
    height: auto;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    padding-left: 6px;
  }

  .fiveDiv {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .vpDescriptionInput {
    padding-top: 6px;
    font-size: 0.8em;
    height: auto;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    font-weight: 300;
  }

  .vpDescriptionInput::placeholder {
    font-weight: 200;
    color: #004a22;
    opacity: 0.5;
  }

  .vpDescriptionInput:focus,
  .vpDescriptionInput:active {
    font-size: 0.8em;
    height: auto;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
  }

  .debriefContainer {
    margin-top: 48px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
  }

  .debriefRow_Cols {
    height: 65vh;
    display: flex;
    height: auto;
    justify-content: space-between;
    align-items: center;
  }

  .debriefRow_buttons {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: space-between;
    align-items: center;
  }

  .debriefRow_title {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: auto;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .debriefRow_title div {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 6px;
  }

  .debriefRow_title label {
    white-space: normal;
    width: fit-content;
    font-weight: bold;
    color: #004a22;
  }

  .showHideButtons {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    margin-right: 12px;
  }

  .showHideButtons button:first-child {
    margin-right: 12px;
  }

  .column {
    min-height: 45vh;
    height: 50vh;
    max-height: 65vh;
    width: 25%;
    background-color: #004a22;
    color: #f5f5f5;
    border-radius: 5px;
    display: flex;
    margin-right: 12px;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: start;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
  }

  .column h3,
  .column h2 {
    color: #e2dfcf;
    padding: 12px;
  }

  .column.active {
    min-height: 45vh;
    height: 50vh;
    max-height: 65vh;
    width: 25%;
    background-color: #f5f5f5;
    color: #004a22;
    border-radius: 5px;
    margin-right: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    overflow: scroll;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
  }

  .debriefInput {
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    padding: 4px 8px 4px 6px;
  }

  .debriefInput_small {
    height: auto;
    min-width: 60%;
    width: fit-content;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    padding: 4px 8px 4px 6px;
    color: #004a22;
    font-size: 1.1em;
  }

  .vertical-line {
    position: relative;
    width: 3px;
    height: 75%; /* Adjust the height as needed */
    background-color: rgba(0, 74, 35, 0.2); /* Adjust the color as needed */
    margin-right: 12px;
  }

  .vertical-line::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* border-left: 1px solid rgba(0, 74, 35, 0.2); */
  }
}
