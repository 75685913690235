@media (min-width: 0px) and (max-width: 768px) {
  /* Header Navigation  */
  .nav.central {
    height: auto;
    width: 100%;
    display: flex;
  }

  .nav--chevron {
    padding: 2px; 
    opacity: 0.5;
    color: #e2dfcf;
  }

  .nav--thin:hover .nav--chevron {
    opacity: 1;
  }

  .nav--thin {
    height: min-content;
    width: 100%;
    display: flex;
    background-color: #004a22;
    justify-content: center;    
  }

  .nav--thin--active {
    height: min-content;
    width: 100%;
    display: flex;
    background-color: #004a22;
    justify-content: center;    
  }

  .nav--thin:hover {
    cursor: pointer;
  }

  .nav--wide {
    height: max-content;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 12px 0;
  }

  .nav--wide a {
    width: 18%;
    /* display: flex;
    justify-content: center;
    align-items: center; */
  }
  .navElement {
    height: 36px;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px 3px;
    background-color: #004a22;
    color: #004a22;
    border-radius: 8px;
    border: 2px solid #004a22;
    margin: 0 4px;
  }

  .navElement h6 {
display: none;
  }

  .navElement_logo {
    min-height: 36px;
    min-width: 36px;
    max-height: 36px;
    max-width: 36px;
  }

  .box.right__desktop {
    display: none;
  }

  .header.node.white {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-in-out;
  }
}

@media (min-width: 769px) {
  /* Full Width */
  .nav.central {
    height: auto;
    width: 100%;
    display: flex;
    z-index: 100;
  }

  .nav.central.dark {
    background-color: #004a22;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0%;
    z-index: 100;
    padding: 0 24px;
  }

  .nav--chevron {
    padding: 2px; 
    opacity: 0.5;
    color: #e2dfcf;
  }

  .nav--thin:hover .nav--chevron {
    opacity: 1;
  }

  nav .box.left {
    display: flex;
    justify-content: flex-start;
  }

  .box.left a {
    transform: scale(0.5);
  }

  .nav--thin {
    width: 100%;
    display: flex;
    background-color: #004a22;
    justify-content: center;
  }

  .nav--thin--active {
    width: 100%;
    display: flex;
    background-color: #004a22;
    justify-content: center;
  }

  .nav--thin:hover {
cursor: pointer;
  }

  .nav--wide {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 12px 0;
  }

  nav .box.right__mobile {
    display: none;
  }

  .box.right__desktop a {
    font-weight: 400;
    text-decoration: none;
    cursor: pointer;
    color: #004a22;
    border-radius: 5px;
    transition: all 0.1s ease-in-out;
  }

  nav .box.centre {
    display: flex;
    justify-content: flex-end;
  }

  #nava {
    font-weight: 600;
    text-decoration: none;
    font-style: italic;
    color: #f5f5f5;
    padding: 6px 12px;
    margin: 0 12px;
    cursor: pointer;
    background-color: #004a22;
    border-radius: 5px;
    transition: all 0.1s ease-in-out;
  }

  #nava:hover {
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
    color: #f5f5f5;
    transition: all 0.1s ease-in-out;
  }


  .wave--top--header {
    z-index: 99;
    width: 100%;
    position: absolute;
    top: 40px;
  }

  .searchInput,
  .searchInput:focus,
  .searchInput:active {
    background-color: rgba(0, 74, 35, 0);
    border: none;
    outline: none;
    color: #e2dfcf;
    font-weight: 600;
  }

  .searchInput.dark,
  .searchInput.dark:focus,
  .searchInput.dark:active {
    border: none;
    outline: none;
    color: rgba(0, 74, 34, 1);
    font-weight: 600;
  }

  .searchInput::placeholder {
    color: #e2dfcf;
    font-weight: 600;
  }

  .searchInput.dark::placeholder {
    color: rgba(0, 74, 34, 1);
    font-weight: 600;
  }

  input[type="search"]::-webkit-search-clear-button {
    -webkit-appearance: none;
    appearance: none;
  }

  .searchInput::-webkit-input-placeholder {
    color: #e2dfcf;
  }

  .searchInput.dark::-webkit-input-placeholder {
    color: rgba(0, 74, 34, 1);
  }

  .searchList {
    margin: 4px;
    border-radius: 5px;
    padding: 6px 4px;
    background-color: rgba(0, 74, 35, 1);
    list-style-type: none;
    color: #e2dfcf;
    z-index: 10000;
    height: 10vh;
    overflow: scroll;
  }

  .searchList.dark {
    margin: 4px;
    border-radius: 5px;
    padding: 6px 4px;
    background-color: #e2dfcf;
    list-style-type: none;
    color: rgba(0, 74, 35, 1);
    z-index: 10000;
    height: 10vh;
    overflow: scroll;
  }

  .searchItem.dark,
  #searchItemDark {
    margin: 2px 4px;
    color: rgba(0, 74, 35, 1);
    white-space: nowrap;
    font-weight: 600;
    font-size: 12px;
  }

  .burger {
    margin: 0;
    padding: 2px 6px;
    border-radius: 5px;
    background-color: #004a22;
    color: #e2dfcf;
    transform: scale(1.5);
  }

  .navElement_logo {
    height: auto;
    width: auto;
    max-width: min-content;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #004a22;
    color: #e2dfcf;
    border-radius: 50%;
  }

  #brand {
    height: 24px;
    width: 24px;
    transition: all 0.5s ease-in-out;
  }

  .navWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .navElement {
    height: auto;
    width: auto;
    max-width: min-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 4px;
    background-color: rgba(0, 74, 35, 1);
    color: #e2dfcf;
    border-radius: 8px;
    white-space: nowrap;
    overflow: hidden;
    opacity: 1;
    transition: opacity 2s ease-in-out;
    margin-right: 12px;
  }

  .navElement.dark {
    height: auto;
    width: auto;
    max-width: min-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 4px;
    background-color: #e2dfcf;
    color: rgba(0, 74, 35, 1);
    border-radius: 30px;
    white-space: normal;
    overflow: hidden;
  }

  .navElement.dark.stack {
    height: auto;
    width: auto;
    max-width: min-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 14px;
    background-color: #e2dfcf;
    color: rgba(0, 74, 35, 1);
    border-radius: 30px;
    white-space: normal;
    overflow: hidden;
  }

  .navElement.light.stack {
    height: auto;
    width: auto;
    max-width: min-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 14px;
    /* background-color: rgba(0, 74, 35, 1); */
    border: rgba(0, 74, 34, 1);
    color: #e2dfcf;
    border-radius: 30px;
    white-space: normal;
    overflow: hidden;
  }

  a .navElement:hover {
    height: auto;
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 4px;
    background-color: #004a22;
    color: #e2dfcf;
    border-radius: 8px;
    cursor: pointer;
  }

  a .navElement.dark:hover {
    height: auto;
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 4px;
    background-color: #e2dfcf;
    color: #004a22;
    border-radius: 30px;
    cursor: pointer;
  }

  .navElement h6 {
    padding-right: 12px;
    padding-left: 6px;
    font-weight: 600;
    text-decoration: none;
    color: #e2dfcf;
    margin: 0;
  }

  .navElement.dark h6 {
    padding-right: 12px;
    padding-left: 6px;
    font-weight: 600;
    text-decoration: none;
    color: #004a22;
    margin: 0;
  }

  .smallSpan {
    padding-right: 24px;
    padding-left: 12px;
    font-weight: 600;
    text-decoration: none;
    font-style: italic;
    color: #f5f5f5;
    font-size: 0.7em;
  }

  .icon.green {
    color: #e2dfcf;
    transform: scale(1.2);
  }

  .header.node.white {
    padding: 6px;
    min-height: 30px;
    min-width: 30px;
    max-height: 30px;
    max-width: 30px;
    border-radius: 50%;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-in-out;
  }

  
  /* Mobile View */
  .menu {
    opacity: 0;
    height: 0;
    width: 100%;
    color: #e2dfcf;
    transition: all 1s ease-in-out;
  }

  .menu.active {
    opacity: 1;
    height: 5vh;
    width: 100%;
    background-color: #004a22;
    color: #e2dfcf;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 1s ease-in-out;
  }

  .navMenu,
  .navDock {
    display: none;
  }

  .navMenu.active {
    margin: 0;
    padding: 0;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }

  .navMenu li {
    list-style: none;
  }

  .listItem a {
    color: #e2dfcf;
  }

  .navDock.active {
    height: 20vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: scroll;
  }

  .navDock.active li {
    padding: 6px 0;
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
  }

  .searchItem,
  #searchItem {
    margin: 2px 4px;
    color: #e2dfcf;
    white-space: nowrap;
    font-weight: 600;
    font-size: 12px;
  }
}
