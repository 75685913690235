.subList {
  line-height: 2;
}

.nameButtonRow {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.aside.timestamp {
  color: #000;
}

