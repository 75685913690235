.resource.main {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.resource.table {
    width: 75%;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 24px;
    border: 2px solid #004a22;
    box-shadow: 0 10px 10px rgba(0,0,0,0.2);
    border-radius: 5px;
}

.resource.name:first-child {
    padding: 10px;
}


.resource.row {
    width: 100%;
    padding: 12px 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.resource.name {
    color: #004a22;
}

.resource.description {
    color: #004a22;
    padding-left: 24px;
    font-weight: 400;
}

.linkRow {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.linkList {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}