#taskImageDiv {
  display: inline-block;
}

.taskImage2 {
  margin-top: 6px;
  border: 2px solid #004a22;
  border-radius: 3px;
  float: right;
}

.taskImage1 {
  border: 2px solid #004a22;
  border-radius: 3px;
}