@media (max-width: 1024px) {
  /* Core Page  */
  .updateday--container,
  .createday--container {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  
  .updateday--container form,
  .createday--container form {
    height: 100%;
    width: 100%;
  }

  .tile__container {
    width: 100%;
    padding: 0 24px;
  }

  /* Tile  */
  .tile {
    height: auto;
    width: 100%;
    border-radius: 5px;
    padding: 10px 12px;
    margin: 6px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
  }

  .dateRow--header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background-color: rgba(0, 74, 35, 1);
    padding: 4px 8px;
    margin-bottom: 12px;
  }

  .upperDiv {
    width: 100%;
  }

  .tooltip--span {
    display: none;
  }

  .yeartile--day {
    height: auto;
    width: 100%;
    border-radius: 5px;
    background-color: whitesmoke;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
    z-index: 1;
    margin: 12px 0;
  }

  .score__row--moment {
    height: max-content;
    width: 100%;
    padding: 0 0 6px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: #f5f5f5;
    border-radius: 5px 5px 5px 0;
    padding: 4px 8px;
  }

  .score__row--moment.complete {
    border-radius: 5px 5px 0 0;
  }

  .weekSlider {
    -webkit-appearance: none;
    width: 100%;
    height: 25px;
    border-radius: 0 0 5px 5px;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }

  .weekSlider.off {
    -webkit-appearance: none;
    width: 100%;
    height: 25px;
    border-radius: 0 0 5px 5px;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    background-color: rgba(0, 0, 0, 0.05);
  }

  .weekSlider::-webkit-slider-thumb::before,
  .weekSlider.week::-webkit-slider-thumb::before {
    content: "";
    position: absolute;
    top: -10px; /* Adjust as needed */
    bottom: -10px; /* Adjust as needed */
    left: -10px; /* Adjust as needed */
    right: -10px; /* Adjust as needed */
  }

  .weekSlider:hover {
    opacity: 1;
  }

  .weekSlider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 25px;
    height: 25px;
    cursor: grab;
    border-radius: 0 0 5px 5px;
    background-color: #004a22;
  }

  .weekSlider.off::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 25px;
    height: 25px;
    cursor: grab;
    border-radius: 0 0 5px 5px;
    background-color: rgba(0, 74, 35, 0.5);
  }

  .tile textarea {
    width: 100%;
    resize: none;
    overflow-y: hidden;
    height: auto;
  }

  .score--tooltip {
    display: none;
  }

  #loggedDate {
    padding: 10px;
    border-radius: 5px;
    background-color: #f5f5f5;
    color: rgba(0, 74, 35, 1);
  }

  .mobileSaveDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .mobFrame {
    display: none;
  }

  .mobFrame.active {
    height: max-content;
  }

  .scoring__row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    border-bottom: 1px dashed rgba(125, 125, 125, 0.2);
    margin: 12px 0;
    padding-bottom: 12px;
  }

  .score__row {
    height: max-content;
    width: 100%;
    padding: 0 0 6px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: #004a22;
    border-radius: 5px 5px 5px 0;
    padding: 4px 8px;
  }

  .score.label {
    font-weight: 600;
    color: #e2dfcf;
    white-space: normal;
  }

  .moment__submission {
    width: 100%;
    padding: 12px 0;
  }

  .score__row.tooltip {
    position: relative;
    display: inline-block;
  }

  .textarea-tooltip {
    position: absolute;
    right: -12px;
    top: -12px;
    display: none;
    background-color: rgba(0, 74, 35, 1);
    font-size: 0.8em;
    font-weight: 600;
    color: #e2dfcf;
    height: auto;
    width: auto;
    padding: 5px 7px;
    margin: 6px;
    justify-content: center;
    white-space: nowrap;
  }

  .score__row:hover .textarea-tooltip {
    display: block;
  }

  .textarea-tooltip-content {
    font-size: 10px;
    color: #e2dfcf;
  }

  .momentRow {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .dateRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #004a22;
    border-radius: 5px;
  }

  .dateRow label {
    padding: 4px 8px;
    background-color: #004a22;
    border-radius: 5px 0 0 5px;
    color: #e2dfcf;
  }

  .dateInputRow {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .dock.footer {
    display: none;
  }

  .dateRow input {
    background-color: transparent;
    outline: none;
    border: none;
  }

  .dateRow label {
    margin-right: 12px;
  }

  .formInput.moment {
    width: 100%;
    height: 100%;
    padding: 8px;
    font-weight: 400;
    color: #004a22;
  }

  .formInput.moment::placeholder {
    font-weight: 400;
    color: #004a22;
    opacity: 0.5;
  }

  .formInput,
  .formInput:focus,
  .formInput:active {
    height: auto;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
  }

  .mouseTooltip {
    display: none;
  }

  .mobileTooltip {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
  }

  .mobileDockRow.overlay {
    opacity: 0.2;
    height: 20vh;
    width: 100%;
  }

  .mobileDockRow {
    height: auto;
    width: 100%;
    padding-bottom: 18px;
  }

  #loggedDate {
    font-family: Courier, monospace;
    font-weight: 200;
  }

  .objective__window {
    width: 87.5%;
    height: auto;
    background-color: #e2dfcf;
    border-radius: 5px;
    border: 2px solid #004a22;
    display: block;
    position: absolute;
    left: 10%;
    top: 100%;
    z-index: 1000;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
  }

  .objective__window--inactive {
    display: none;
  }

  /* Tutorial */
  .dateRow--tutorial {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(103, 36, 131, 1);
    border-radius: 5px;
  }

  .dateRow input,
  .dateRow--tutorial input {
    background-color: #f5f5f5;
    outline: none;
    border: none;
    padding: 4px 8px;
    border-radius: 0 5px 5px 0;
  }

  .weekSlider.tutorial {
    -webkit-appearance: none;
    width: 100%;
    height: 25px;
    border-radius: 0 0 5px 5px;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    background-color: rgba(226, 223, 207, 0.2);
  }

  .weekSlider.tutorial::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 25px;
    height: 25px;
    cursor: grab;
    border-radius: 0 0 5px 5px;
    background-color: #e2dfcf;
  }

  .dateRow--tutorial label {
    padding: 4px 8px;
    background-color: rgba(103, 36, 131, 1);
    border-radius: 5px 0 0 5px;
    color: #e2dfcf;
  }

  .dateRow--tutorial.prime label {
    padding: 4px 8px;
    background-color: transparent;
    border-radius: 5px 0 0 5px;
    color: #004a22;
  }

  .yeartile--day--tutorial {
    height: auto;
    width: 100%;
    border-radius: 5px;
    background-color: whitesmoke;
    box-shadow:
      -15px 15px 15px rgba(0, 0, 0, 0.05),
      0 0 30px rgba(226, 223, 207, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
    z-index: 1;
    margin: 12px 0;
  }

  .tutorial__button {
    height: 18px;
    width: auto;
    margin-bottom: 12px;
  }

  .dateRow--header--wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dateRow--header--wrapper .dateRow--header {
    width: 92.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #004a22;
    border-radius: 5px;
    padding: 4px 8px;
    margin-bottom: 12px;
  }

  .dateRow--header--tutorial {
    width: 92.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(103, 36, 131, 1);
    border-radius: 5px;
    margin-bottom: 12px;
  }

  .tutorial.access--tutorial.blank,
  .dateRow--tutorial.blank,
  .dateRow--header--tutorial.blank,
  .score__row--tutorial.blank {
    background-color: transparent;
  }

  .tutorial.access--tutorial.blank label,
  .dateRow--header--tutorial.blank label,
  .score__row--tutorial.blank label {
    color: #e2dfcf;
  }

  .tutorial.access--tutorial.prime,
  .dateRow--tutorial.prime,
  .dateRow--header--tutorial.prime,
  .score__row--tutorial.prime {
    background-color: #e2dfcf;
    border: 4px solid #e2dfcf;
  }

  .tutorial.access--tutorial.prime label,
  .dateRow--header--tutorial.prime label,
  .score__row--tutorial.prime label {
    color: #004a22;
  }

  .tutorial.access--tutorial.process,
  .dateRow--header--tutorial.process,
  .score__row--tutorial.process {
    background-color: rgba(103, 36, 131, 1);
    border: 4px solid rgba(103, 36, 131, 1);
  }

  .tutorial.access--tutorial.process label,
  .dateRow--header--tutorial.process label,
  .score__row--tutorial.process label {
    color: #f5f5f5;
  }

  .tutorial.access--tutorial.plan,
  .dateRow--header--tutorial.plan,
  .score__row--tutorial.plan {
    background-color: #f5f5f5;
    border: 4px solid #f5f5f5;
  }

  .tutorial.access--tutorial.plan label,
  .dateRow--header--tutorial.plan label,
  .score__row--tutorial.plan label {
    color: #004a22;
  }

  .today__tutorial--container {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 24px;
    background-color: #004a22;
    border-top: 2px solid #004a22;
  }

  .mobiledock--tutorial {
    text-align: flex-start;
    padding: 10px 12px;
  }

  .mobiledock--tutorial--inactive {
    display: none;
  }

  .score__display {
    color: #e2dfcf;
  }

  .mobileDockRow--tutorial {
    height: auto;
    width: 100%;
    box-shadow:
      inset -15px 15px 15px rgba(0, 0, 0, 0.05),
      inset 0 0 30px rgba(226, 223, 207, 0.5);
    padding-bottom: 18px;
    border-radius: 5px;
  }

  .dateRow--header--tutorial {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background-color: rgba(103, 36, 131, 1);
    padding: 4px 8px;
    margin-bottom: 12px;
  }

  .score__row--tutorial {
    height: max-content;
    width: 100%;
    padding: 0 0 6px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: rgba(103, 36, 131, 1);
    border-radius: 5px 5px 5px 0;
    padding: 4px 8px;
    box-shadow:
      -15px 15px 15px rgba(0, 0, 0, 0.05),
      0 0 30px rgba(226, 223, 207, 0.5);
  }

  .score__row label,
  .score__row--moment label,
  .score__row--tutorial label {
    color: #e2dfcf;
    font-weight: 600;
  }

  .tile.tut {
    height: auto;
    width: 100%;
    border-radius: 5px;
    padding: 10px 12px;
    margin: 12px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    background-color: #004a22;
  }

  .score__row--moment.tut {
    height: max-content;
    width: 100%;
    padding: 0 0 6px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: #f5f5f5;
    border-radius: 5px 5px 0px 0;
    padding: 4px 8px;
    box-shadow:
      -15px 15px 15px rgba(0, 0, 0, 0.05),
      0 0 30px rgba(226, 223, 207, 0.5);
  }

  .score.label h3 {
    margin-bottom: 2rem;
  }

  .score.label h3:last-child {
    margin-bottom: 0.5rem;
  }

  .formInput.moment.tut {
    width: 100%;
    height: 100%;
    padding: 8px;
    font-weight: 600;
    color: #004a22;
  }
}


@media (min-width: 1025px) and (max-width: 1440px) {
  /* Tutorial */
  #crossIcon {
    transform: scale(3);
    color: rgba(0, 74, 35, 1);
  }

  #crossIcon:hover,
  #crossIcon:active,
  #crossIcon:focus,
  #nextIcon:hover,
  #nextIcon:active,
  #nextIcon:focus {
    cursor: pointer;
    color: rgba(0, 74, 35, 1);
  }

  #nextIcon {
    transform: scale(3);
    color: rgba(0, 74, 35, 1);
  }

  .tutorialpage--container {
    height: 90vh;
    width: 100%;
    min-width: 960px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 12px;
  }

  .instruction {
    height: 12vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0 0 0;
    padding: 0;
    transition: all 1s;
  }

  .instruction div:first-child {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    padding-right: 48px;
  }

  .instruction div:last-child {
    width: 8%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    text-align: end;
  }

  .instruction div:last-child a:first-child {
    margin-left: 12px;
  }

  .tutorialNav {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .tutorialNav button {
    width: 72px;
  }

  .instruction h3 {
    color: #004a22;
  }

  .weekSlider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 25px;
    border-radius: 0 0 5px 5px;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    background-color: rgba(245, 245, 245, 0);
    /* outline: 0.5px solid rgba(0, 74, 35, 0.25); */
  }

  .weekSlider:hover {
    opacity: 1;
  }

  .weekSlider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 25px;
    border-radius: 5px;
    cursor: grab;
    border-radius: 0 0 5px 5px;
    background-color: #004a22;
    cursor: pointer;
  }

  .weekSlider.off::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 25px;
    border-radius: 5px;
    cursor: grab;
    border-radius: 0 0 5px 5px;
    background-color: rgba(0, 74, 35, 0.25);
  }

  .range-slider-container {
    position: relative;
  }

  .tooltip {
    position: absolute;
    top: 30px; /* Adjust the tooltip position as needed */
    background-color: #333;
    color: fuchsia;
    padding: 4px 90px;
    border-radius: 4px;
    font-size: 35px;
  }

  /* Day Screen */
  .updateday--container,
  .createday--container {
    min-height: 250px;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0);
    transition: all 2s ease-in-out;
    padding: 0 24px;

  }

  .updateday--container form,
  .createday--container form {
    width: 100%;
    height: 100%;
    /* background-color: crimson; */
  }

  .tile__container {
    height: 85%;
    /* background-color: yellow; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 24px 0 12px 0;
  }

  .tile {
    min-height: 150px;
    height: auto;
    max-height: 100%;
    min-width: 30%;
    width: auto;
    padding: 12px 18px;
    border-radius: 8px;
    background-color: rgba(0, 74, 34, 0.05);
    /* background-color: coral; */
    box-shadow: 0 4px 6px rgba(0, 74, 34, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    margin: 0px 12px;
    overflow-y: scroll;
    opacity: 1;
    transition: opacity 2s ease-in-out;
  }

  .tile.reg {
    padding: 36px;
    min-height: 375px;
    max-height: 85%;
    height: auto;
    min-width: 50%;
    width: auto;
    max-width: 75%;
  }

  .cornerStateOn {
    border-radius: 5px 5px 0px 0px;
  }

  .cornerStateOff {
    border-radius: 5px 5px 5px 0px;
  }

  .yeartile--day {
    height: auto;
    width: 100%;
    border-radius: 5px;
    background-color: whitesmoke;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
    z-index: 1;
    margin: 12px 0;
  }

  .yeartile--day:nth-child(7) {
    margin: 6px 0px 12px 0px;
    background-color: fuchsia;
  }

  .upperDiv {
    width: 100%;
  }

  .iconDiv--day {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .dateRow--header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #004a22;
    border-radius: 5px;
    padding: 4px 8px;
    margin-bottom: 12px;
  }

  .mobileSaveDiv {
    display: none;
  }

  .dateRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 5px;
  }

  .dateRow input {
    min-width: 30%;
    width: 50%;
    background-color: #f5f5f5;
    outline: none;
    border: none;
    padding: 4px 8px;
    border-radius: 0 5px 5px 0;
  }

  .dateRow label {
    padding: 4px 8px;
    background-color: #004a22;
    border-radius: 5px 0 0 5px;
    color: #e2dfcf;
  }

  .primeContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .tile.centre {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    overflow-y: scroll;
  }

  .tile.centre textarea {
    width: 100%;
  }

  .scoring__row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    margin: 12px 0;
  }

  .scoring__row span,
  .moment__submission span {
    position: relative;
  }

  .score__row--moment {
    height: max-content;
    width: 100%;
    padding: 0 0 6px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: #f5f5f5;
    border-radius: 5px 5px 5px 0;
    padding: 4px 8px;
    z-index: 1;
  }

  .score__row--moment.complete {
    border-radius: 5px 5px 0 0;
    z-index: 1;
  }

  .score__row {
    height: max-content;
    width: 100%;
    padding: 4px 8px 6px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: #004a22;
    border-radius: 8px 8px 0 0;
  }

  .score__row label,
  .score__row--moment label {
    color: #e2dfcf;
    font-weight: 600;
  }

  .score__row textarea,
  .score__row textarea:focus {
    border-left: 1px dashed rgba(125, 125, 125, 0.5);
    padding-left: 12px;
  }

  .score__column {
    width: 10%;
    position: relative;
    top: 1%;
    right: 2.5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .score__column span {
    font-size: 0.6em;
    padding: 0;
    margin: 0;
  }

  .prime.submission {
    width: 100%;
    padding: 12px 0 6px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 12px;
    border-bottom: 1px dashed rgba(125, 125, 125, 0.2);
  }

  .prime.submission:first-child {
    padding-top: 24px;
  }

  .prime.submission textarea {
    height: 100%;
  }

  .prime.submission:last-child {
    border-bottom: none;
    padding-bottom: 6px;
  }

  .tile.right {
    padding: 16px 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: start;
    /* box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05); */
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .review.submission {
    width: 100%;
    padding: 12px 0 6px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 12px;
    border-bottom: 1px dashed rgba(125, 125, 125, 0.2);
  }

  .review.submission textarea {
    height: 100%;
  }

  .review.submission:nth-child(2) {
    padding: 0 0 6px 0;
  }

  .moment__submission {
    width: 100%;
    padding-top: 6px;
  }

  .score.label {
    width: 100%;
    font-weight: 600;
    color: #e2dfcf;
  }

  .formInput.moment {
    width: 100%;
    height: 100%;
    padding: 8px;
    font-weight: 400;
    color: #004a22;
  }

  .formInput.moment::placeholder {
    font-weight: 400;
    color: #004a22;
    opacity: 0.3;
  }

  .score__row.tooltip {
    position: relative;
    display: inline-block;
  }

  .textarea-tooltip {
    position: absolute;
    right: -8px;
    top: -4px;
    background-color: #f5f5f5;
    box-shadow: 2.5px -2.5px 0px rgba(0, 74, 34, 1);
    display: none;
    font-weight: 600;
    color: #004a22;
    border-left: 1px dashed rgba(125, 125, 125, 0.5);
    height: auto;
    width: auto;
    padding: 4px 6px;
    border-radius: 5px;
    margin: 6px;
    justify-content: center;
    white-space: nowrap;
  }

  .score__row:hover .textarea-tooltip {
    display: block;
  }

  .textarea-tooltip-content {
    font-size: 10px;
    color: #004a22;
  }

  .formInput,
  .formInput:focus,
  .formInput:active {
    height: auto;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
  }

  .dock.footer {
    height: auto;
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 150;
  }



  .row.button {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .row.button button {
    width: auto;
  }

  .mobileDockRow {
    display: none;
  }

  .tile textarea {
    width: 100%;
    resize: none;
    overflow-y: hidden;
    height: auto;
  }

  .objective__window {
    width: auto;
    height: auto;
    background-color: #e2dfcf;
    border-radius: 5px;
    border: 2px solid #004a22;
    display: block;
    position: absolute;
    left: 10%;
    top: 15%;
    z-index: 1000;
    cursor: grab;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
  }

  .objective__window--inactive {
    display: none;
  }

  /* Tutorial */
  .dateRow--tutorial {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(103, 36, 131, 1);
    border-radius: 5px;
    transition: all 2s ease-in-out;
  }

  .dateRow input,
  .dateRow--tutorial input {
    background-color: #f5f5f5;
    outline: none;
    border: none;
    padding: 4px 8px;
    border-radius: 0 5px 5px 0;
    transition: all 2s ease-in-out;
  }

  .dateRow--tutorial label {
    padding: 4px 8px;
    border-radius: 5px 0 0 5px;
    color: #e2dfcf;
    transition: all 2s ease-in-out;
  }

  .yeartile--day--tutorial {
    height: auto;
    width: 100%;
    border-radius: 5px;
    background-color: whitesmoke;
    box-shadow:
      -15px 15px 15px rgba(0, 0, 0, 0.05),
      0 0 30px rgba(226, 223, 207, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
    z-index: 1;
    margin: 12px 0;
    transition: all 2s ease-in-out;
  }

  .tutorial__button {
    height: 18px;
    width: auto;
    background-color: #004a22;
    color: #e2dfcf;
    padding: 8px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    font-weight: bold;
    transition: all 2s ease-in-out;
  }

  .liveDock {
    max-width: 100%;
    /* background-color: #e2dfcf; */
    padding: 24px;
    margin: 0;
    display: flex;
    align-items: center;
    transition: all 2s ease-in-out;
  }

  .live {
    background-color: #e2dfcf;
    color: #004a22;
    font-weight: bold;
    transition: all 2s ease-in-out;
  }

  .live:hover {
    cursor: pointer;
  }

  .dateRow--header--wrapper .dateRow--header--tutorial {
    width: 92.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(103, 36, 131, 1);
    border-radius: 5px;
    padding: 4px 8px;
    margin-bottom: 12px;
    transition: all 2s ease-in-out;
  }

  .today__tutorial--container {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 12px 0.75rem 96px 0.75rem;
    background-color: rgba(0, 74, 35, 1);
    border-top: 2px solid #004a22;
    transition: all 2s ease-in-out;
  }

  .mobiledock--tutorial {
    text-align: flex-start;
    padding: 10px 12px;
    transition: all 2s ease-in-out;
  }

  .mobiledock--tutorial--inactive {
    display: none;
  }

  .score__display {
    color: #e2dfcf;
    transition: all 2s ease-in-out;
    display: none;
  }

  .mobileDockRow--tutorial {
    height: auto;
    width: 100%;
    box-shadow:
      inset -15px 15px 15px rgba(0, 0, 0, 0.05),
      inset 0 0 30px rgba(226, 223, 207, 0.5);
    padding-bottom: 18px;
    border-radius: 5px;
    transition: all 2s ease-in-out;
  }

  .dateRow--header--tutorial {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background-color: rgba(103, 36, 131, 1);
    padding: 4px 8px;
    margin-bottom: 12px;
    transition: all 2s ease-in-out;
  }

  .score__row--tutorial {
    height: max-content;
    width: 100%;
    padding: 0 0 6px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: rgba(103, 36, 131, 1);
    border-radius: 5px 5px 5px 0;
    padding: 4px 8px;
    transition: all 2s ease-in-out;
  }

  .score__row label,
  .score__row--moment label,
  .score__row--tutorial label {
    color: #e2dfcf;
    font-weight: 600;
    transition: all 2s ease-in-out;
  }

  .tile.tut--inactive {
    display: none;
  }
  
  .day__icon--trash {
    display: block;
    position: relative;
    right: 2%;
    color: #004a22;
    opacity: 0.25;
  }

  .tutorial.access--tutorial,
  .dateRow--header--tutorial,
  .score__row--tutorial {
    width: 100%;
    border-radius: 5px 5px 0 0;
    padding-left: 4px;
    transition: all 2s ease-in-out;
  }

  .tutorial.access--tutorial.blank,
  .dateRow--tutorial.blank,
  .dateRow--header--tutorial.blank,
  .score__row--tutorial.blank {
    background-color: rgba(0, 0, 0, 0);
    transition: all 2s ease-in-out;
  }

  .tutorial.access--tutorial.blank label,
  .dateRow--header--tutorial.blank label,
  .score__row--tutorial.blank label {
    color: #e2dfcf;
    transition: all 2s ease-in-out;
  }

  .tutorial.access--tutorial.prime,
  .dateRow--tutorial.prime,
  .dateRow--header--tutorial.prime,
  .score__row--tutorial.prime {
    width: 100%;
    background-color: #e2dfcf;
    border: 4px solid #e2dfcf;
    transition: all 2s ease-in-out;
  }

  .tutorial.access--tutorial.prime label,
  .dateRow--header--tutorial.prime label,
  .dateRow--tutorial.prime label,
  .score__row--tutorial.prime label {
    color: #004a22;
    transition: all 2s ease-in-out;
  }

  .tutorial.access--tutorial.process,
  .dateRow--header--tutorial.process,
  .score__row--tutorial.process {
    background-color: rgba(103, 36, 131, 1);
    border: 4px solid rgba(103, 36, 131, 1);
    transition: all 2s ease-in-out;
  }

  .tutorial.access--tutorial.process label,
  .dateRow--header--tutorial.process label,
  .score__row--tutorial.process label {
    color: #f5f5f5;
    transition: all 2s ease-in-out;
  }

  .tutorial.access--tutorial.plan,
  .dateRow--header--tutorial.plan,
  .score__row--tutorial.plan {
    background-color: #f5f5f5;
    border: 4px solid #f5f5f5;
    transition: all 2s ease-in-out;
  }

  .tutorial.access--tutorial.plan label,
  .dateRow--header--tutorial.plan label,
  .score__row--tutorial.plan label {
    color: #004a22;
    transition: all 2s ease-in-out;
  }

  .weekSlider.tutorial {
    -webkit-appearance: none;
    width: 100%;
    height: 25px;
    border-radius: 0 0 5px 5px;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    background-color: rgba(226, 223, 207, 0.2);
    transition: all 2s ease-in-out;
  }

  .weekSlider.tutorial::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 25px;
    height: 25px;
    cursor: grab;
    border-radius: 0 0 5px 5px;
    background-color: #e2dfcf;
    transition: all 2s ease-in-out;
  }

  #opac50,
  .opac50 {
    opacity: 0.25;
    transition: all 1s ease-in-out;
  }
}

@media (min-width: 1441px)  {
  /* Tutorial */
  #crossIcon {
    transform: scale(3);
    color: rgba(0, 74, 35, 1);
  }

  #crossIcon:hover,
  #crossIcon:active,
  #crossIcon:focus,
  #nextIcon:hover,
  #nextIcon:active,
  #nextIcon:focus {
    cursor: pointer;
    color: rgba(0, 74, 35, 1);
  }

  #nextIcon {
    transform: scale(3);
    color: rgba(0, 74, 35, 1);
  }

  .tutorialpage--container {
    height: 90vh;
    width: 100%;
    min-width: 960px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 12px 24px;
    background-color: aqua;
  }

  .instruction {
    height: 12vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0 0 0;
    padding: 0;
    background-color: blue;
    transition: all 1s;
  }

  .instruction div:first-child {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    padding-right: 48px;
  }

  .instruction div:last-child {
    width: 8%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    text-align: end;
    background-color: coral;
  }

  .instruction div:last-child a:first-child {
    margin-left: 12px;
  }

  .tutorialNav {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .tutorialNav button {
    width: 72px;
  }

  .instruction h3 {
    color: #004a22;
  }

  .weekSlider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 25px;
    border-radius: 0 0 5px 5px;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    background-color: rgba(245, 245, 245, 0);
    /* outline: 0.5px solid rgba(0, 74, 35, 0.25); */
  }

  .weekSlider:hover {
    opacity: 1;
  }

  .weekSlider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 25px;
    border-radius: 5px;
    cursor: grab;
    border-radius: 0 0 5px 5px;
    background-color: #004a22;
    cursor: pointer;
  }

  .weekSlider.off::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 25px;
    border-radius: 5px;
    cursor: grab;
    border-radius: 0 0 5px 5px;
    background-color: rgba(0, 74, 35, 0.25);
  }

  .range-slider-container {
    position: relative;
  }

  .tooltip {
    position: absolute;
    top: 30px; /* Adjust the tooltip position as needed */
    background-color: #333;
    color: fuchsia;
    padding: 4px 90px;
    border-radius: 4px;
    font-size: 35px;
  }

  /* Day Screen */
  .updateday--container,
  .createday--container {
    min-height: 500px;
    height: 750px;
    max-height: 900px;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0);
    transition: all 2s ease-in-out;
    padding: 0 24px;

  }

  .updateday--container form,
  .createday--container form {
    width: 100%;
    min-height: 500px;
    height: 750px;
    max-height: 900px;
  }

  .tile__container {
    height: 85%;
    /* background-color: yellow; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 24px 0 12px 0;
  }

  .tile {
    min-height: 250px;
    height: auto;
    max-height: 100%;
    min-width: 30%;
    width: auto;
    padding: 12px 18px;
    border-radius: 8px;
    background-color: rgba(0, 74, 34, 0.05);
    box-shadow: 0 4px 6px rgba(0, 74, 34, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    margin: 0px 12px;
    overflow-y: scroll;
    opacity: 1;
    transition: opacity 2s ease-in-out;
  }

  .tile.reg {
    padding: 36px;
    min-height: 375px;
    max-height: 85%;
    height: auto;
    min-width: 50%;
    width: auto;
    max-width: 75%;
  }

  .cornerStateOn {
    border-radius: 5px 5px 0px 0px;
  }

  .cornerStateOff {
    border-radius: 5px 5px 5px 0px;
  }

  .yeartile--day {
    height: auto;
    width: 100%;
    border-radius: 5px;
    background-color: whitesmoke;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
    z-index: 1;
    margin: 12px 0;
  }

  .yeartile--day:nth-child(7) {
    margin: 6px 0px 12px 0px;
    background-color: fuchsia;
  }

  .upperDiv {
    width: 100%;
  }

  .iconDiv--day {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .dateRow--header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #004a22;
    border-radius: 5px;
    padding: 4px 8px;
    margin-bottom: 12px;
  }

  .mobileSaveDiv {
    display: none;
  }

  .dateRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 5px;
  }

  .dateRow input {
    min-width: 30%;
    width: 50%;
    background-color: #f5f5f5;
    outline: none;
    border: none;
    padding: 4px 8px;
    border-radius: 0 5px 5px 0;
  }

  .dateRow label {
    padding: 4px 8px;
    background-color: #004a22;
    border-radius: 5px 0 0 5px;
    color: #e2dfcf;
  }

  .primeContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .tile.centre {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    overflow-y: scroll;
  }

  .tile.centre textarea {
    width: 100%;
  }

  .scoring__row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    margin: 12px 0;
  }

  .scoring__row span,
  .moment__submission span {
    position: relative;
  }

  .score__row--moment {
    height: max-content;
    width: 100%;
    padding: 0 0 6px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: #f5f5f5;
    border-radius: 5px 5px 5px 0;
    padding: 4px 8px;
    z-index: 1;
  }

  .score__row--moment.complete {
    border-radius: 5px 5px 0 0;
    z-index: 1;
  }

  .score__row {
    height: max-content;
    width: 100%;
    padding: 4px 8px 6px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: #004a22;
    border-radius: 8px 8px 0 0;
  }

  .score__row label,
  .score__row--moment label {
    color: #e2dfcf;
    font-weight: 600;
  }

  .score__row textarea,
  .score__row textarea:focus {
    border-left: 1px dashed rgba(125, 125, 125, 0.5);
    padding-left: 12px;
  }

  .score__column {
    width: 10%;
    position: relative;
    top: 1%;
    right: 2.5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .score__column span {
    font-size: 0.6em;
    padding: 0;
    margin: 0;
  }

  .prime.submission {
    width: 100%;
    padding: 12px 0 6px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 12px;
    border-bottom: 1px dashed rgba(125, 125, 125, 0.2);
  }

  .prime.submission:first-child {
    padding-top: 24px;
  }

  .prime.submission textarea {
    height: 100%;
  }

  .day__icon--trash {
    display: block;
    position: relative;
    right: 1%;
    color: #004a22;
    opacity: 0.25;
  }

  .prime.submission:last-child {
    border-bottom: none;
    padding-bottom: 6px;
  }

  .tile.right {
    padding: 16px 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: start;
    /* box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05); */
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .review.submission {
    width: 100%;
    padding: 12px 0 6px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 12px;
    border-bottom: 1px dashed rgba(125, 125, 125, 0.2);
  }

  .review.submission textarea {
    height: 100%;
  }

  .review.submission:nth-child(2) {
    padding: 0 0 6px 0;
  }

  .moment__submission {
    width: 100%;
    padding-top: 6px;
  }

  .score.label {
    width: 100%;
    font-weight: 600;
    color: #e2dfcf;
  }

  .formInput.moment {
    width: 100%;
    height: 100%;
    padding: 8px;
    font-weight: 400;
    color: #004a22;
  }

  .formInput.moment::placeholder {
    font-weight: 400;
    color: #004a22;
    opacity: 0.3;
  }

  .score__row.tooltip {
    position: relative;
    display: inline-block;
  }

  .textarea-tooltip {
    position: absolute;
    right: -8px;
    top: -4px;
    background-color: #f5f5f5;
    box-shadow: 2.5px -2.5px 0px rgba(0, 74, 34, 1);
    display: none;
    font-weight: 600;
    color: #004a22;
    border-left: 1px dashed rgba(125, 125, 125, 0.5);
    height: auto;
    width: auto;
    padding: 4px 6px;
    border-radius: 5px;
    margin: 6px;
    justify-content: center;
    white-space: nowrap;
  }

  .score__row:hover .textarea-tooltip {
    display: block;
  }

  .textarea-tooltip-content {
    font-size: 10px;
    color: #004a22;
  }

  .formInput,
  .formInput:focus,
  .formInput:active {
    height: auto;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
  }

  .dock.footer {
    height: auto;
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 150;
  }

 

  .row.button {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .row.button button {
    width: auto;
  }

  .mobileDockRow {
    display: none;
  }

  .tile textarea {
    width: 100%;
    resize: none;
    overflow-y: hidden;
    height: auto;
  }

  .objective__window {
    width: auto;
    height: auto;
    background-color: #e2dfcf;
    border-radius: 5px;
    border: 2px solid #004a22;
    display: block;
    position: absolute;
    left: 10%;
    top: 15%;
    z-index: 1000;
    cursor: grab;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
  }

  .objective__window--inactive {
    display: none;
  }

  /* Tutorial */
  .dateRow--tutorial {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(103, 36, 131, 1);
    border-radius: 5px;
    transition: all 2s ease-in-out;
  }

  .dateRow input,
  .dateRow--tutorial input {
    background-color: #f5f5f5;
    outline: none;
    border: none;
    padding: 4px 8px;
    border-radius: 0 5px 5px 0;
    transition: all 2s ease-in-out;
  }

  .dateRow--tutorial label {
    padding: 4px 8px;
    border-radius: 5px 0 0 5px;
    color: #e2dfcf;
    transition: all 2s ease-in-out;
  }

  .yeartile--day--tutorial {
    height: auto;
    width: 100%;
    border-radius: 5px;
    background-color: whitesmoke;
    box-shadow:
      -15px 15px 15px rgba(0, 0, 0, 0.05),
      0 0 30px rgba(226, 223, 207, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
    z-index: 1;
    margin: 12px 0;
    transition: all 2s ease-in-out;
  }

  .tutorial__button {
    height: 18px;
    width: auto;
    background-color: #004a22;
    color: #e2dfcf;
    padding: 8px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    font-weight: bold;
    transition: all 2s ease-in-out;
  }

  .liveDock {
    max-width: 100%;
    /* background-color: #e2dfcf; */
    padding: 24px;
    margin: 0;
    display: flex;
    align-items: center;
    transition: all 2s ease-in-out;
  }

  .live {
    background-color: #e2dfcf;
    color: #004a22;
    font-weight: bold;
    transition: all 2s ease-in-out;
  }

  .live:hover {
    cursor: pointer;
  }

  .dateRow--header--wrapper .dateRow--header--tutorial {
    width: 92.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(103, 36, 131, 1);
    border-radius: 5px;
    padding: 4px 8px;
    margin-bottom: 12px;
    transition: all 2s ease-in-out;
  }

  .today__tutorial--container {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 12px 0.75rem 96px 0.75rem;
    background-color: rgba(0, 74, 35, 1);
    border-top: 2px solid #004a22;
    transition: all 2s ease-in-out;
  }

  .mobiledock--tutorial {
    text-align: flex-start;
    padding: 10px 12px;
    transition: all 2s ease-in-out;
  }

  .mobiledock--tutorial--inactive {
    display: none;
  }

  .score__display {
    color: #e2dfcf;
    transition: all 2s ease-in-out;
    display: none;
  }

  .mobileDockRow--tutorial {
    height: auto;
    width: 100%;
    box-shadow:
      inset -15px 15px 15px rgba(0, 0, 0, 0.05),
      inset 0 0 30px rgba(226, 223, 207, 0.5);
    padding-bottom: 18px;
    border-radius: 5px;
    transition: all 2s ease-in-out;
  }

  .dateRow--header--tutorial {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background-color: rgba(103, 36, 131, 1);
    padding: 4px 8px;
    margin-bottom: 12px;
    transition: all 2s ease-in-out;
  }

  .score__row--tutorial {
    height: max-content;
    width: 100%;
    padding: 0 0 6px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: rgba(103, 36, 131, 1);
    border-radius: 5px 5px 5px 0;
    padding: 4px 8px;
    transition: all 2s ease-in-out;
  }

  .score__row label,
  .score__row--moment label,
  .score__row--tutorial label {
    color: #e2dfcf;
    font-weight: 600;
    transition: all 2s ease-in-out;
  }

  .tile.tut--inactive {
    display: none;
  }

  .tutorial.access--tutorial,
  .dateRow--header--tutorial,
  .score__row--tutorial {
    width: 100%;
    border-radius: 5px 5px 0 0;
    padding-left: 4px;
    transition: all 2s ease-in-out;
  }

  .tutorial.access--tutorial.blank,
  .dateRow--tutorial.blank,
  .dateRow--header--tutorial.blank,
  .score__row--tutorial.blank {
    background-color: rgba(0, 0, 0, 0);
    transition: all 2s ease-in-out;
  }

  .tutorial.access--tutorial.blank label,
  .dateRow--header--tutorial.blank label,
  .score__row--tutorial.blank label {
    color: #e2dfcf;
    transition: all 2s ease-in-out;
  }

  .tutorial.access--tutorial.prime,
  .dateRow--tutorial.prime,
  .dateRow--header--tutorial.prime,
  .score__row--tutorial.prime {
    width: 100%;
    background-color: #e2dfcf;
    border: 4px solid #e2dfcf;
    transition: all 2s ease-in-out;
  }

  .tutorial.access--tutorial.prime label,
  .dateRow--header--tutorial.prime label,
  .dateRow--tutorial.prime label,
  .score__row--tutorial.prime label {
    color: #004a22;
    transition: all 2s ease-in-out;
  }

  .tutorial.access--tutorial.process,
  .dateRow--header--tutorial.process,
  .score__row--tutorial.process {
    background-color: rgba(103, 36, 131, 1);
    border: 4px solid rgba(103, 36, 131, 1);
    transition: all 2s ease-in-out;
  }

  .tutorial.access--tutorial.process label,
  .dateRow--header--tutorial.process label,
  .score__row--tutorial.process label {
    color: #f5f5f5;
    transition: all 2s ease-in-out;
  }

  .tutorial.access--tutorial.plan,
  .dateRow--header--tutorial.plan,
  .score__row--tutorial.plan {
    background-color: #f5f5f5;
    border: 4px solid #f5f5f5;
    transition: all 2s ease-in-out;
  }

  .tutorial.access--tutorial.plan label,
  .dateRow--header--tutorial.plan label,
  .score__row--tutorial.plan label {
    color: #004a22;
    transition: all 2s ease-in-out;
  }

  .weekSlider.tutorial {
    -webkit-appearance: none;
    width: 100%;
    height: 25px;
    border-radius: 0 0 5px 5px;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    background-color: rgba(226, 223, 207, 0.2);
    transition: all 2s ease-in-out;
  }

  .weekSlider.tutorial::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 25px;
    height: 25px;
    cursor: grab;
    border-radius: 0 0 5px 5px;
    background-color: #e2dfcf;
    transition: all 2s ease-in-out;
  }

  #opac50,
  .opac50 {
    opacity: 0.25;
    transition: all 1s ease-in-out;
  }
}

