@media only screen and (max-width: 480px) {
  #d3demo5mobile {
    width: 156vw;
    height: 388px;
  }

  #d3demo5tablet {
    width: 175vw;
    height: 388px;
  }

  #d3demo5desktop {
    width: 175vw;
    height: 388px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {
  #d3demo5mobile {
    width: 80vw;
    height: 388px;
  }

  #d3demo5tablet {
    width: 80vw;
    height: 388px;
  }

  #d3demo5desktop {
    width: 80vw;
    height: 388px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 767px) and (orientation: portrait) {
  #d3demo5mobile {
    width: 175vw;
    height: 50vh;
  }

  #d3demo5tablet {
    width: 175vw;
    height: 50vh;
  }

  #d3demo5desktop {
    width: 175vw;
    height: 50vh;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  #d3demo5mobile {
    width: 100%;
    height: 388px;
  }

  #d3demo5tablet {
    width: 100%;
    height: 388px;
  }

  #d3demo5desktop {
    width: 100%;
    height: 388px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  #d3demo5mobile {
    width: 110vw;
    height: 388px;
  }

  #d3demo5tablet {
    width: 100%;
    height: 388px;
  }

  #d3demo5desktop {
    width: 95vw;
    height: 388px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) and (orientation: portrait) {
  #d3demo5mobile {
    width: 100%;
    height: 388px;
  }

  #d3demo5tablet {
    width: 100%;
    height: 388px;
  }

  #d3demo5desktop {
    width: 100%;
    height: 388px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) and (orientation: landscape) {
  #d3demo5mobile {
    width: 100%;
    height: 388px;
  }

  #d3demo5tablet {
    width: 100%;
    height: 388px;
  }

  #d3demo5desktop {
    width: 100%;
    height: 388px;
  }
}

@media only screen and (min-width: 1201px) {
  #d3demo5desktop {
    width: 100%;
    height: 388px;
  }
}
