@media (max-width: 480px) {
  .loggedInMain {
    height: 120vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .loggedOutMain h1 {
    margin-bottom: 12px;
    font-weight: 600;
    color: #004a22;
    font-size: clamp(4em, 6em, 8em);
  }

  #testState {
    display: "none";
  }

  #testStateActive {
    display: "flex";
  }

  .loggedOutMain div p {
    padding: 0 24px;
  }

  .topSection {
    height: 500px;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: start;
    margin-bottom: 72px;
  }

  .topSection div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 45%;
  }

  .loggedOutMain {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 24px 0;
  }

  .rtbSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #004a22;
    height: auto;
    margin: 24px 0;
    width: 100%;
    padding: 72px 48px;
    /* overflow: hidden; */
  }

  .rtbLP {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: start;
    width: 100%;
    color: #e2dfcf;
    margin-bottom: 48px;
  }

  .rtbLP div {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  .rtbLP div span {
    text-align: start;
  }

  .sc {
    width: 50px;
    position: relative;
  }

  .sc.two {
    position: absolute;
    width: 50px;
    left: 90px;
    top: 24px;
  }

  .sc.three {
    position: fixed;
    width: 25px;
    left: 180px;
    top: 180px;
  }

  .sc.eight,
  .sc.nine,
  .sc.ten {
    display: none;
  }

  .sc.four {
    position: absolute;
    width: 100px;
    left: 33%;
    top: 360px;
    justify-self: center;
  }

  .sc.five {
    position: absolute;
    width: 75px;
    left: 48px;
    top: 480px;
    justify-self: center;
  }

  .sc.six {
    position: absolute;
    width: 125px;
    right: 48px;
    top: 100px;
    justify-self: center;
  }

  .sc.seven {
    position: fixed;
    width: 75px;
    right: 24px;
    top: 320px;
    justify-self: center;
  }

  .sc.eleven {
    position: fixed;
    width: 125px;
    right: 24px;
    top: 400px;
    justify-self: center;
  }

  .sc.twelve {
    position: fixed;
    width: 125px;
    right: 24px;
    top: 530px;
    justify-self: center;
  }

  .sc.fourteen {
    position: fixed;
    width: 125px;
    right: 24px;
    top: 660px;
    justify-self: center;
  }

  .circleButton {
    height: 100px;
    width: 100px;
    background-color: #004a22;
    border: none;
    border-radius: 50%;
    white-space: normal;
    color: #e2dfcf;
    transform: scale(1);
  }

  .storySection {
    height: 120vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 24px 48px;
  }

  .storySection div:first-child {
    position: relative;
    height: auto;
    width: 100%;
    text-align: start;
  }

  .storySection div:first-child h2 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    padding: 0 24px 0 0;
    font-size: 2em;
  }

  .exhaustImage {
    position: relative;
    top: 0;
    left: -100px;
    width: 100%;
    opacity: 0.25;
    z-index: 1;
  }

  .storySection div:last-child {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
  }

  .storySection div:last-child span {
    margin-top: 24px;
    color: rgba(0, 74, 35, 1);
  }

  .storySection a {
    font-weight: 700;
    text-decoration: none;
    position: relative;
    color: rgba(0, 74, 35, 1);
  }

  .storySection a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -0.2em; /* Adjust the value to increase or decrease the distance */
    width: 100%;
    height: 0.1em; /* Adjust the value to control the thickness of the underline */
    background-color: #; /* Adjust the color as needed */
  }

  .finalSection {
    background-color: #004a22;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    padding: 24px 24px;
  }

  .finalSection h1 {
    color: #e2dfcf;
    padding: 12px;
    font-size: 24px;
    z-index: 3;
  }

  .finalSection span {
    padding: 12px;
    margin-bottom: 24px;
    color: #e2dfcf;
  }

  .finalSection a {
    padding: 0 0 0 12px;
  }

  .mobile_main {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px 0;
  }

  .button__row,
  .button__row_two {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0 0 0;
  }

  .button__row button:first-child {
    margin-right: 12px;
    text-decoration: none;
  }

  .button__row a:last-child {
    text-decoration: none;
  }

  a .lpButton,
  .lpButton {
    height: auto;
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 12px;
    background-color: #004a22;
    color: #e2dfcf;
    border-radius: 30px;
    cursor: pointer;
    border: none;
    outline: none;
    text-decoration: none;
  }

  a .lpButton {
    color: #e2dfcf;
    text-decoration: none;
  }

  a .lpButton:hover,
  a .lpButton:focus,
  a .lpButton:visited {
    text-decoration: none;
    color: #e2dfcf;
  }

  #lpTutorial {
    grid-column: 5 / auto;
    grid-row: 4 / auto;
  }

  h2 {
    color: #004a22;
  }

  .side {
    display: none;
  }

  .salesblock {
    display: none;
  }

  .newsfeed {
    height: 100vh;
    width: 95%;
    margin-top: 12px;
    overflow: scroll;
    background-color: whitesmoke;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    padding: 16px 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
  }

  .missionLP {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .missionLP section {
    height: 100vh;
    width: 95%;
    background-color: #004a22;
    border-radius: 5px;
    padding: 16px 20px;
    color: #e2dfcf;
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    text-align: start;
    position: relative;
  }

  #mis {
    font-size: 2em;
  }

  .newsitem {
    margin: 4px 0;
    white-space: none;
  }

  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 24px;
  }

  .title span {
    font-size: 10px;
  }

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .headerIcon {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #004a22;
    color: whitesmoke;
    position: relative;
    margin: 0 4px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nameDate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 3px;
  }

  .nameDate h6 {
    margin: 0;
    padding: 4px 0 0 0;
    font-size: 12px;
  }

  .nameDate span {
    margin: 0;
    padding: 0;
    font-size: 10px;
  }

  .quote,
  .insight,
  .blog,
  .video,
  .podcast,
  .picture {
    height: auto;
    width: 100%;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
  }

  .quote span,
  .insight p,
  .blog span,
  .blog a,
  .video span,
  .podcast span {
    margin-top: 4px;
    margin-left: 12px;
    text-decoration: none;
    color: #004a22;
  }

  .dividingLine {
    width: 100%;
    border-top: 3px solid #004a22;
  }

  .dividingLineThin {
    width: 75%;
    border-top: 5px solid rgba(0, 74, 35, 1);
  }

  .community {
    display: none;
  }

  .insightRow {
    max-height: 4rem;
    width: 100%;
    padding: 4px;
    background-color: #004a22;
    border-radius: 5px;
    color: whitesmoke;
    display: flex;
    flex-direction: column;
  }

  .insightRow:hover {
    background-color: whitesmoke;
    color: #004a22;
    border: 1px solid #004a22;
  }

  .tutorialtile {
    height: auto;
    width: 100%;
    border-radius: 5px;
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    /* border: 2px solid #004a22; */
  }

  .rtb3 {
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }

  .rtb43 {
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 90vh);
    row-gap: 12px;
  }

  .rtb44 {
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 90vh);
    row-gap: 12px;
  }

  .rtb h2 {
    color: #004a22;
    font-weight: 700;
  }

  .rtb3 .one {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: center;
    padding: 36px 9px 24px 9px;
  }

  .rtb43 .one,
  .rtb44 .one {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 24px 12px;
  }

  .rtb4 img {
    border: 2px solid rgba(0, 74, 35, 0.1);
    border-radius: 5px;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.1);
  }

  .rtb3 .two {
    grid-row: 2 / 2;
    grid-column: 1 / 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    padding: 24px 9px 24px 9px;
  }

  .rtb43 .two,
  .rtb44 .two {
    grid-row: 2 / 2;
    grid-column: 1 / 1;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 12px;
  }

  .rtb3 .three {
    grid-row: 3 / 3;
    grid-column: 1 / 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    padding: 24px 9px 24px 9px;
  }

  .rtb43 .three,
  .rtb44 .three {
    grid-row: 3 / 3;
    grid-column: 1 / 1;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 12px;
  }

  .rtb44 .four {
    grid-row: 4 / 4;
    grid-column: 1 / 1;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 12px;
  }

  .rtb4 h3 {
    margin-bottom: 12px;
  }

  .tutorialIntro {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #004a22;
    border-radius: 5px;
    color: #e2dfcf;
    text-align: start;
    padding: 24px 12px;
  }

  .tutorialIntro h2,
  .tutorialIntro p {
    color: #e2dfcf;
  }

  .tutorialIntro h2 {
    line-height: 1.2em;
    padding-bottom: 24px;
  }

  .tutorialIntro img {
    position: absolute;
    right: 12px;
    top: 216px;
  }

  .pageDescriptor {
    background-color: #004a22;
    border: 2px solid #004a22;
    padding: 18px 6px 12px 6px;
    text-align: start;
    margin: 12px 0;
    border-radius: 5px;
    width: 100%;
  }

  .button__row_two .lpButton {
    background-color: #e2dfcf;
    color: rgba(0, 74, 35, 1);
    text-decoration: none;
  }

  .button__row_two .lpButton:first-child {
    margin-right: 12px;
  }

  .button__row_two a {
    text-decoration: none;
  }

  .pC {
    display: flex;
    flex-direction: row;
  }

  #pcHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
  }

  .pro,
  .con {
    width: 50%;
  }

  .featuredIn {
    height: 75vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .lp {
    font-weight: 500;
  }

  .tile.left .hidden {
    display: none;
  }

  /* Mobile Landing Page */

  .landingPage.mobile {
    height: 100vh;
    width: 100%;
  }

  .landingPage.mobile a {
    color: #004a22;
  }

  /* Animation */

  .main {
    height: auto;
    width: 100%;
    display: block;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
  }

  .part {
    font-size: 28px;
    font-weight: 700;
    height: 3.5rem;
    display: inline-flex;
    align-items: center;
    color: #004a22;
  }

  .part.two {
    font-size: 48px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    text-align: center;
    height: 5rem;
    background-color: #004a22;
  }

  .part.two span {
    height: 6rem;
    color: whitesmoke;
    line-height: 6rem;
    font-weight: 500;
    width: auto;
    position: relative;
    top: -1rem;
    margin: 0px 8px 0px 8px;
    animation: slide 15s ease-in-out infinite;
  }

  @keyframes slide {
    0% {
      top: 0rem;
    }
    15% {
      top: -6.6rem;
    }
    30% {
      top: -12.6rem;
    }
    45% {
      top: -18.6rem;
    }
    60% {
      top: -24.6rem;
    }
    75% {
      top: -30.6rem;
    }
    100% {
      top: 0rem;
    }
  }

  .newsFeedPlayer {
    width: 85vw;
    height: 50vw;
    top: 20%;
    left: 25%;
    z-index: 25;
    border-radius: 5px;
    border: 5px solid #004a22;
    margin-top: 12px;
  }

  .mobileHeader {
    background-color: #004a22;
    padding: 12px 24px 0 24px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .loggedInMain {
    height: 120vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .loggedOutMain h1 {
    margin-bottom: 12px;
    font-weight: 600;
    color: #004a22;
    font-size: clamp(4em, 6em, 8em);
  }

  #testState {
    display: "none";
  }

  #testStateActive {
    display: "flex";
  }

  .loggedOutMain div p {
    padding: 0 24px;
  }

  .topSection {
    height: 500px;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: start;
    margin-bottom: 72px;
  }

  .topSection div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 45%;
  }

  .loggedOutMain {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 24px 0;
  }

  .rtbSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #004a22;
    height: auto;
    margin: 24px 0;
    width: 100%;
    padding: 72px 48px;
    /* overflow: hidden; */
  }

  .rtbLP {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: start;
    width: 100%;
    color: #e2dfcf;
    margin-bottom: 48px;
  }

  .rtbLP div {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  .rtbLP div span {
    text-align: start;
  }

  .sc {
    width: 50px;
    position: relative;
  }

  .sc.two {
    position: absolute;
    width: 50px;
    left: 90px;
    top: 24px;
  }

  .sc.three {
    position: fixed;
    width: 25px;
    left: 180px;
    top: 180px;
  }

  .sc.eight,
  .sc.nine,
  .sc.ten {
    display: none;
  }

  .sc.four {
    position: absolute;
    width: 100px;
    left: 33%;
    top: 360px;
    justify-self: center;
  }

  .sc.five {
    position: absolute;
    width: 75px;
    left: 48px;
    top: 480px;
    justify-self: center;
  }

  .sc.six {
    position: absolute;
    width: 125px;
    right: 48px;
    top: 100px;
    justify-self: center;
  }

  .sc.seven {
    position: fixed;
    width: 75px;
    right: 24px;
    top: 320px;
    justify-self: center;
  }

  .sc.eleven {
    position: fixed;
    width: 125px;
    right: 24px;
    top: 400px;
    justify-self: center;
  }

  .sc.twelve {
    position: fixed;
    width: 125px;
    right: 24px;
    top: 530px;
    justify-self: center;
  }

  .sc.fourteen {
    position: fixed;
    width: 125px;
    right: 24px;
    top: 660px;
    justify-self: center;
  }

  .circleButton {
    height: 100px;
    width: 100px;
    background-color: #004a22;
    border: none;
    border-radius: 50%;
    white-space: normal;
    color: #e2dfcf;
    transform: scale(1);
  }

  .storySection {
    height: 120vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 24px 48px;
  }

  .storySection div:first-child {
    position: relative;
    height: auto;
    width: 100%;
    text-align: start;
  }

  .storySection div:first-child h2 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    padding: 0 24px 0 0;
    font-size: 2em;
  }

  .exhaustImage {
    position: relative;
    top: 0;
    left: -100px;
    width: 100%;
    opacity: 0.25;
    z-index: 1;
  }

  .storySection div:last-child {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
  }

  .storySection div:last-child span {
    margin-top: 24px;
    color: rgba(0, 74, 35, 1);
  }

  .storySection a {
    font-weight: 700;
    text-decoration: none;
    position: relative;
    color: rgba(0, 74, 35, 1);
  }

  .storySection a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -0.2em; /* Adjust the value to increase or decrease the distance */
    width: 100%;
    height: 0.1em; /* Adjust the value to control the thickness of the underline */
    background-color: #; /* Adjust the color as needed */
  }

  .finalSection {
    background-color: #004a22;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    padding: 24px 24px;
  }

  .finalSection h1 {
    color: #e2dfcf;
    padding: 12px;
    font-size: 24px;
    z-index: 3;
  }

  .finalSection span {
    padding: 12px;
    margin-bottom: 24px;
    color: #e2dfcf;
  }

  .finalSection a {
    padding: 0 0 0 12px;
  }

  .mobile_main {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px 0;
  }

  .button__row,
  .button__row_two {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0 0 0;
  }

  .button__row button:first-child {
    margin-right: 12px;
    text-decoration: none;
  }

  .button__row a:last-child {
    text-decoration: none;
  }

  a .lpButton,
  .lpButton {
    height: auto;
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 12px;
    background-color: #004a22;
    color: #e2dfcf;
    border-radius: 30px;
    cursor: pointer;
    border: none;
    outline: none;
    text-decoration: none;
  }

  a .lpButton {
    color: #e2dfcf;
    text-decoration: none;
  }

  a .lpButton:hover,
  a .lpButton:focus,
  a .lpButton:visited {
    text-decoration: none;
    color: #e2dfcf;
  }

  #lpTutorial {
    grid-column: 5 / auto;
    grid-row: 4 / auto;
  }

  h2 {
    color: #004a22;
  }

  .side {
    display: none;
  }

  .salesblock {
    display: none;
  }

  .newsfeed {
    height: 100vh;
    width: 95%;
    margin-top: 12px;
    overflow: scroll;
    background-color: whitesmoke;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    padding: 16px 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
  }

  .missionLP {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .missionLP section {
    height: 100vh;
    width: 95%;
    background-color: #004a22;
    border-radius: 5px;
    padding: 16px 20px;
    color: #e2dfcf;
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    text-align: start;
    position: relative;
  }

  #mis {
    font-size: 2em;
  }

  .newsitem {
    margin: 4px 0;
    white-space: none;
  }

  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 24px;
  }

  .title span {
    font-size: 10px;
  }

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .headerIcon {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #004a22;
    color: whitesmoke;
    position: relative;
    margin: 0 4px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nameDate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 3px;
  }

  .nameDate h6 {
    margin: 0;
    padding: 4px 0 0 0;
    font-size: 12px;
  }

  .nameDate span {
    margin: 0;
    padding: 0;
    font-size: 10px;
  }

  .quote,
  .insight,
  .blog,
  .video,
  .podcast,
  .picture {
    height: auto;
    width: 100%;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
  }

  .quote span,
  .insight p,
  .blog span,
  .blog a,
  .video span,
  .podcast span {
    margin-top: 4px;
    margin-left: 12px;
    text-decoration: none;
    color: #004a22;
  }

  .dividingLine {
    width: 100%;
    border-top: 3px solid #004a22;
  }

  .dividingLineThin {
    width: 75%;
    border-top: 5px solid rgba(0, 74, 35, 1);
  }

  .community {
    display: none;
  }

  .insightRow {
    max-height: 4rem;
    width: 100%;
    padding: 4px;
    background-color: #004a22;
    border-radius: 5px;
    color: whitesmoke;
    display: flex;
    flex-direction: column;
  }

  .insightRow:hover {
    background-color: whitesmoke;
    color: #004a22;
    border: 1px solid #004a22;
  }

  .tutorialtile {
    height: auto;
    width: 100%;
    border-radius: 5px;
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    background-color: whitesmoke;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
  }

  .rtb3 {
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }

  .rtb4 {
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 35vh 65vh 60vh 35vh;
    row-gap: 12px;
  }

  .rtb h2 {
    color: #004a22;
    font-weight: 700;
  }

  .rtb3 .one {
    background-color: indianred;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding: 36px 9px 24px 9px;
  }

  .rtb4 .one {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 24px 12px;
  }

  .rtb4 img {
    border: 2px solid rgba(0, 74, 35, 0.1);
    border-radius: 5px;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.1);
  }

  .rtb3 .two {
    grid-row: 2 / 2;
    grid-column: 1 / 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding: 24px 9px 24px 9px;
  }

  .rtb4 .two {
    grid-row: 2 / 2;
    grid-column: 1 / 1;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 12px;
  }

  .rtb3 .three {
    grid-row: 3 / 3;
    grid-column: 1 / 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding: 24px 9px 24px 9px;
  }

  .rtb4 .three {
    grid-row: 3 / 3;
    grid-column: 1 / 1;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 12px;
  }

  .rtb4 .four {
    grid-row: 4 / 4;
    grid-column: 1 / 1;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 12px;
  }

  .rtb4 h3 {
    margin-bottom: 12px;
  }

  .tutorialIntro {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #004a22;
    border-radius: 5px;
    color: #e2dfcf;
    text-align: center;
    padding: 24px 12px;
  }

  .tutorialIntro p:last-child {
    padding: 12px 6px 0 6px;
  }

  .tutorialIntro h2,
  .tutorialIntro p {
    color: #e2dfcf;
  }

  .tutorialIntro h2 {
    line-height: 1.5em;
  }

  .button__row_two .lpButton {
    background-color: #e2dfcf;
    color: rgba(0, 74, 35, 1);
    text-decoration: none;
  }

  .button__row_two .lpButton:first-child {
    margin-right: 12px;
  }

  .button__row_two a {
    text-decoration: none;
  }

  .pC {
    display: flex;
    flex-direction: row;
  }

  #pcHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
  }

  .pro,
  .con {
    width: 50%;
  }

  .featuredIn {
    height: 75vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .lp {
    font-weight: 500;
  }

  .tile.left .hidden {
    display: none;
  }

  /* Mobile Landing Page */

  .landingPage.mobile {
    height: 100vh;
    width: 100%;
  }

  .landingPage.mobile a {
    color: #004a22;
  }

  /* Animation */

  .main {
    height: auto;
    width: 100%;
    display: block;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
  }

  .part {
    font-size: 28px;
    font-weight: 700;
    height: 3.5rem;
    display: inline-flex;
    align-items: center;
    color: #004a22;
  }

  .part.two {
    font-size: 48px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    text-align: center;
    height: 5rem;
    background-color: #004a22;
  }

  .part.two span {
    height: 6rem;
    color: whitesmoke;
    line-height: 6rem;
    font-weight: 500;
    width: auto;
    position: relative;
    top: -1rem;
    margin: 0px 8px 0px 8px;
    animation: slide 15s ease-in-out infinite;
  }

  @keyframes slide {
    0% {
      top: 0rem;
    }
    15% {
      top: -6.6rem;
    }
    30% {
      top: -12.6rem;
    }
    45% {
      top: -18.6rem;
    }
    60% {
      top: -24.6rem;
    }
    75% {
      top: -30.6rem;
    }
    100% {
      top: 0rem;
    }
  }

  .newsFeedPlayer {
    width: 85vw;
    height: 50vw;
    top: 20%;
    left: 25%;
    z-index: 25;
    border-radius: 5px;
    border: 5px solid #004a22;
    margin-top: 12px;
  }

  .mobileHeader {
    background-color: #004a22;
    padding: 12px 24px 0 24px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .loggedInMain {
    height: 120vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .loggedOutMain h1 {
    margin-bottom: 12px;
    font-weight: 600;
    color: #004a22;
    font-size: clamp(4em, 6em, 8em);
  }

  #testState {
    display: "none";
  }

  #testStateActive {
    display: "flex";
  }

  .loggedOutMain div p {
    padding: 0 24px;
  }

  .topSection {
    height: 500px;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: start;
    margin-bottom: 72px;
  }

  .topSection div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 45%;
  }

  .loggedOutMain {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 24px 0;
  }

  .rtbSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #004a22;
    height: auto;
    margin: 24px 0;
    width: 100%;
    padding: 72px 48px;
    /* overflow: hidden; */
  }

  .rtbLP {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: start;
    width: 100%;
    color: #e2dfcf;
    margin-bottom: 48px;
  }

  .rtbLP div {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }

  .rtbLP div span {
    text-align: start;
  }

  .sc {
    width: 50px;
    position: relative;
  }

  .sc.two {
    position: absolute;
    width: 50px;
    left: 90px;
    top: 24px;
  }

  .sc.three {
    position: fixed;
    width: 25px;
    left: 180px;
    top: 180px;
  }

  .sc.eight,
  .sc.nine,
  .sc.ten {
    display: none;
  }

  .sc.four {
    position: absolute;
    width: 100px;
    left: 33%;
    top: 360px;
    justify-self: center;
  }

  .sc.five {
    position: absolute;
    width: 75px;
    left: 48px;
    top: 480px;
    justify-self: center;
  }

  .sc.six {
    position: absolute;
    width: 125px;
    right: 48px;
    top: 100px;
    justify-self: center;
  }

  .sc.seven {
    position: fixed;
    width: 75px;
    right: 24px;
    top: 320px;
    justify-self: center;
  }

  .sc.eleven {
    position: fixed;
    width: 125px;
    right: 24px;
    top: 400px;
    justify-self: center;
  }

  .sc.twelve {
    position: fixed;
    width: 125px;
    right: 24px;
    top: 530px;
    justify-self: center;
  }

  .sc.fourteen {
    position: fixed;
    width: 125px;
    right: 24px;
    top: 660px;
    justify-self: center;
  }

  .circleButton {
    height: 100px;
    width: 100px;
    background-color: #004a22;
    border: none;
    border-radius: 50%;
    white-space: normal;
    color: #e2dfcf;
    transform: scale(1);
  }

  .storySection {
    height: 120vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 24px 48px;
  }

  .storySection div:first-child {
    position: relative;
    height: auto;
    width: 100%;
    text-align: start;
  }

  .storySection div:first-child h2 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    padding: 0 24px 0 0;
    font-size: 2em;
  }

  .exhaustImage {
    position: relative;
    top: 0;
    left: -100px;
    width: 100%;
    opacity: 0.25;
    z-index: 1;
  }

  .storySection div:last-child {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
  }

  .storySection div:last-child span {
    margin-top: 24px;
    color: rgba(0, 74, 35, 1);
  }

  .storySection a {
    font-weight: 700;
    text-decoration: none;
    position: relative;
    color: rgba(0, 74, 35, 1);
  }

  .storySection a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -0.2em; /* Adjust the value to increase or decrease the distance */
    width: 100%;
    height: 0.1em; /* Adjust the value to control the thickness of the underline */
    background-color: #; /* Adjust the color as needed */
  }

  .finalSection {
    background-color: #004a22;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    padding: 24px 24px;
  }

  .finalSection h1 {
    color: #e2dfcf;
    padding: 12px;
    font-size: 24px;
    z-index: 3;
  }

  .finalSection span {
    padding: 12px;
    margin-bottom: 24px;
    color: #e2dfcf;
  }

  .finalSection a {
    padding: 0 0 0 12px;
  }

  .mobile_main {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px 0;
  }

  .button__row,
  .button__row_two {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0 0 0;
  }

  .button__row button:first-child {
    margin-right: 12px;
    text-decoration: none;
  }

  .button__row a:last-child {
    text-decoration: none;
  }

  a .lpButton,
  .lpButton {
    height: auto;
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 12px;
    background-color: #004a22;
    color: #e2dfcf;
    border-radius: 30px;
    cursor: pointer;
    border: none;
    outline: none;
    text-decoration: none;
  }

  a .lpButton {
    color: #e2dfcf;
    text-decoration: none;
  }

  a .lpButton:hover,
  a .lpButton:focus,
  a .lpButton:visited {
    text-decoration: none;
    color: #e2dfcf;
  }

  #lpTutorial {
    grid-column: 5 / auto;
    grid-row: 4 / auto;
  }

  h2 {
    color: #004a22;
  }

  .side {
    display: none;
  }

  .salesblock {
    display: none;
  }

  .newsfeed {
    height: 100vh;
    width: 95%;
    margin-top: 12px;
    overflow: scroll;
    background-color: whitesmoke;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    padding: 16px 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
  }

  .missionLP {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .missionLP section {
    height: 100vh;
    width: 95%;
    background-color: #004a22;
    border-radius: 5px;
    padding: 16px 20px;
    color: #e2dfcf;
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    text-align: start;
    position: relative;
  }

  #mis {
    font-size: 2em;
  }

  .newsitem {
    margin: 4px 0;
    white-space: none;
  }

  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 24px;
  }

  .title span {
    font-size: 10px;
  }

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .headerIcon {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #004a22;
    color: whitesmoke;
    position: relative;
    margin: 0 4px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nameDate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 3px;
  }

  .nameDate h6 {
    margin: 0;
    padding: 4px 0 0 0;
    font-size: 12px;
  }

  .nameDate span {
    margin: 0;
    padding: 0;
    font-size: 10px;
  }

  .quote,
  .insight,
  .blog,
  .video,
  .podcast,
  .picture {
    height: auto;
    width: 100%;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
  }

  .quote span,
  .insight p,
  .blog span,
  .blog a,
  .video span,
  .podcast span {
    margin-top: 4px;
    margin-left: 12px;
    text-decoration: none;
    color: #004a22;
  }

  .dividingLine {
    width: 100%;
    border-top: 3px solid #004a22;
  }

  .dividingLineThin {
    width: 75%;
    border-top: 5px solid rgba(0, 74, 35, 1);
  }

  .community {
    display: none;
  }

  .insightRow {
    max-height: 4rem;
    width: 100%;
    padding: 4px;
    background-color: #004a22;
    border-radius: 5px;
    color: whitesmoke;
    display: flex;
    flex-direction: column;
  }

  .insightRow:hover {
    background-color: whitesmoke;
    color: #004a22;
    border: 1px solid #004a22;
  }

  .tutorialtile {
    height: auto;
    width: 100%;
    border-radius: 5px;
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    background-color: whitesmoke;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
  }

  .rtb3 {
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }

  .rtb4 {
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 35vh 65vh 60vh 35vh;
    row-gap: 12px;
  }

  .rtb h2 {
    color: #004a22;
    font-weight: 700;
  }

  .rtb3 .one {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding: 36px 9px 24px 9px;
  }

  .rtb4 .one {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 24px 12px;
  }

  .rtb4 img {
    border: 2px solid rgba(0, 74, 35, 0.1);
    border-radius: 5px;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.1);
  }

  .rtb3 .two {
    grid-row: 2 / 2;
    grid-column: 1 / 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding: 24px 9px 24px 9px;
  }

  .rtb4 .two {
    grid-row: 2 / 2;
    grid-column: 1 / 1;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 12px;
  }

  .rtb3 .three {
    grid-row: 3 / 3;
    grid-column: 1 / 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding: 24px 9px 24px 9px;
  }

  .rtb4 .three {
    grid-row: 3 / 3;
    grid-column: 1 / 1;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 12px;
  }

  .rtb4 .four {
    grid-row: 4 / 4;
    grid-column: 1 / 1;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 12px;
  }

  .rtb4 h3 {
    margin-bottom: 12px;
  }

  .tutorialIntro {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #004a22;
    border-radius: 5px;
    color: #e2dfcf;
    text-align: center;
    padding: 24px 12px;
  }

  .tutorialIntro p:last-child {
    padding: 12px 6px 0 6px;
  }

  .tutorialIntro h2,
  .tutorialIntro p {
    color: #e2dfcf;
  }

  .tutorialIntro h2 {
    line-height: 1.5em;
  }

  .button__row_two .lpButton {
    background-color: #e2dfcf;
    color: rgba(0, 74, 35, 1);
    text-decoration: none;
  }

  .button__row_two .lpButton:first-child {
    margin-right: 12px;
  }

  .button__row_two a {
    text-decoration: none;
  }

  .pC {
    display: flex;
    flex-direction: row;
  }

  #pcHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
  }

  .pro,
  .con {
    width: 50%;
  }

  .featuredIn {
    height: 75vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .lp {
    font-weight: 500;
  }

  .tile.left .hidden {
    display: none;
  }

  /* Mobile Landing Page */

  .landingPage.mobile {
    height: 100vh;
    width: 100%;
  }

  .landingPage.mobile a {
    color: #004a22;
  }

  /* Animation */

  .main {
    height: auto;
    width: 100%;
    display: block;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
  }

  .part {
    font-size: 28px;
    font-weight: 700;
    height: 3.5rem;
    display: inline-flex;
    align-items: center;
    color: #004a22;
  }

  .part.two {
    font-size: 48px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    text-align: center;
    height: 5rem;
    background-color: #004a22;
  }

  .part.two span {
    height: 6rem;
    color: whitesmoke;
    line-height: 6rem;
    font-weight: 500;
    width: auto;
    position: relative;
    top: -1rem;
    margin: 0px 8px 0px 8px;
    animation: slide 15s ease-in-out infinite;
  }

  @keyframes slide {
    0% {
      top: 0rem;
    }
    15% {
      top: -6.6rem;
    }
    30% {
      top: -12.6rem;
    }
    45% {
      top: -18.6rem;
    }
    60% {
      top: -24.6rem;
    }
    75% {
      top: -30.6rem;
    }
    100% {
      top: 0rem;
    }
  }

  .newsFeedPlayer {
    width: 85vw;
    height: 50vw;
    top: 20%;
    left: 25%;
    z-index: 25;
    border-radius: 5px;
    border: 5px solid #004a22;
    margin-top: 12px;
  }

  .mobileHeader {
    background-color: #004a22;
    padding: 12px 24px 0 24px;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .loggedInMain {
    margin-top: 24px;
    height: 90vh;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(20, 1fr);
  }

  .missionLP {
    grid-column: 3 / 15;
    grid-row: 2 / 20;
  }

  .missionLP section {
    background-color: #004a22;
    border-radius: 5px;
    padding: 16px 20px;
    color: #e2dfcf;
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    text-align: start;
    position: relative;
  }

  #lpTutorial {
    position: fixed;
    top: 2%;
  }

  .button__row a,
  .button__row a:hover {
    text-decoration: none;
  }

  a .lpButton {
    height: auto;
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 12px;
    background-color: #004a22;
    color: #e2dfcf;
    border-radius: 30px;
    cursor: pointer;
    border: none;
    outline: none;
    text-decoration: none;
  }

  a .lpButton {
    color: #e2dfcf;
    text-decoration: none;
  }

  a .lpButton:hover,
  a .lpButton:focus,
  a .lpButton:visited {
    text-decoration: none;
    color: #e2dfcf;
  }

  h2 {
    color: #004a22;
  }

  .side {
    grid-column: 1 / 3;
    grid-row: 2 / 18;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .newsfeed {
    width: 100%;
    overflow: scroll;
    grid-column: 3 / 15;
    grid-row: 2 / 18;
    background-color: whitesmoke;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    padding: 16px 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
  }

  .newsitem {
    margin: 4px 0;
  }

  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 24px;
  }

  .title span {
    font-size: 10px;
  }

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .headerIcon {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #004a22;
    color: whitesmoke;
    position: relative;
    margin: 0 4px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nameDate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 3px;
  }

  .nameDate h6 {
    margin: 0;
    padding: 4px 0 0 0;
    font-size: 12px;
  }

  .nameDate span {
    margin: 0;
    padding: 0;
    font-size: 10px;
  }

  .insight,
  .blog,
  .video,
  .podcast,
  .picture {
    height: auto;
    width: 100%;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
  }

  .quote span,
  .insight p,
  .blog span,
  .blog a,
  .video span,
  .podcast span {
    margin-top: 4px;
    margin-left: 12px;
    text-decoration: none;
    color: #004a22;
  }

  .quote h2 {
    font-style: italic;
  }

  .dividingLine {
    width: 100%;
    border-top: 3px solid #004a22;
  }

  .dividingLineThin {
    width: 75%;
    border-top: 5px solid rgba(0, 74, 35, 1);
  }

  .community {
    grid-column: 15 / 20;
    grid-row: 2 / 10;
    background-color: whitesmoke;
    margin-left: 24px;
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    border-radius: 5px;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
  }

  .insightRow {
    max-height: 4rem;
    width: 100%;
    padding: 4px;
    background-color: #004a22;
    border-radius: 5px;
    color: whitesmoke;
    display: flex;
    flex-direction: column;
  }

  .insightRow:hover {
    background-color: whitesmoke;
    color: #004a22;
    border: 1px solid #004a22;
  }

  .loggedOutMain {
    height: auto;
    width: 100%;
    /* padding: 0 48px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: start;
    position: relative;
  }

  .topSection {
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: start;
  }

  .topSection div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 45%;
  }

  .rtbSection {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    background-color: #004a22;
    height: auto;
    margin: 24px 0;
    width: 100%;
    padding: 72px 48px;
    /* overflow: hidden; */
  }

  .sc {
    width: 200px;
    position: relative;
  }

  .sc.two {
    position: absolute;
    width: 200px;
    left: 24px;
    top: 24px;
  }

  .sc.three {
    position: absolute;
    width: 100px;
    left: 240px;
    top: 240px;
  }

  .sc.four {
    position: absolute;
    width: 400px;
    left: 33%;
    top: 360px;
    justify-self: center;
  }

  .sc.five {
    position: absolute;
    width: 150px;
    left: 48px;
    top: 480px;
    justify-self: center;
  }

  .sc.six {
    position: absolute;
    width: 200px;
    right: 48px;
    top: 120px;
    justify-self: center;
  }

  .sc.seven {
    position: absolute;
    width: 125px;
    right: 160px;
    top: 360px;
    justify-self: center;
  }

  .sc.eight {
    position: absolute;
    width: 125px;
    right: 48px;
    top: 240px;
    justify-self: center;
  }

  .sc.nine {
    position: absolute;
    width: 100px;
    right: 96px;
    top: 120px;
    justify-self: center;
  }

  .sc.ten {
    position: absolute;
    width: 75px;
    right: 192px;
    top: 228px;
    justify-self: center;
  }

  .sc.eleven {
    position: absolute;
    width: 225px;
    left: 400px;
    top: 400px;
    z-index: 1000;
    justify-self: center;
  }

  .sc.twelve {
    position: absolute;
    width: 225px;
    left: 300px;
    top: 750px;
    z-index: 1000;
    justify-self: center;
  }

  .sc.thirteen {
    position: absolute;
    width: 225px;
    right: 300px;
    top: 800px;
    z-index: 1000;
    justify-self: center;
  }

  .sc.fourteen {
    position: absolute;
    width: 225px;
    right: 400px;
    top: 400px;
    z-index: 1000;
    justify-self: center;
  }

  .circleButton {
    height: 100px;
    width: 100px;
    background-color: #004a22;
    border: none;
    border-radius: 50%;
    white-space: normal;
    color: #e2dfcf;
    transform: scale(1);
  }

  .circleButton:hover {
    transform: scale(0.9);
    transition: transform 0.3s ease;
  }

  .rtbLP {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: start;
    width: 65%;
    color: #e2dfcf;
  }

  .rtbLP div {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .rtbLP div span {
    text-align: center;
  }

  .rtbCircle {
    background-color: #004a22;
    max-height: 150px;
    max-width: 150px;
    min-height: 150px;
    min-width: 150px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
  }

  .rtbIcon {
    color: #e2dfcf;
  }

  .rtbLP.one {
    top: 0;
    left: 0;
    position: relative;
  }

  .rtbLP.two {
    top: 0;
    left: 0;
    position: relative;
  }

  .rtbLP.three {
    top: 0;
    right: 0;
    position: relative;
  }

  .storySection {
    height: 400px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 48px 72px;
  }

  .storySection div:first-child {
    position: relative;
    height: auto;
    width: 50%;
    text-align: start;
    padding: 0 24px 0 0;
    /* background: url('../../LOGO_EXHAUST.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center; */
  }

  .storySection div:first-child h2 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    padding: 0 24px 0 0;
    font-size: 3em;
  }

  .exhaustImage {
    position: relative;
    top: 0;
    left: -125px;
    width: 90%;
    opacity: 0.25;
    z-index: 1;
  }

  .storySection div:last-child {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
  }

  .storySection div:last-child span,
  .storySection div:last-child h3 {
    margin-top: 24px;
    color: rgba(0, 74, 35, 1);
  }

  .storySection a {
    font-weight: 700;
    text-decoration: none;
    position: relative;
    color: rgba(0, 74, 35, 1);
  }

  .storySection a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -0.2em; /* Adjust the value to increase or decrease the distance */
    width: 100%;
    height: 0.1em; /* Adjust the value to control the thickness of the underline */
    background-color: #; /* Adjust the color as needed */
  }

  .finalSection {
    width: 60%;
    padding: 48px 72px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: start;
  }

  .finalSection h1 {
    z-index: 3;
  }

  .finalSection span {
    margin-bottom: 24px;
  }

  .salesblock {
    background-color: #004a22;
    color: #f5f5f5;
    padding: 16px 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    margin: 0 1em;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    height: auto;
    min-width: 388px;
    max-width: 412px;
    color: #e2dfcf;
  }

  .salesblock h2 {
    color: #e2dfcf;
  }

  .background-video {
    height: 100vh;
    width: 100%;
    z-index: -5;
    position: fixed;
  }

  .loggedOutMain h1 {
    font-weight: 700;
    color: #004a22;
    font-size: clamp(4em, 6em, 10em);
  }

  .button__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 0 0 0;
  }

  .loggedOutMain div {
    text-align: start;
  }

  .rtb {
    height: 50vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 6px;
  }

  .rtb h1 {
    color: #004a22;
    font-weight: 700;
  }

  .rtb ul {
    list-style-type: none;
  }

  .rtb .number {
    color: #004a22;
    transform: scale(0.8);
  }

  .rtb .one {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
    padding: 0 12px 0 24px;
  }

  .rtb .two {
    grid-row: 1 / 1;
    grid-column: 2 / 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: end;
    padding: 0 12px;
  }

  .rtb .three {
    grid-row: 1 / 1;
    grid-column: 3 / 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: end;
    padding: 0 24px 0 12px;
  }

  /* .rtb .four {
      grid-row: 1 / 1;
      grid-column: 4 / 4;
      display: flex; 
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      text-align: end;
    } */

  .tutorialtile {
    background-color: whitesmoke;
    padding: 16px 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
    height: auto;
    min-width: 25%;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  }

  .mobileHeader,
  .helpText {
    display: none;
  }

  #backgroundAnimation {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -100;
  }

  canvas:not([id]) {
    display: none;
  }

  .mobile_main {
    display: none;
  }
}

@media (min-width: 1201px) {
  .loggedInMain {
    margin-top: 24px;
    height: 90vh;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(20, 1fr);
    grid-template-rows: repeat(20, 1fr);
  }

  .missionLP {
    grid-column: 3 / 15;
    grid-row: 2 / 20;
  }

  .missionLP section {
    background-color: #004a22;
    border-radius: 5px;
    padding: 16px 20px;
    color: #e2dfcf;
    height: auto;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    text-align: start;
    position: relative;
  }

  #lpTutorial {
    position: fixed;
    top: 2%;
  }

  .button__row a,
  .button__row a:hover {
    text-decoration: none;
  }

  a .lpButton {
    height: auto;
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 12px;
    background-color: #004a22;
    color: #e2dfcf;
    border-radius: 30px;
    cursor: pointer;
    border: none;
    outline: none;
    text-decoration: none;
  }

  a .lpButton {
    color: #e2dfcf;
    text-decoration: none;
  }

  a .lpButton:hover,
  a .lpButton:focus,
  a .lpButton:visited {
    text-decoration: none;
    color: #e2dfcf;
  }

  h2 {
    color: #004a22;
  }

  .side {
    grid-column: 1 / 3;
    grid-row: 2 / 18;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .newsfeed {
    width: 100%;
    overflow: scroll;
    grid-column: 3 / 15;
    grid-row: 2 / 18;
    background-color: whitesmoke;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    padding: 16px 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
  }

  .newsitem {
    margin: 4px 0;
  }

  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 24px;
  }

  .title span {
    font-size: 10px;
  }

  .header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .headerIcon {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #004a22;
    color: whitesmoke;
    position: relative;
    margin: 0 4px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nameDate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 3px;
  }

  .nameDate h6 {
    margin: 0;
    padding: 4px 0 0 0;
    font-size: 12px;
  }

  .nameDate span {
    margin: 0;
    padding: 0;
    font-size: 10px;
  }

  .insight,
  .blog,
  .video,
  .podcast,
  .picture {
    height: auto;
    width: 100%;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
  }

  .quote span,
  .insight p,
  .blog span,
  .blog a,
  .video span,
  .podcast span {
    margin-top: 4px;
    margin-left: 12px;
    text-decoration: none;
    color: #004a22;
  }

  .quote h2 {
    font-style: italic;
  }

  .dividingLine {
    width: 100%;
    border-top: 3px solid #004a22;
  }

  .dividingLineThin {
    width: 75%;
    border-top: 5px solid rgba(0, 74, 35, 1);
  }

  .community {
    grid-column: 15 / 20;
    grid-row: 2 / 10;
    background-color: whitesmoke;
    margin-left: 24px;
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    border-radius: 5px;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
  }

  .insightRow {
    max-height: 4rem;
    width: 100%;
    padding: 4px;
    background-color: #004a22;
    border-radius: 5px;
    color: whitesmoke;
    display: flex;
    flex-direction: column;
  }

  .insightRow:hover {
    background-color: whitesmoke;
    color: #004a22;
    border: 1px solid #004a22;
  }

  .loggedOutMain {
    height: auto;
    width: 100%;
    /* padding: 0 48px; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: start;
    position: relative;
  }

  .topSection {
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: start;
  }

  .topSection div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 45%;
  }

  .rtbSection {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    background-color: #004a22;
    height: auto;
    margin: 24px 0;
    width: 100%;
    padding: 72px 48px;
    /* overflow: hidden; */
  }

  .sc {
    width: 200px;
    position: relative;
  }

  .sc.two {
    position: absolute;
    width: 200px;
    left: 24px;
    top: 24px;
  }

  .sc.three {
    position: absolute;
    width: 100px;
    left: 240px;
    top: 240px;
  }

  .sc.four {
    position: absolute;
    width: 400px;
    left: 33%;
    top: 360px;
    justify-self: center;
  }

  .sc.five {
    position: absolute;
    width: 150px;
    left: 48px;
    top: 480px;
    justify-self: center;
  }

  .sc.six {
    position: absolute;
    width: 200px;
    right: 48px;
    top: 120px;
    justify-self: center;
  }

  .sc.seven {
    position: absolute;
    width: 125px;
    right: 160px;
    top: 360px;
    justify-self: center;
  }

  .sc.eight {
    position: absolute;
    width: 125px;
    right: 48px;
    top: 240px;
    justify-self: center;
  }

  .sc.nine {
    position: absolute;
    width: 100px;
    right: 96px;
    top: 120px;
    justify-self: center;
  }

  .sc.ten {
    position: absolute;
    width: 75px;
    right: 192px;
    top: 228px;
    justify-self: center;
  }

  .sc.eleven {
    position: absolute;
    width: 225px;
    left: 400px;
    top: 400px;
    z-index: 1000;
    justify-self: center;
  }

  .sc.twelve {
    position: absolute;
    width: 225px;
    left: 300px;
    top: 750px;
    z-index: 1000;
    justify-self: center;
  }

  .sc.thirteen {
    position: absolute;
    width: 225px;
    right: 300px;
    top: 800px;
    z-index: 1000;
    justify-self: center;
  }

  .sc.fourteen {
    position: absolute;
    width: 225px;
    right: 400px;
    top: 400px;
    z-index: 1000;
    justify-self: center;
  }

  .circleButton {
    height: 100px;
    width: 100px;
    background-color: #004a22;
    border: none;
    border-radius: 50%;
    white-space: normal;
    color: #e2dfcf;
    transform: scale(1);
  }

  .circleButton:hover {
    transform: scale(0.9);
    transition: transform 0.3s ease;
  }

  .rtbLP {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: start;
    width: 65%;
    color: #e2dfcf;
  }

  .rtbLP div {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .rtbLP div span {
    text-align: center;
  }

  .rtbCircle {
    background-color: #004a22;
    max-height: 150px;
    max-width: 150px;
    min-height: 150px;
    min-width: 150px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
  }

  .rtbIcon {
    color: #e2dfcf;
  }

  .rtbLP.one {
    top: 0;
    left: 0;
    position: relative;
  }

  .rtbLP.two {
    top: 0;
    left: 0;
    position: relative;
  }

  .rtbLP.three {
    top: 0;
    right: 0;
    position: relative;
  }

  .storySection {
    height: 400px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 48px 72px;
  }

  .storySection div:first-child {
    position: relative;
    height: auto;
    width: 50%;
    text-align: start;
    padding: 0 24px 0 0;
    /* background: url('../../LOGO_EXHAUST.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center; */
  }

  .storySection div:first-child h2 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    padding: 0 24px 0 0;
    font-size: 3em;
  }

  .exhaustImage {
    position: relative;
    top: 0;
    left: -125px;
    width: 90%;
    opacity: 0.25;
    z-index: 1;
  }

  .storySection div:last-child {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
  }

  .storySection div:last-child span,
  .storySection div:last-child h3 {
    margin-top: 24px;
    color: rgba(0, 74, 35, 1);
  }

  .storySection a {
    font-weight: 700;
    text-decoration: none;
    position: relative;
    color: rgba(0, 74, 35, 1);
  }

  .storySection a::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -0.2em; /* Adjust the value to increase or decrease the distance */
    width: 100%;
    height: 0.1em; /* Adjust the value to control the thickness of the underline */
    background-color: #; /* Adjust the color as needed */
  }

  .finalSection {
    width: 60%;
    padding: 48px 72px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: start;
  }

  .finalSection h1 {
    z-index: 3;
  }

  .finalSection span {
    margin-bottom: 24px;
  }

  .salesblock {
    background-color: #004a22;
    color: #f5f5f5;
    padding: 16px 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    margin: 0 1em;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    height: auto;
    min-width: 388px;
    max-width: 412px;
    color: #e2dfcf;
  }

  .salesblock h2 {
    color: #e2dfcf;
  }

  .background-video {
    height: 100vh;
    width: 100%;
    z-index: -5;
    position: fixed;
  }

  .loggedOutMain h1 {
    font-weight: 700;
    color: #004a22;
    font-size: clamp(4em, 6em, 10em);
  }

  .button__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 0 0 0;
  }

  .loggedOutMain div {
    text-align: start;
  }

  .rtb {
    height: 50vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 6px;
  }

  .rtb h1 {
    color: #004a22;
    font-weight: 700;
  }

  .rtb ul {
    list-style-type: none;
  }

  .rtb .number {
    color: #004a22;
    transform: scale(0.8);
  }

  .rtb .one {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
    padding: 0 12px 0 24px;
  }

  .rtb .two {
    grid-row: 1 / 1;
    grid-column: 2 / 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: end;
    padding: 0 12px;
  }

  .rtb .three {
    grid-row: 1 / 1;
    grid-column: 3 / 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    text-align: end;
    padding: 0 24px 0 12px;
  }

  /* .rtb .four {
      grid-row: 1 / 1;
      grid-column: 4 / 4;
      display: flex; 
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      text-align: end;
    } */

  .tutorialtile {
    background-color: whitesmoke;
    padding: 16px 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
    height: auto;
    min-width: 25%;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  }

  .mobileHeader,
  .helpText {
    display: none;
  }

  #backgroundAnimation {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -100;
  }

  canvas:not([id]) {
    display: none;
  }

  .mobile_main {
    display: none;
  }
}
