.list {
    list-style-type: none;
}

.list.sub {
   margin: 12px 0 12px 12px;
}

.listHeader {
    margin: 12px 0;
}

.list.main {
    padding: 0;
    margin-top: 24px;
}

.listExplainer {
    margin: 0 0 12px 0;
    color:blue;
}