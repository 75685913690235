@media (max-width: 480px) {
  #registerScreen {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 48px 20px;
  }

  .registerContainer {
    height: auto;
    width: 100%;
    border-radius: 5px;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    z-index: 1;
    margin: 12px 0;
  }

  .registerContainer p {
    padding: 18px 18px 0 18px;
    color: #004a22;
  }

  .sticker {
    position: absolute;
    right: 36px;
    transform: translateY(-42px);
  }

  .sticker.profile {
    position: absolute;
    right: 36px;
    transform: translateY(-60px);
  }

  .demographic__row--first {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px 0;
  }

  .demographic__row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px 0;
  }

  .demographic__row:first-child {
    padding: 0;
  }

  .demographic__row--flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
  }

  .demographic__row--flex button:first-child {
    opacity: 0.5;
  }

  .demographic__option {
    background-color: #004a22;
    color: #e2dfcf;
    width: 100%;
    white-space: normal;
  }

  .registerContainer h3,
  .registerContainer span a {
    color: #004a22;
  }

  .registerContainer span {
    margin: 12px 0;
    opacity: 0.5;
    font-size: 0.7em;
  }

  .register__date {
    padding: 10px;
    border-radius: 5px;
    background-color: #004a22;
    color: #e2dfcf;
    border: none;
    outline: none;
  }

  .register.form {
    padding: 6px 18px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .register .formInput,
  .register .formInput:focus,
  .register .formInput:active {
    width: 100%;
    height: auto;
    background-color: #004a22;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    border-radius: 5px;
    color: #e2dfcf;
    padding: 5px;
  }

  .register .formInput::placeholder {
    color: #e2dfcf;
  }

  .registerButton {
    background-color: #004a22;
    color: #e2dfcf;
    border: 1px solid #004a22;
    padding: 3px 6px;
    border-radius: 5px;
    margin: 6px 0;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  #registerScreen {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
  }

  .registerContainer {
    height: auto;
    width: 100%;
    border-radius: 5px;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    z-index: 1;
    margin: 12px 0;
  }

  .registerContainer p {
    padding: 18px 18px 0 18px;
    color: #004a22;
  }

  .sticker {
    height: 96px;
    width: auto;
    position: absolute;
    right: 12.5%;
    transform: translateY(-42px);
  }

  .sticker.profile {
    height: 96px;
    width: auto;
    position: absolute;
    right: 12.5%;
    transform: translateY(-48px);
  }

  .demographic__row--first {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px 0;
  }

  .demographic__row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px 0;
  }

  .demographic__row:first-child {
    padding: 0;
  }

  .demographic__row--flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
  }

  .demographic__row--flex button:first-child {
    opacity: 0.5;
  }

  .demographic__option {
    background-color: #004a22;
    color: #e2dfcf;
    width: 100%;
    white-space: normal;
  }

  .registerContainer h3,
  .registerContainer span a {
    color: #004a22;
  }

  .registerContainer span {
    margin: 12px 0;
    opacity: 0.5;
    font-size: 0.7em;
  }

  .register__date {
    padding: 10px;
    border-radius: 5px;
    background-color: #004a22;
    color: #e2dfcf;
    border: none;
    outline: none;
  }

  /* .register__date::-webkit-calendar-picker-indicator {
    translate: sdf;
  } */

  .register.form {
    padding: 6px 18px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .register .formInput,
  .register .formInput:focus,
  .register .formInput:active {
    width: 100%;
    height: auto;
    background-color: #004a22;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    border-radius: 5px;
    color: #e2dfcf;
    padding: 5px;
  }

  .register .formInput::placeholder {
    color: #e2dfcf;
  }

  .registerButton {
    background-color: #004a22;
    color: #e2dfcf;
    border: 1px solid #004a22;
    padding: 3px 6px;
    border-radius: 5px;
    margin: 6px 0;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  #registerScreen {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
  }

  .registerContainer {
    height: auto;
    width: 100%;
    border-radius: 5px;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    z-index: 1;
    margin: 12px 0;
  }

  .registerContainer p {
    padding: 18px 18px 0 18px;
    color: #004a22;
  }

  .sticker {
    height: 96px;
    width: auto;
    position: absolute;
    right: 12.5%;
    transform: translateY(-42px);
  }

  .sticker.profile {
    height: 96px;
    width: auto;
    position: absolute;
    right: 12.5%;
    transform: translateY(-48px);
  }

  .demographic__row--first {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px 0;
  }

  .demographic__row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px 0;
  }

  .demographic__row:first-child {
    padding: 0;
  }

  .demographic__row--flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
  }

  .demographic__row--flex button:first-child {
    opacity: 0.5;
  }

  .demographic__option {
    background-color: #004a22;
    color: #e2dfcf;
    width: 100%;
    white-space: normal;
  }

  .registerContainer h3,
  .registerContainer span a {
    color: #004a22;
  }

  .registerContainer span {
    margin: 12px 0;
    opacity: 0.5;
    font-size: 0.7em;
  }

  .register__date {
    padding: 10px;
    border-radius: 5px;
    background-color: #004a22;
    color: #e2dfcf;
    border: none;
    outline: none;
  }

  /* .register__date::-webkit-calendar-picker-indicator {
    translate: sdf;
  } */

  .register.form {
    padding: 6px 18px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .register .formInput,
  .register .formInput:focus,
  .register .formInput:active {
    width: 100%;
    height: auto;
    background-color: #004a22;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    border-radius: 5px;
    color: #e2dfcf;
    padding: 5px;
  }

  .register .formInput::placeholder {
    color: #e2dfcf;
  }

  .registerButton {
    background-color: #004a22;
    color: #e2dfcf;
    border: 1px solid #004a22;
    padding: 3px 6px;
    border-radius: 5px;
    margin: 6px 0;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  #registerScreen {
    min-height: 600px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
  }

  .registerContainer {
    height: auto;
    width: 100%;
    border-radius: 5px;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    z-index: 1;
    margin: 12px 0;
  }

  .registerContainer p {
    padding: 18px 18px 0 18px;
    color: #004a22;
  }

  .sticker {
    height: 96px;
    width: auto;
    position: absolute;
    left: 32.5%;
    transform: translateY(-42px);
  }

  .sticker.profile {
    height: 96px;
    width: auto;
    position: absolute;
    left: 32.5%;
    transform: translateY(-48px);
  }

  .demographic__row--first {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px 0;
  }

  .demographic__row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px 0;
  }

  .demographic__row:first-child {
    padding: 0;
  }

  .demographic__row--flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
  }

  .demographic__row--flex button:first-child {
    opacity: 0.5;
  }

  .demographic__option {
    background-color: #004a22;
    color: #e2dfcf;
    width: 100%;
    white-space: normal;
  }

  .registerContainer h3,
  .registerContainer span a {
    color: #004a22;
  }

  .registerContainer span {
    margin: 12px 0;
    opacity: 0.5;
    font-size: 0.7em;
  }

  .register__date {
    padding: 10px;
    border-radius: 5px;
    background-color: #004a22;
    color: #e2dfcf;
    border: none;
    outline: none;
  }

  /* .register__date::-webkit-calendar-picker-indicator {
    translate: sdf;
  } */

  .register.form {
    padding: 6px 18px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .register .formInput,
  .register .formInput:focus,
  .register .formInput:active {
    width: 100%;
    height: auto;
    background-color: #004a22;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    border-radius: 5px;
    color: #e2dfcf;
    padding: 5px;
  }

  .register .formInput::placeholder {
    color: #e2dfcf;
  }

  .registerButton {
    background-color: #004a22;
    color: #e2dfcf;
    border: 1px solid #004a22;
    padding: 3px 6px;
    border-radius: 5px;
    margin: 6px 0;
  }
}

@media (min-width: 1201px) {
  #registerScreen {
    min-height: 600px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
  }

  .registerContainer {
    height: auto;
    width: 100%;
    border-radius: 5px;
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    z-index: 1;
    margin: 12px 0;
  }

  .registerContainer p {
    padding: 18px 18px 0 18px;
    color: #004a22;
  }

  .sticker {
    height: 96px;
    width: auto;
    position: absolute;
    left: 58%;
    transform: translateY(-42px);
  }

  .sticker.profile {
    height: 96px;
    width: auto;
    position: absolute;
    left: 58%;
    transform: translateY(-48px);
  }

  .demographic__row--first {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px 0;
  }

  .demographic__row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 12px 0;
  }

  .demographic__row:first-child {
    padding: 0;
  }

  .demographic__row--flex {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
  }

  .demographic__row--flex button:first-child {
    opacity: 0.5;
  }

  .demographic__option {
    background-color: #004a22;
    color: #e2dfcf;
    width: 100%;
    white-space: normal;
  }

  .registerContainer h3,
  .registerContainer span a {
    color: #004a22;
  }

  .registerContainer span {
    margin: 12px 0;
    opacity: 0.5;
    font-size: 0.7em;
  }

  .register__date {
    padding: 10px;
    border-radius: 5px;
    background-color: #004a22;
    color: #e2dfcf;
    border: none;
    outline: none;
  }

  /* .register__date::-webkit-calendar-picker-indicator {
    translate: sdf;
  } */

  .register.form {
    padding: 6px 18px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .register .formInput,
  .register .formInput:focus,
  .register .formInput:active {
    width: 100%;
    height: auto;
    background-color: #004a22;
    border: none;
    box-shadow: none;
    text-decoration: none;
    outline: none;
    border-radius: 5px;
    color: #e2dfcf;
    padding: 5px;
  }

  .register .formInput::placeholder {
    color: #e2dfcf;
  }

  .registerButton {
    background-color: #004a22;
    color: #e2dfcf;
    border: 1px solid #004a22;
    padding: 3px 6px;
    border-radius: 5px;
    margin: 6px 0;
  }
}
