@media (max-width: 480px) {

}

@media (min-width: 481px) and (max-width: 1024px) {

}

@media (min-width: 1025px) and (max-width: 1200px) {

}

@media (min-width: 1201px) {
  .aboutpage {
    width: 100%; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;
  }

.about-us-page {
  background-color: #004a22;
  width: 100%; 
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  border-radius: 8px ;
  box-shadow: 0 4px 6px rgba(0, 74, 34, 0.1);
}

.about--header {
  text-align: center;
  margin-bottom: 36px;
  margin-top: 24px;
}

.about--header h1 {
  font-size: 3.5rem;
  font-weight: bold;
  line-height: 1.2;
  color: #f5f5f5;
  padding: 0px 72px;
}

.content {
  width: 100%;
  display: flex; 
flex-direction: column;
align-items: center;
max-width: 1200px;
}

.two-columns {
  background-color: #f5f5f5;
  width: 100%;
  max-width: 1200px;
  display: flex; 
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  padding: 24px 120px;
}

.two-columns h3 {
  margin-bottom: 24px;
}

.about--column {
  width: 50%; 
  padding: 12px;
  gap: 6px;
}

.full-width { 
  width: 100%; 
  max-width: 1200px;
  padding: 12px 72px;
  border-radius: 0 0 8px 8px ;
  color: #f5f5f5;
  text-align: center;
}

}