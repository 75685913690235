@media only screen and (min-width: 1051px) and (max-width: 4000px) { 
.jordan {
  height: 100%;
  width: 100%;
}

.jordan.indicate {
  animation: 2s linear .1s infinite alternate nodeAnimation;
}

.jordan.indicate .cls-2 {
  fill: rgba(103, 36, 131,1);
}

  @keyframes nodeAnimation {
    from {
      transform: rotate(0.0125turn);
    }
    to {
      transform: rotate(-0.0125turn);
    }
  }

  .jordan .main {
    fill: rgba(0, 74, 34, 1);
    transition: fill .2s ease-in-out;
  }

  .jordan .accent {
    fill: rgba(226, 223, 207, 1);
    transition: fill .2s ease-in-out;
  }

  .jordan.loading .main {
    fill: rgba(103, 36, 131,1);
    transition: fill .2s ease-in-out;
  }

  .jordan.loading .accent {
    fill: rgba(103, 36, 131,1);
    transition: fill .2s ease-in-out;
  }

  .jordan.success .main {
    fill: rgba(0, 74, 34, 1);
    transition: fill .5s ease-in-out;
  }

  .jordan.success .accent {
    fill: rgba(0, 74, 34, 1);
    transition: fill .5s ease-in-out;
  }



 
  .main.shaded {
    fill: #004a22;
    opacity: 0.1;
  }

  .accent, 
  .main {
    stroke-width: 0px;
  }

  .accent-invert {
    fill: #004a22;
  }

  .main-invert {
    fill: #e2dfcf;
  }

  .gradient-fill-one {
    fill: url(#gradient-one); /* Apply the gradient fill */
  }
  
  /* Define the gradient */
  #gradient-one {
    stop-color: #004a22; /* Your desired color */
    stop-opacity: 1; /* Fully opaque */
  }

  .gradient-fill-one-mob {
    fill: url(#gradient-one-mob); /* Apply the gradient fill */
  }
  
  /* Define the gradient */
  #gradient-one-mob {
    stop-color: #004a22; /* Your desired color */
    stop-opacity: 1; /* Fully opaque */
  }

  .gradient-fill-two {
    fill: url(#gradient-two); /* Apply the gradient fill */
  }
  
  /* Define the gradient */
  #gradient-two {
    stop-color: #004a22; /* Your desired color */
    stop-opacity: 1; /* Fully opaque */
  }

  .gradient-fill-two-mob {
    fill: url(#gradient-two-mob); /* Apply the gradient fill */
  }
  
  /* Define the gradient */
  #gradient-two-mob {
    stop-color: #004a22; /* Your desired color */
    stop-opacity: 1; /* Fully opaque */
  }

  .gradient-fill-three {
    fill: url(#gradient-three); /* Apply the gradient fill */
  }
  
  /* Define the gradient */
  #gradient-three {
    stop-color: #004a22; /* Your desired color */
    stop-opacity: 1; /* Fully opaque */
  }

  .gradient-fill-three-mob {
    fill: url(#gradient-three-mob); /* Apply the gradient fill */
  }
  
  /* Define the gradient */
  #gradient-three-mob {
    stop-color: #004a22; /* Your desired color */
    stop-opacity: 1; /* Fully opaque */
  }

  .gradient-fill-four {
    fill: url(#gradient-four); /* Apply the gradient fill */
  }
  
  /* Define the gradient */
  #gradient-four {
    stop-color: #004a22; /* Your desired color */
    stop-opacity: 1; /* Fully opaque */
  }

  .gradient-fill-four-mob {
    fill: url(#gradient-four-mob); /* Apply the gradient fill */
  }
  
  /* Define the gradient */
  #gradient-four-mob {
    stop-color: #004a22; /* Your desired color */
    stop-opacity: 1; /* Fully opaque */
  }

  .gradient-fill-five {
    fill: url(#gradient-five); /* Apply the gradient fill */
  }
  
  /* Define the gradient */
  #gradient-five {
    stop-color: #004a22; /* Your desired color */
    stop-opacity: 1; /* Fully opaque */
  }

  .gradient-fill-five-mob {
    fill: url(#gradient-five-mob); /* Apply the gradient fill */
  }
  
  /* Define the gradient */
  #gradient-five-mob {
    stop-color: #004a22; /* Your desired color */
    stop-opacity: 1; /* Fully opaque */
  }

  .gradient-fill-six {
    fill: url(#gradient-six); /* Apply the gradient fill */
  }
  
  /* Define the gradient */
  #gradient-six {
    stop-color: #004a22; /* Your desired color */
    stop-opacity: 1; /* Fully opaque */
  }

  .gradient-fill-six-mob {
    fill: url(#gradient-six-mob); /* Apply the gradient fill */
  }
  
  /* Define the gradient */
  #gradient-six-mob {
    stop-color: #004a22; /* Your desired color */
    stop-opacity: 1; /* Fully opaque */
  }

  .gradient-fill-seven {
    fill: url(#gradient-seven); /* Apply the gradient fill */
  }
  
  /* Define the gradient */
  #gradient-seven {
    stop-color: #004a22; /* Your desired color */
    stop-opacity: 1; /* Fully opaque */
  }

  .gradient-fill-seven-mob {
    fill: url(#gradient-seven-mob); /* Apply the gradient fill */
  }
  
  /* Define the gradient */
  #gradient-seven-mob {
    stop-color: #004a22; /* Your desired color */
    stop-opacity: 1; /* Fully opaque */
  }

  .gradient-fill-eight {
    fill: url(#gradient-eight); /* Apply the gradient fill */
  }
  
  /* Define the gradient */
  #gradient-eight {
    stop-color: #004a22; /* Your desired color */
    stop-opacity: 1; /* Fully opaque */
  }

  .gradient-fill-eight-mob {
    fill: url(#gradient-eight-mob); /* Apply the gradient fill */
  }
  
  /* Define the gradient */
  #gradient-eight-mob {
    stop-color: #004a22; /* Your desired color */
    stop-opacity: 1; /* Fully opaque */
  }

  .gradient-fill-nine {
    fill: url(#gradient-nine); /* Apply the gradient fill */
  }
  
  /* Define the gradient */
  #gradient-nine {
    stop-color: #004a22; /* Your desired color */
    stop-opacity: 1; /* Fully opaque */
  }

  .gradient-fill-nine-mob {
    fill: url(#gradient-nine-mob); /* Apply the gradient fill */
  }
  
  /* Define the gradient */
  #gradient-nine-mob {
    stop-color: #004a22; /* Your desired color */
    stop-opacity: 1; /* Fully opaque */
  }

  .gradient-fill-ten {
    fill: url(#gradient-ten); /* Apply the gradient fill */
  }
  
  /* Define the gradient */
  #gradient-ten {
    stop-color: #004a22; /* Your desired color */
    stop-opacity: 1; /* Fully opaque */
  }

  .gradient-fill-ten-mob {
    fill: url(#gradient-ten-mob); /* Apply the gradient fill */
  }
  
  /* Define the gradient */
  #gradient-ten-mob {
    stop-color: #004a22; /* Your desired color */
    stop-opacity: 1; /* Fully opaque */
  }

} 

@media only screen and (min-width: 0px) and (max-width: 1050px) { 

  .jordan {
    height: 100%;
    width: 100%;
  }

  .jordan.indicate {
    animation: 2s linear .1s infinite alternate nodeAnimation;
  }
  
  .jordan.indicate .main {
    fill: rgba(103, 36, 131,1);
  }
  
    @keyframes nodeAnimation {
      from {
        transform: rotate(0.0125turn);
      }
      to {
        transform: rotate(-0.0125turn);
      }
    }
  
  .accent {
      fill: #e2dfcf;
    }
  
    .main {
      fill: #004a22;
    }
  
    .main.shaded {
      fill: #004a22;
      opacity: 0.1;
    }
  
    .main, 
    .accent {
      stroke-width: 0px;
    }
  
    .accent-invert {
      fill: #004a22;
    }
  
    .main-invert {
      fill: #e2dfcf;
    }
  
    .gradient-fill-one {
      fill: url(#gradient-one); /* Apply the gradient fill */
    }
    
    /* Define the gradient */
    #gradient-one {
      stop-color: #004a22; /* Your desired color */
      stop-opacity: 1; /* Fully opaque */
    }
  
    .gradient-fill-one-mob {
      fill: url(#gradient-one-mob); /* Apply the gradient fill */
    }
    
    /* Define the gradient */
    #gradient-one-mob {
      stop-color: #004a22; /* Your desired color */
      stop-opacity: 1; /* Fully opaque */
    }

    .gradient-fill-two {
      fill: url(#gradient-two); /* Apply the gradient fill */
    }
    
    /* Define the gradient */
    #gradient-two {
      stop-color: #004a22; /* Your desired color */
      stop-opacity: 1; /* Fully opaque */
    }
  
    .gradient-fill-two-mob {
      fill: url(#gradient-two-mob); /* Apply the gradient fill */
    }
    
    /* Define the gradient */
    #gradient-two-mob {
      stop-color: #004a22; /* Your desired color */
      stop-opacity: 1; /* Fully opaque */
    }

    .gradient-fill-three {
      fill: url(#gradient-three); /* Apply the gradient fill */
    }
    
    /* Define the gradient */
    #gradient-three {
      stop-color: #004a22; /* Your desired color */
      stop-opacity: 1; /* Fully opaque */
    }
  
    .gradient-fill-three-mob {
      fill: url(#gradient-three-mob); /* Apply the gradient fill */
    }
    
    /* Define the gradient */
    #gradient-three-mob {
      stop-color: #004a22; /* Your desired color */
      stop-opacity: 1; /* Fully opaque */
    }

    .gradient-fill-four {
      fill: url(#gradient-four); /* Apply the gradient fill */
    }
    
    /* Define the gradient */
    #gradient-four {
      stop-color: #004a22; /* Your desired color */
      stop-opacity: 1; /* Fully opaque */
    }
  
    .gradient-fill-four-mob {
      fill: url(#gradient-four-mob); /* Apply the gradient fill */
    }
    
    /* Define the gradient */
    #gradient-four-mob {
      stop-color: #004a22; /* Your desired color */
      stop-opacity: 1; /* Fully opaque */
    }

    .gradient-fill-five {
      fill: url(#gradient-five); /* Apply the gradient fill */
    }
    
    /* Define the gradient */
    #gradient-five {
      stop-color: #004a22; /* Your desired color */
      stop-opacity: 1; /* Fully opaque */
    }
  
    .gradient-fill-five-mob {
      fill: url(#gradient-five-mob); /* Apply the gradient fill */
    }
    
    /* Define the gradient */
    #gradient-five-mob {
      stop-color: #004a22; /* Your desired color */
      stop-opacity: 1; /* Fully opaque */
    }

    .gradient-fill-six {
      fill: url(#gradient-six); /* Apply the gradient fill */
    }
    
    /* Define the gradient */
    #gradient-six {
      stop-color: #004a22; /* Your desired color */
      stop-opacity: 1; /* Fully opaque */
    }
  
    .gradient-fill-six-mob {
      fill: url(#gradient-six-mob); /* Apply the gradient fill */
    }
    
    /* Define the gradient */
    #gradient-six-mob {
      stop-color: #004a22; /* Your desired color */
      stop-opacity: 1; /* Fully opaque */
    }

    .gradient-fill-seven {
      fill: url(#gradient-seven); /* Apply the gradient fill */
    }
    
    /* Define the gradient */
    #gradient-seven {
      stop-color: #004a22; /* Your desired color */
      stop-opacity: 1; /* Fully opaque */
    }
  
    .gradient-fill-seven-mob {
      fill: url(#gradient-seven-mob); /* Apply the gradient fill */
    }
    
    /* Define the gradient */
    #gradient-seven-mob {
      stop-color: #004a22; /* Your desired color */
      stop-opacity: 1; /* Fully opaque */
    }

    .gradient-fill-eight {
      fill: url(#gradient-eight); /* Apply the gradient fill */
    }
    
    /* Define the gradient */
    #gradient-eight {
      stop-color: #004a22; /* Your desired color */
      stop-opacity: 1; /* Fully opaque */
    }
  
    .gradient-fill-eight-mob {
      fill: url(#gradient-eight-mob); /* Apply the gradient fill */
    }
    
    /* Define the gradient */
    #gradient-eight-mob {
      stop-color: #004a22; /* Your desired color */
      stop-opacity: 1; /* Fully opaque */
    }

    .gradient-fill-nine {
      fill: url(#gradient-nine); /* Apply the gradient fill */
    }
    
    /* Define the gradient */
    #gradient-nine {
      stop-color: #004a22; /* Your desired color */
      stop-opacity: 1; /* Fully opaque */
    }
  
    .gradient-fill-nine-mob {
      fill: url(#gradient-nine-mob); /* Apply the gradient fill */
    }
    
    /* Define the gradient */
    #gradient-nine-mob {
      stop-color: #004a22; /* Your desired color */
      stop-opacity: 1; /* Fully opaque */
    }
  
    .gradient-fill-ten {
      fill: url(#gradient-ten); /* Apply the gradient fill */
    }
    
    /* Define the gradient */
    #gradient-ten {
      stop-color: #004a22; /* Your desired color */
      stop-opacity: 1; /* Fully opaque */
    }
  
    .gradient-fill-ten-mob {
      fill: url(#gradient-ten-mob); /* Apply the gradient fill */
    }
    
    /* Define the gradient */
    #gradient-ten-mob {
      stop-color: #004a22; /* Your desired color */
      stop-opacity: 1; /* Fully opaque */
    }

    .jordan.success .main {
      fill: rgba(0, 74, 34, 1);
      transition: fill .5s ease-in-out;
    }
  
    .jordan.success .accent {
      fill: rgba(0, 74, 34, 1);
      transition: fill .5s ease-in-out;
    }
  }