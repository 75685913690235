@media (max-width: 480px) {
  .test-footer {
    height: auto;
    width: 100%;
    background-color: #004a22;
    color: #e2dfcf;
    padding: 20px 0;
  }

  .test-footer h4 {
    font-weight: 600;
    text-transform: uppercase;
    font-size:x-large;
    margin-bottom: 20px;
  }

  .test-footer p {
    margin-bottom: 10px;
    font-size: large;
  }

  .test-footer a {
    color: #e2dfcf;
    text-decoration: none;
    font-size: large;
  }

  .test-footer a:hover {
    text-decoration: underline;
  }

  .test-footer ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .test-footer ul li {
    margin-bottom: 10px;
  }

  .test-footer ul li a {
    display: block;
    padding: 5px 0;
  }
  .footer-main {
    height: 25vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border: 0.5px solid #004a22;
    background-color: #004a22;
    padding: 24px 12px 0 12px;
  }

  .footer-main div:first-child {
    margin-right: 72px;
  }

  .footerColumn {
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
  }

  .footer-header {
    font-weight: 700;
    color: whitesmoke;
    width: auto;
  }

  .copyright {
    display: block;
    background-color: transparent;
    color: whitesmoke;
  }

  .copyright p {
    color: whitesmoke;
  }
  .copyright.logoHeader {
    filter: brightness(50%);
  }

  #fava {
    color: whitesmoke;
    opacity: 1;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    width: auto;
    padding: 0;
    font-weight: 200;
  }

  #fava a {
    position: relative;
    transition: all 1s linear;
  }

  #funderline {
    width: 100%;
    height: 1.5px;
    margin-top: 15px;
    align-self: flex-end;
    left: -220px;
    background-color: whitesmoke;
    position: absolute;
    transition: all 1s linear;
    bottom: 0;
  }

  #fava:hover #funderline {
    left: 0px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .test-footer {
    height: auto;
    background-color: #004a22;
    color: #e2dfcf;
    padding: 20px 0;
  }

  .test-footer h4 {
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .test-footer p {
    margin-bottom: 10px;
  }

  .test-footer a {
    color: #e2dfcf;
    text-decoration: none;
  }

  .test-footer a:hover {
    text-decoration: underline;
  }

  .test-footer ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .test-footer ul li {
    margin-bottom: 10px;
  }

  .test-footer ul li a {
    display: block;
    padding: 5px 0;
  }

  .footer-main {
    height: 25vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border: 0.5px solid #004a22;
    background-color: #004a22;
    padding: 24px 12px 0 12px;
  }

  .footer-main div:first-child {
    margin-right: 72px;
  }

  .footerColumn {
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
  }

  .footer-header {
    font-weight: 700;
    color: whitesmoke;
    width: auto;
  }

  .copyright {
    display: block;
    background-color: transparent;
    color: whitesmoke;
  }

  .copyright p {
    color: whitesmoke;
  }
  .copyright.logoHeader {
    filter: brightness(50%);
  }

  #fava {
    color: whitesmoke;
    opacity: 1;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    width: auto;
    padding: 0;
    font-weight: 200;
  }

  #fava a {
    position: relative;
    transition: all 1s linear;
  }

  #funderline {
    width: 100%;
    height: 1.5px;
    margin-top: 15px;
    align-self: flex-end;
    left: -220px;
    background-color: whitesmoke;
    position: absolute;
    transition: all 1s linear;
    bottom: 0;
  }

  #fava:hover #funderline {
    left: 0px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .test-footer {
    background-color: #004a22;
    color: #f5f5f5;
    padding: 20px 0;
    z-index: 1000;
  }

  .test-footer h4 {
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 20px;
    color: #e2dfcf;
  }

  .test-footer p {
    margin-bottom: 10px;
  }

  .test-footer a {
    color: #e2dfcf;
    text-decoration: none;
  }

  .test-footer a:hover {
    text-decoration: underline;
  }

  .test-footer ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .test-footer ul li {
    margin-bottom: 10px;
  }

  .test-footer ul li a {
    display: block;
    padding: 5px 0;
  }

  /* Footer */
  .footer-main {
    height: 25vh;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: transparent;
    border: 0.5px solid #004a22;
    background-color: #004a22;
    padding: 24px 12px 0 12px;
  }

  .test-footer h4,
  .test-footer li,
  .test-footer a {
    color: #e2dfcf;
  }

  .footerColumn {
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
  }

  .footer-header {
    font-weight: 700;
    color: whitesmoke;
    width: auto;
  }

  .copyright {
    display: block;
    background-color: transparent;
    color: whitesmoke;
  }

  .copyright p {
    color: whitesmoke;
  }
  .copyright.logoHeader {
    filter: brightness(50%);
  }

  #fava {
    color: whitesmoke;
    opacity: 1;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    width: auto;
    padding: 0;
    font-weight: 200;
  }

  #fava a {
    position: relative;
    transition: all 1s linear;
  }

  #funderline {
    width: 100%;
    height: 1.5px;
    margin-top: 15px;
    align-self: flex-end;
    left: -220px;
    background-color: whitesmoke;
    position: absolute;
    transition: all 1s linear;
    bottom: 0;
  }

  #fava:hover #funderline {
    left: 0px;
  }

  .footer-main {
    height: 25vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border: 0.5px solid #004a22;
    background-color: #004a22;
    padding: 24px 12px 0 12px;
  }

  .footer-main div:first-child {
    margin-right: 72px;
  }

  .footerColumn {
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
  }

  .footer-header {
    font-weight: 700;
    color: whitesmoke;
    width: auto;
  }

  .copyright {
    display: block;
    background-color: transparent;
    color: whitesmoke;
  }

  .copyright p {
    color: whitesmoke;
  }
  .copyright.logoHeader {
    filter: brightness(50%);
  }

  #fava {
    color: whitesmoke;
    opacity: 1;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    width: auto;
    padding: 0;
    font-weight: 200;
  }

  #fava a {
    position: relative;
    transition: all 1s linear;
  }

  #funderline {
    width: 100%;
    height: 1.5px;
    margin-top: 15px;
    align-self: flex-end;
    left: -220px;
    background-color: whitesmoke;
    position: absolute;
    transition: all 1s linear;
    bottom: 0;
  }

  #fava:hover #funderline {
    left: 0px;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .test-footer {
    background-color: #004a22;
    color: #f5f5f5;
    padding: 20px 0;
    z-index: 1000;
  }

  .test-footer h4 {
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 20px;
    color: #e2dfcf;
  }

  .test-footer p {
    margin-bottom: 10px;
  }

  .test-footer a {
    color: #e2dfcf;
    text-decoration: none;
  }

  .test-footer a:hover {
    text-decoration: underline;
  }

  .test-footer ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .test-footer ul li {
    margin-bottom: 10px;
  }

  .test-footer ul li a {
    display: block;
    padding: 5px 0;
  }

  /* Footer */
  .footer-main {
    height: 25vh;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: transparent;
    border: 0.5px solid #004a22;
    background-color: #004a22;
    padding: 24px 12px 0 12px;
  }

  .test-footer h4,
  .test-footer li,
  .test-footer a {
    color: #e2dfcf;
  }

  .footerColumn {
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
  }

  .footer-header {
    font-weight: 700;
    color: whitesmoke;
    width: auto;
  }

  .copyright {
    display: block;
    background-color: transparent;
    color: whitesmoke;
  }

  .copyright p {
    color: whitesmoke;
  }
  .copyright.logoHeader {
    filter: brightness(50%);
  }

  #fava {
    color: whitesmoke;
    opacity: 1;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    width: auto;
    padding: 0;
    font-weight: 200;
  }

  #fava a {
    position: relative;
    transition: all 1s linear;
  }

  #funderline {
    width: 100%;
    height: 1.5px;
    margin-top: 15px;
    align-self: flex-end;
    left: -220px;
    background-color: whitesmoke;
    position: absolute;
    transition: all 1s linear;
    bottom: 0;
  }

  #fava:hover #funderline {
    left: 0px;
  }
}

@media (min-width: 1201px) {
  .test-footer {
    background-color: #004a22;
    color: #f5f5f5;
    padding: 20px 0;
    z-index: 1000;
    height: 100%;
  }

  .test-footer h4 {
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 20px;
    color: #e2dfcf;
  }

  .test-footer p {
    margin-bottom: 10px;
  }

  .test-footer a {
    color: #e2dfcf;
    text-decoration: none;
  }

  .test-footer a:hover {
    text-decoration: underline;
  }

  .test-footer ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .test-footer ul li {
    margin-bottom: 10px;
  }

  .test-footer ul li a {
    display: block;
    padding: 5px 0;
  }

  /* Footer */
  .footer-main {
    height: 25vh;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: transparent;
    border: 0.5px solid #004a22;
    background-color: #004a22;
    padding: 24px 12px 0 12px;
  }

  .test-footer h4,
  .test-footer li,
  .test-footer a {
    color: #e2dfcf;
  }

  .footerColumn {
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
  }

  .footer-header {
    font-weight: 700;
    color: whitesmoke;
    width: auto;
  }

  .copyright {
    display: block;
    background-color: transparent;
    color: whitesmoke;
  }

  .copyright p {
    color: whitesmoke;
  }
  .copyright.logoHeader {
    filter: brightness(50%);
  }

  #fava {
    color: whitesmoke;
    opacity: 1;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    width: auto;
    padding: 0;
    font-weight: 200;
  }

  #fava a {
    position: relative;
    transition: all 1s linear;
  }

  #funderline {
    width: 100%;
    height: 1.5px;
    margin-top: 15px;
    align-self: flex-end;
    left: -220px;
    background-color: whitesmoke;
    position: absolute;
    transition: all 1s linear;
    bottom: 0;
  }

  #fava:hover #funderline {
    left: 0px;
  }

  .wave--top--footer {
    z-index: 1000;
  }
}
