@media only screen and (max-width: 480px) and (orientation: portrait) {
  .story__frame {
    height: 425px;
    width: 100%;
    background: repeat center/100%
      url("https://cdn.sanity.io/images/ou6d2gec/production/39aacdb6823278f0a0dab1e80d6903a11b395b30-5000x3250.png");
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 48px 12px 12px 12px;
    color: #e2dfcf;
    border-radius: 5px;
  }

  .headline {
    background-color: #004a22;
    padding: 6px 8px;
    border-radius: 5px;
  }

  .subline {
    background-color: #004a22;
    padding: 3px 4px;
    border-radius: 5px;
  }

  .story__frame.story__frame_live {
    height: 100%;
    width: 100%;
    background: #004a22;
    background-color: #004a22;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    padding: 12px;
    border-radius: 5px;
    overflow-y: scroll;
  }

  .story__frame--button,
  .story__frame--button:focus,
  .story__frame--button:visited {
    width: fit-content;
    border: 2px solid #004a22;
    background-color: #e2dfcf;
    color: #004a22;
    padding: 6px 8px;
    border-radius: 5px;
    border: none;
    outline: none;
  }

  .story__frame--button:hover {
    cursor: pointer;
  }

  .story__frame.story__frame_live div:nth-child(2) {
    margin: 24px 0;
  }

  .story__frame.story__frame_live div:nth-child(3) {
    margin-bottom: 24px;
  }

  .story__frame_live .button__div {
    height: 12px;
    background-color: blue;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .story__frame__cover {
    background-color: #004a22;
    color: #e2dfcf;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .story__frame--button,
  .story__frame--button:focus,
  .story__frame--button:visited {
    background-color: #e2dfcf;
    color: #004a22;
    padding: 6px 8px;
    border-radius: 5px;
    border: none;
    outline: none;
  }

  .story__frame--button:hover {
    cursor: pointer;
  }

  .visible {
    display: block;
  }

  .hidden {
    display: none;
  }
}

@media (min-width: 481px) and (max-width: 767px) and (orientation: portrait) {
  .story__frame {
    height: auto;
    width: 60%;
    background: repeat center/100%
      url("https://cdn.sanity.io/images/ou6d2gec/production/39aacdb6823278f0a0dab1e80d6903a11b395b30-5000x3250.png");
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 48px 12px 12px 12px;
    color: #e2dfcf;
    border-radius: 5px;
  }

  .headline {
    background-color: #004a22;
    padding: 6px 8px;
    border-radius: 5px;
  }

  .subline {
    background-color: #004a22;
    padding: 3px 4px;
    border-radius: 5px;
  }

  .story__frame.story__frame_live {
    height: 100%;
    width: 60%;
    background: #004a22;
    background-color: #004a22;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    padding: 12px;
    border-radius: 5px;
    overflow-y: scroll;
  }

  .story__frame--button,
  .story__frame--button:focus,
  .story__frame--button:visited {
    width: fit-content;
    border: 2px solid #004a22;
    background-color: #e2dfcf;
    color: #004a22;
    padding: 6px 8px;
    border-radius: 5px;
    border: none;
    outline: none;
  }

  .story__frame--button:hover {
    cursor: pointer;
  }

  .story__frame.story__frame_live div:nth-child(2) {
    margin: 24px 0;
  }

  .story__frame.story__frame_live div:nth-child(3) {
    margin-bottom: 24px;
  }

  .story__frame_live .button__div {
    height: 12px;
    background-color: blue;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .story__frame__cover {
    background-color: #004a22;
    color: #e2dfcf;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .story__frame--button,
  .story__frame--button:focus,
  .story__frame--button:visited {
    background-color: #e2dfcf;
    color: #004a22;
    padding: 6px 8px;
    border-radius: 5px;
    border: none;
    outline: none;
  }

  .story__frame--button:hover {
    cursor: pointer;
  }

  .visible {
    display: block;
  }

  .hidden {
    display: none;
  }
}

@media (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {
  .story__frame {
    display: none;
  }

  .story__frame.story__frame_live {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .story__frame {
    height: 425px;
    width: 60%;
    background: repeat center/100%
      url("https://cdn.sanity.io/images/ou6d2gec/production/39aacdb6823278f0a0dab1e80d6903a11b395b30-5000x3250.png");
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 48px 12px 12px 12px;
    color: #e2dfcf;
    border-radius: 5px;
  }

  .headline {
    background-color: #004a22;
    padding: 6px 8px;
    border-radius: 5px;
  }

  .subline {
    background-color: #004a22;
    padding: 3px 4px;
    border-radius: 5px;
  }

  .story__frame.story__frame_live {
    height: 100%;
    width: 60%;
    background: #004a22;
    background-color: #004a22;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    padding: 12px;
    border-radius: 5px;
    overflow-y: scroll;
  }

  .story__frame--button,
  .story__frame--button:focus,
  .story__frame--button:visited {
    width: fit-content;
    border: 2px solid #004a22;
    background-color: #e2dfcf;
    color: #004a22;
    padding: 6px 8px;
    border-radius: 5px;
    border: none;
    outline: none;
  }

  .story__frame--button:hover {
    cursor: pointer;
  }

  .story__frame.story__frame_live div:nth-child(2) {
    margin: 24px 0;
  }

  .story__frame.story__frame_live div:nth-child(3) {
    margin-bottom: 24px;
  }

  .story__frame_live .button__div {
    height: 12px;
    background-color: blue;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .story__frame__cover {
    background-color: #004a22;
    color: #e2dfcf;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .story__frame--button,
  .story__frame--button:focus,
  .story__frame--button:visited {
    background-color: #e2dfcf;
    color: #004a22;
    padding: 6px 8px;
    border-radius: 5px;
    border: none;
    outline: none;
  }

  .story__frame--button:hover {
    cursor: pointer;
  }

  .visible {
    display: block;
  }

  .hidden {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .story__frame {
    display: none;
  }

  .story__frame.story__frame_live {
    display: none;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) and (orientation: portrait) {
  .story__frame {
    height: 100%;
    width: 100%;
    background: repeat center/100%
      url("https://cdn.sanity.io/images/ou6d2gec/production/39aacdb6823278f0a0dab1e80d6903a11b395b30-5000x3250.png");
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 12px;
    color: #e2dfcf;
    border-radius: 5px;
    overflow-y: scroll;
  }

  .headline {
    background-color: #004a22;
    padding: 6px 8px;
    border-radius: 5px;
  }

  .subline {
    background-color: #004a22;
    padding: 3px 4px;
    border-radius: 5px;
  }

  .story__frame.story__frame_live {
    height: 100%;
    width: 100%;
    background: #004a22;
    background-color: #004a22;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: start;
    padding: 12px;
    border-radius: 5px;
  }

  .story__frame_live .button__div {
    height: 12px;
    background-color: blue;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .story__frame__cover {
    background-color: #004a22;
    color: #e2dfcf;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .story__frame--button,
  .story__frame--button:focus,
  .story__frame--button:visited {
    background-color: #e2dfcf;
    color: #004a22;
    padding: 6px 8px;
    border-radius: 5px;
    border: none;
    outline: none;
  }

  .story__frame--button:hover {
    cursor: pointer;
  }

  .visible {
    display: block;
  }

  .hidden {
    display: none;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) and (orientation: landscape) {
  .story__frame {
    display: none;
  }

  .story__frame.story__frame_live {
    display: none;
  }
}

@media only screen and (min-width: 1201px) {
  .story__frame {
    height: 100%;
    width: 100%;
    background: repeat center/100%
      url("https://cdn.sanity.io/images/ou6d2gec/production/39aacdb6823278f0a0dab1e80d6903a11b395b30-5000x3250.png");
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 12px;
    color: #e2dfcf;
    border-radius: 5px;
    overflow-y: scroll;
  }

  .headline {
    background-color: #004a22;
    padding: 6px 8px;
    border-radius: 5px;
  }

  .subline {
    background-color: #004a22;
    padding: 3px 4px;
    border-radius: 5px;
  }

  .story__frame.story__frame_live {
    height: 100%;
    width: 100%;
    background: #004a22;
    background-color: #004a22;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: start;
    padding: 12px;
    border-radius: 5px;
  }

  .story__frame_live .button__div {
    height: 12px;
    background-color: blue;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .story__frame__cover {
    background-color: #004a22;
    color: #e2dfcf;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .story__frame--button,
  .story__frame--button:focus,
  .story__frame--button:visited {
    background-color: #e2dfcf;
    color: #004a22;
    padding: 6px 8px;
    border-radius: 5px;
    border: none;
    outline: none;
  }

  .story__frame--button:hover {
    cursor: pointer;
  }

  .visible {
    display: block;
  }

  .hidden {
    display: none;
  }
}
