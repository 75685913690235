.textInput {
  width: 100%;
}

.feedbackButton {
  background-color: whitesmoke;
  color: #004a22;
  border: 1px solid #004a22;
  margin-bottom: 12px;
  width: 200px;
}

.returnButton {
  background-color: #004a22;
  color: whitesmoke;
  border: 1px solid #004a22;
  margin-bottom: 12px;
  width: 200px;
}

.rowHeader {
    color: whitesmoke;
}

.feedbackContainer label {
  color: whitesmoke;
}

.feedbackLabel {
  color: whitesmoke;
  font-family: "Montserrat", sans-serif;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 20px;
  border-radius: 2px;
  background: #004a22;
  outline: 1px dashed whitesmoke;
  -webkit-transition: .2s;
  transition: opacity .2s;
  margin-top: 6px;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: whitesmoke;
  outline: 1px dashed #004a22;
  cursor: pointer;
  border-radius: 2px;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #004a22;
  cursor: pointer;
}