@media (max-width: 480px) {

  .feelingScoreToday,
.feelingScoreChart {
  border-radius: 5px;
  margin: 12px 0;
}

.sideBySide {
  display: flex;
}

.toggleButtonDiv {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.toggleButtonDiv img {
  margin-bottom: 12px;
}

.toggleSpread {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#scoreHere {
  margin-right: 24px;
}

.toggleButton {
  background-color:#004a22;
  color: whitesmoke;
  border: 1px solid #004a22;
  margin: 0 6px 0 6px;
}


#feelingVariance {
  display: inline-block;
}

.feelingVarianceChart {
  border: 3px solid #004a22;
  border-radius: 10px;
}

#waveContent {
  display: inline-block;
}

.waveContentChart {
  border: 3px solid #004a22;
  border-radius: 10px;
}

.aside {
  font-size: 0.75em;
}
}

@media (min-width: 481px) and (max-width: 767px) {
  .feelingScoreToday,
.feelingScoreChart {
  border-radius: 5px;
  margin: 12px 0;
}

.sideBySide {
  display: flex;
}

.toggleButtonDiv {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.toggleButtonDiv img {
  margin-bottom: 12px;
}

.toggleSpread {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#scoreHere {
  margin-right: 24px;
}

.toggleButton {
  background-color:#004a22;
  color: whitesmoke;
  border: 1px solid #004a22;
  margin: 0 6px 0 6px;
}


#feelingVariance {
  display: inline-block;
}

.feelingVarianceChart {
  border: 3px solid #004a22;
  border-radius: 10px;
}

#waveContent {
  display: inline-block;
}

.waveContentChart {
  border: 3px solid #004a22;
  border-radius: 10px;
}

.aside {
  font-size: 0.75em;
}

}

@media (min-width: 768px) and (max-width: 1024px) {
  .feelingScoreToday,
.feelingScoreChart {
  border-radius: 5px;
  margin: 12px 0;
}

.sideBySide {
  display: flex;
}

.toggleButtonDiv {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.toggleButtonDiv img {
  margin-bottom: 12px;
}

.toggleSpread {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#scoreHere {
  margin-right: 24px;
}

.toggleButton {
  background-color:#004a22;
  color: whitesmoke;
  border: 1px solid #004a22;
  margin: 0 6px 0 6px;
}


#feelingVariance {
  display: inline-block;
}

.feelingVarianceChart {
  border: 3px solid #004a22;
  border-radius: 10px;
}

#waveContent {
  display: inline-block;
}

.waveContentChart {
  border: 3px solid #004a22;
  border-radius: 10px;
}

.aside {
  font-size: 0.75em;
}

}

@media (min-width: 1025px) and (max-width: 1200px) {
  .feelingScoreToday,
  .feelingScoreChart {
    border-radius: 5px;
  }
  
  .sideBySide {
    display: flex;
  }
  
  .toggleButtonDiv {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  
  .toggleButtonDiv img {
    margin-bottom: 12px;
  }
  
  .toggleSpread {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #scoreHere {
    margin-right: 24px;
  }
  
  .toggleButton {
    background-color:#004a22;
    color: whitesmoke;
    border: 1px solid #004a22;
    margin: 0 6px 0 6px;
    padding: 4px 6px; 
    border-radius: 5px; 
  }
  
  
  #feelingVariance {
    display: inline-block;
  }
  
  .feelingVarianceChart {
    border: 3px solid #004a22;
    border-radius: 10px;
  }
  
  #waveContent {
    display: inline-block;
  }
  
  .waveContentChart {
    border: 3px solid #004a22;
    border-radius: 10px;
  }
  
  .aside {
    font-size: 0.75em;
  }
}

@media (min-width: 1201px) {
  .feelingScoreToday,
  .feelingScoreChart {
    border-radius: 5px;
  }
  
  .sideBySide {
    display: flex;
  }
  
  .toggleButtonDiv {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  
  .toggleButtonDiv img {
    margin-bottom: 12px;
  }
  
  .toggleSpread {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #scoreHere {
    margin-right: 24px;
  }
  
  .toggleButton {
    background-color:#004a22;
    color: whitesmoke;
    border: 1px solid #004a22;
    margin: 0 6px 0 6px;
    padding: 4px 6px; 
    border-radius: 5px; 
  }
  
  
  #feelingVariance {
    display: inline-block;
  }
  
  .feelingVarianceChart {
    border: 3px solid #004a22;
    border-radius: 10px;
  }
  
  #waveContent {
    display: inline-block;
  }
  
  .waveContentChart {
    border: 3px solid #004a22;
    border-radius: 10px;
  }
  
  .aside {
    font-size: 0.75em;
  }
}