@media (max-width: 480px) {
}

@media (min-width: 481px) and (max-width: 767px) {
}

@media (min-width: 768px) and (max-width: 1024px) {
}

@media (min-width: 1025px) and (max-width: 1200px) {
}

@media (min-width: 1201px) {
  .gallery__main {
    padding: 20px;
  }

  .gallery__gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 20px;
  }

  .gallery__block {
    position: relative;
    overflow: hidden;
  }

  .gallery__image {
    width: 100%;
    height: auto;
    display: block;
  }

  .gallery__button {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #004a22;
    color: #e2dfcf;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 5px;
  }
}
