.mission {
  height: 75vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: start;
  position: relative;
}

.mission label {
  padding-bottom: 6px;
}

.mission section {
  background-color: #004a22;
  border-radius: 5px;
  padding: 16px 20px;
  color: #e2dfcf;
  height: auto;
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  text-align: start;
  position: relative;
}
