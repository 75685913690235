@media (max-width: 480px) {

/* Full Dock Mobile  */
 .mobiledock--container {
  background-color: #e2dfcf;
  width: 100%;
  display: inline flex;
  justify-content: center;
  align-items: center;
  overflow-x: scroll;
  padding: 6px 0;
 }

 .mobiledock.node {
  height: 30px;
  width: 30px;
  margin: 0 4px;
 }

 .mobiledockwide.node {
  height: 30px;
  width: 40px;
 }

 /* Hide Dock Mobile  */
 .hidedock--container {
  height: 20vh;
  width: 20%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: 0%;
  right: 0%;
  margin-bottom: 12px;
  z-index: 1000;
}

.hidedock.node,
.hidedock.node_save {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: relative;
  margin: 4px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidedockwide.node,
.hidedockwide.node_save {
  height: 40px;
  width: 50px;
  border-radius: 50%;
  position: relative;
  margin: 4px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
}

.hidedock.separator {
  height: 40px;
  width: 15px;
  border-radius: 50%;
  background-color: transparent;
  position: relative;
  margin: 0 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(90deg);
}

.icon {
  color: #e2dfcf;
  transform: scale(1.2);
}

.separator {
  color: #004a22;
  transform: scale(0.9);
}

.hidedock.node.save:active,
.hidedock.node.save:focus,
.hidedock.node.save:visited {
  background-color: #004a22;
  border: 3px solid whitesmoke;
}

.hidedock.node.save:active #saveIcon,
.hidedock.node.save:focus #saveIcon,
.hidedock.node.save:visited #saveIcon {
  color: #e2dfcf;
}

.hidedock.node.inactive {
  display: none;
}

.dock--section {
  display: none;
}
}

@media (min-width: 481px) and (max-width: 767px) {
  .hidedock--container {
    height: 8vh;
    width: auto;
    border-radius: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    bottom: 0.5%;
    left: 0.75%;
    margin: auto;
    z-index: 1000;
  }

  .hidedock.node,
  .hidedock.node_save {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hidedockwide.node,
  .hidedockwide.node_save {
    height: 40px;
    width: 50px;
    border-radius: 50%;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0px;
  }

  .hidedock.separator {
    height: 40px;
    width: 15px;
    border-radius: 50%;
    background-color: transparent;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dock--section {
    display: none;
  }

  .icon {
    color: #e2dfcf;
    transform: scale(1.2);
  }

  .separator {
    color: #004a22;
    transform: scale(0.9);
  }

  .hidedock.node.save:active,
  .hidedock.node.save:focus,
  .hidedock.node.save:visited {
    background-color: #004a22;
    border: 3px solid whitesmoke;
  }

  .hidedock.node.save:active #saveIcon,
  .hidedock.node.save:focus #saveIcon,
  .hidedock.node.save:visited #saveIcon {
    color: #e2dfcf;
  }

  .hidedock.node.inactive {
    display: none;
  }

  .dock--container {
    height: 20vh;
    width: 20%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    bottom: 0%;
    right: 0%;
    margin-bottom: 12px;
    z-index: 25;
  }

  .objectiveButton,
  .objectiveButton.inactive {
    display: none;
  }

  .mobiledock--container.center {
    margin: 10px 0;
    height: auto;
    width: auto;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .mobiledock--container {
    margin: 10px 0;
    padding: 0 0 0 4px;
    height: auto;
    width: auto;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .mobiledock--container::-webkit-scrollbar {
    display: none;
  }

  .dock.node,
  .jordandock.node,
  .jordandock.node_save {
    min-height: 60px;
    min-width: 60px;
    max-height: 60px;
    max-width: 60px;
    position: relative;
    margin: 12px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-in-out;
  }

  .mobiledockwide.node {
    min-height: 60px;
    min-width: 75px;
    max-height: 60px;
    max-width: 75px;
    border-radius: 50%;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-in-out;
  }

  .jordandockwide.node,
  .jordandockwide.tooltip_node {
    min-height: 40px;
    min-width: 50px;
    max-height: 40px;
    max-width: 50px;
    /* border-radius: 50%; */
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0px;
    transition: all 0.2s ease-in-out;
  }

  #disabled {
    opacity: 0.3;
  }

  .dock.node:active:before,
  #openDock::before {
    content: attr(data-tooltip);
    background-color: rgba(0, 74, 35, 1);
    font-size: 0.8em;
    font-weight: 600;
    color: #e2dfcf;
    height: auto;
    width: max-content;
    max-width: 200px;
    padding: 5px 7px;
    margin: 6px;
    position: absolute;
    right: 48px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    white-space: normal;
  }

  #start::before {
    display: none;
  }

  .dock.node.indicate {
    min-height: 40px;
    min-width: 40px;
    max-height: 40px;
    max-width: 40px;
    border-radius: 50%;
    /* box-shadow: 0px 0px 10px rgba(0,0,0,0.3); */
    border: 4px solid rgba(0, 74, 35, 0.3);
    transform: scale3d(0.9, 0.9, 0.9);
    position: relative;
    margin: 4px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: 2s linear 0.1s infinite alternate nodeAnimation;
  }

  .dock.node.indicate::before {
    content: attr(data-tooltip);
    background-color: rgba(103, 36, 131, 1);
    font-size: 0.8em;
    font-weight: 600;
    color: #fff;
    height: auto;
    width: auto;
    padding: 5px 7px;
    margin: 6px;
    position: absolute;
    bottom: 48px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    white-space: nowrap;
  }

  .mobiledock.node {
    min-height: 60px;
    min-width: 60px;
    max-height: 60px;
    max-width: 60px;
    border-radius: 50%;
    position: relative;
    margin: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-in-out;
  }

  .mobiledock.node.animate {
    min-height: 40px;
    min-width: 40px;
    max-height: 40px;
    max-width: 40px;
    border-radius: 50%;
    border: 4px solid rgba(0, 74, 35, 0.3);
    transform: scale3d(0.9, 0.9, 0.9);
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobiledock.node.indicate {
    min-height: 40px;
    min-width: 40px;
    max-height: 40px;
    max-width: 40px;
    border-radius: 50%;
    /* box-shadow: 0px 0px 10px rgba(0,0,0,0.3); */
    border: 4px solid rgba(0, 74, 35, 0.3);
    transform: scale3d(0.9, 0.9, 0.9);
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: 1s linear 0.1s infinite alternate nodeAnimation;
  }

  #finalnode {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dock.node:hover,
  .dock.node:hover .dock.node .icon,
  .mini.node:hover,
  .mini.node:hover .mini.node .icon {
    transform: scale3d(1.1, 1.1, 1.1);
    transition: all 0.1s ease-in-out;
  }

  .dock.separator {
    height: 40px;
    width: 15px;
    border-radius: 50%;
    background-color: transports;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(90deg);
  }

  .icon {
    color: #e2dfcf;
    transform: scale(1.2);
  }

  .separator {
    color: #004a22;
    transform: scale(0.9);
  }

  .dock.node.save:hover {
    background-color: whitesmoke;
    color: #004a22;
  }

  .dock.node.save:hover #saveIcon {
    color: #004a22;
  }

  .dock.node.save:active,
  .dock.node.save:focus,
  .dock.node.save:visited {
    background-color: #004a22;
    border: 3px solid whitesmoke;
  }

  .dock.node.save:active #saveIcon,
  .dock.node.save:focus #saveIcon,
  .dock.node.save:visited #saveIcon {
    color: #e2dfcf;
  }

  .dockNodeWrapper {
    width: auto;
    display: flex;
    flex-direction: column;
    overflow: visible;
    justify-content: center;
    align-items: center;
  }

  .mobileDockNodeWrapper {
    display: flex;
    overflow: visible;
    justify-content: center;
    align-items: center;
  }

  .mini.node.inactive {
    display: none;
    transition: all 1s linear;
  }

  .mini.node {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #004a22;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    transition: all 0.1s ease-in-out;
  }

  #miniValuesIcon {
    color: #e2dfcf;
    transform: scale(0.6);
  }

  .nodeRow {
    display: flex;
    overflow: scroll;
    /* margin-top: 6px; */
    margin-bottom: 6px;
    margin-right: 72px;
  }

  .mobileNodeRow {
    display: flex;
    overflow: visible;
    margin-bottom: 6px;
  }

  .mobiledock.row {
    height: 10vh;
    width: 100%;
    background-color: whitesmoke;
    border-radius: 5px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
  }

  .row.values {
    height: 10vh;
    width: 100%;
    background-color: whitesmoke;
    border-radius: 5px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 6px 0 6px;
  }

  .mobiledock.row h1 {
    color: #004a22;
  }

  .dock--container {
    height: 50vh;
    width: 20%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    bottom: 0%;
    right: 0%;
    margin-bottom: 12px;
    z-index: 25;
  }

  .mobiledock--container {
    margin: 6px 0;
    padding: 0 0 0 4px;
    height: auto;
    width: auto;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .mobiledock--container::-webkit-scrollbar {
    display: none;
  }

  .dock.node {
    min-height: 40px;
    min-width: 40px;
    max-height: 40px;
    max-width: 40px;
    border-radius: 50%;
    background-color: #004a22;
    position: relative;
    margin: 4px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-in-out;
  }

  .disableddock.node {
    min-height: 60px;
    min-width: 60px;
    max-height: 60px;
    max-width: 60px;
    border-radius: 50%;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.25;
    transition: all 0.1s ease-in-out;
  }

  #finalnode {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dock.node:hover,
  .dock.node:hover .dock.node .icon,
  .mini.node:hover,
  .mini.node:hover .mini.node .icon {
    transform: scale3d(1.1, 1.1, 1.1);
    transition: all 0.1s ease-in-out;
  }

  .dock.separator {
    height: 40px;
    width: 15px;
    border-radius: 50%;
    background-color: transports;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(90deg);
  }

  .icon {
    color: #e2dfcf;
    transform: scale(1.2);
  }

  .separator {
    color: #004a22;
    transform: scale(0.9);
  }

  .dock.node.save:hover {
    background-color: whitesmoke;
    color: #004a22;
  }

  .dock.node.save:hover #saveIcon {
    color: #004a22;
  }

  .dock.node.save:active,
  .dock.node.save:focus,
  .dock.node.save:visited {
    background-color: #004a22;
    border: 3px solid whitesmoke;
  }

  .dock.node.save:active #saveIcon,
  .dock.node.save:focus #saveIcon,
  .dock.node.save:visited #saveIcon {
    color: #e2dfcf;
  }

  .dockNodeWrapper {
    display: flex;
    flex-direction: column;
    overflow: visible;
    justify-content: center;
    align-items: center;
  }

  .mobileDockNodeWrapper {
    display: flex;
    overflow: visible;
    justify-content: center;
    align-items: center;
  }

  .mini.node.inactive {
    display: none;
    transition: all 1s linear;
  }

  .mini.node {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #004a22;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    transition: all 0.1s ease-in-out;
  }

  #miniValuesIcon {
    color: #e2dfcf;
    transform: scale(0.6);
  }

  .nodeRow {
    display: flex;
    overflow: scroll;
    /* margin-top: 6px; */
    margin-bottom: 6px;
    margin-right: 72px;
  }

  .mobileNodeRow {
    display: flex;
    overflow: visible;
    margin-bottom: 6px;
  }

  .mobiledock.row {
    height: 10vh;
    width: 100%;
    background-color: whitesmoke;
    border-radius: 5px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
  }

  .row.values {
    height: 10vh;
    width: 100%;
    background-color: whitesmoke;
    border-radius: 5px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 6px 0 6px;
  }

  .mobiledock.row h1 {
    color: #004a22;
  }

  .disabledmobiledock.node {
    min-height: 40px;
    min-width: 40px;
    max-height: 40px;
    max-width: 40px;
    border-radius: 50%;
    position: relative;
    margin: 4px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.25;
    transition: all 0.1s ease-in-out;
  }

  /* Tutorial */
  .mobiledock--container.center.tute {
    margin: 6px 0;
    height: auto;
    width: auto;
    border-radius: 5px;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    background-color: transparent;
    padding: 18px;
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
  }

  .mobiledock--container.tute {
    margin: 6px 0;
    height: auto;
    width: auto;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    background-color: #f5f5f5;
    padding: 18px;
    box-shadow: 0px 0px 15px rgba(245, 245, 245, 0.5);
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .hidedock--container {
    height: 8vh;
    width: auto;
    border-radius: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    bottom: 0.5%;
    left: 0.75%;
    margin: auto;
    z-index: 1000;
  }

  .hidedock.node,
  .hidedock.node_save {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hidedockwide.node,
  .hidedockwide.node_save {
    height: 40px;
    width: 50px;
    border-radius: 50%;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0px;
  }

  .hidedock.node:hover::before {
    content: attr(data-tooltip);
    color: #fff;
    height: auto;
    width: auto;
    padding: 5px;
    margin: 6px;
    font-size: 0.8em;
    font-weight: 600;
    left: -20%;
    bottom: 44px;
    position: absolute;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    white-space: nowrap;
  }

  .hidedock.separator {
    height: 40px;
    width: 15px;
    border-radius: 50%;
    background-color: transparent;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon {
    color: #e2dfcf;
    transform: scale(1.2);
  }

  .separator {
    color: #004a22;
    transform: scale(0.9);
  }

  .hidedock.node.save:active,
  .hidedock.node.save:focus,
  .hidedock.node.save:visited {
    background-color: #004a22;
    border: 3px solid whitesmoke;
  }

  .hidedock.node.save:active #saveIcon,
  .hidedock.node.save:focus #saveIcon,
  .hidedock.node.save:visited #saveIcon {
    color: #e2dfcf;
  }

  .hidedock.node.inactive {
    display: none;
  }

  .dock--container {
    height: 20vh;
    width: 20%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    bottom: 0%;
    right: 0%;
    margin-bottom: 12px;
    z-index: 25;
  }

  .objectiveButton,
  .objectiveButton.inactive {
    display: none;
  }

  .mobiledock--container.center {
    margin: 10px 0;
    height: auto;
    width: auto;
    border-radius: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .mobiledock--container {
    margin: 10px 0;
    padding: 0 0 0 4px;
    height: auto;
    width: auto;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .mobiledock--container::-webkit-scrollbar {
    display: none;
  }

  .dock.node,
  .jordandock.node,
  .jordandock.node_save {
    min-height: 75px;
    min-width: 75px;
    max-height: 75px;
    max-width: 75px;
    position: relative;
    margin: 12px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-in-out;
  }

  .jordandockwide.node,
  .jordandockwide.tooltip_node {
    min-height: 60px;
    min-width: 75px;
    max-height: 60px;
    max-width: 75px;
    /* border-radius: 50%; */
    position: relative;
    margin: 12px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0px;
    transition: all 0.2s ease-in-out;
  }

  #disabled {
    opacity: 0.3;
  }

  .dock.node:active:before,
  #openDock::before {
    content: attr(data-tooltip);
    background-color: rgba(0, 74, 35, 1);
    font-size: 0.8em;
    font-weight: 600;
    color: #e2dfcf;
    height: auto;
    width: max-content;
    max-width: 200px;
    padding: 5px 7px;
    margin: 6px;
    position: absolute;
    right: 48px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    white-space: normal;
  }

  #start::before {
    display: none;
  }

  .dock.node.indicate {
    min-height: 40px;
    min-width: 40px;
    max-height: 40px;
    max-width: 40px;
    border-radius: 50%;
    /* box-shadow: 0px 0px 10px rgba(0,0,0,0.3); */
    border: 4px solid rgba(0, 74, 35, 0.3);
    transform: scale3d(0.9, 0.9, 0.9);
    position: relative;
    margin: 4px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: 2s linear 0.1s infinite alternate nodeAnimation;
  }

  .dock.node.indicate::before {
    content: attr(data-tooltip);
    background-color: rgba(103, 36, 131, 1);
    font-size: 0.8em;
    font-weight: 600;
    color: #fff;
    height: auto;
    width: auto;
    padding: 5px 7px;
    margin: 6px;
    position: absolute;
    bottom: 48px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    white-space: nowrap;
  }

  .mobiledock.node {
    min-height: 60px;
    min-width: 60px;
    max-height: 60px;
    max-width: 60px;
    border-radius: 50%;
    position: relative;
    margin: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-in-out;
  }

  .mobiledockwide.node {
    min-height: 60px;
    min-width: 75px;
    max-height: 60px;
    max-width: 75px;
    border-radius: 50%;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-in-out;
  }

  .mobiledock.node.animate {
    min-height: 40px;
    min-width: 40px;
    max-height: 40px;
    max-width: 40px;
    border-radius: 50%;
    border: 4px solid rgba(0, 74, 35, 0.3);
    transform: scale3d(0.9, 0.9, 0.9);
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobiledock.node.indicate {
    min-height: 40px;
    min-width: 40px;
    max-height: 40px;
    max-width: 40px;
    border-radius: 50%;
    /* box-shadow: 0px 0px 10px rgba(0,0,0,0.3); */
    border: 4px solid rgba(0, 74, 35, 0.3);
    transform: scale3d(0.9, 0.9, 0.9);
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: 1s linear 0.1s infinite alternate nodeAnimation;
  }

  #finalnode {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dock.node:hover,
  .dock.node:hover .dock.node .icon,
  .mini.node:hover,
  .mini.node:hover .mini.node .icon {
    transform: scale3d(1.1, 1.1, 1.1);
    transition: all 0.1s ease-in-out;
  }

  .dock.separator {
    height: 40px;
    width: 15px;
    border-radius: 50%;
    background-color: transports;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(90deg);
  }

  .icon {
    color: #e2dfcf;
    transform: scale(1.2);
  }

  .separator {
    color: #004a22;
    transform: scale(0.9);
  }

  .dock.node.save:hover {
    background-color: whitesmoke;
    color: #004a22;
  }

  .dock.node.save:hover #saveIcon {
    color: #004a22;
  }

  .dock.node.save:active,
  .dock.node.save:focus,
  .dock.node.save:visited {
    background-color: #004a22;
    border: 3px solid whitesmoke;
  }

  .dock.node.save:active #saveIcon,
  .dock.node.save:focus #saveIcon,
  .dock.node.save:visited #saveIcon {
    color: #e2dfcf;
  }

  .dockNodeWrapper {
    width: auto;
    display: flex;
    flex-direction: column;
    overflow: visible;
    justify-content: center;
    align-items: center;
  }

  .mobileDockNodeWrapper {
    display: flex;
    overflow: visible;
    justify-content: center;
    align-items: center;
  }

  .mini.node.inactive {
    display: none;
    transition: all 1s linear;
  }

  .mini.node {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #004a22;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    transition: all 0.1s ease-in-out;
  }

  #miniValuesIcon {
    color: #e2dfcf;
    transform: scale(0.6);
  }

  .nodeRow {
    display: flex;
    overflow: scroll;
    /* margin-top: 6px; */
    margin-bottom: 6px;
    margin-right: 72px;
  }

  .mobileNodeRow {
    display: flex;
    overflow: visible;
    margin-bottom: 6px;
  }

  .mobiledock.row {
    height: 10vh;
    width: 100%;
    background-color: whitesmoke;
    border-radius: 5px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
  }

  .row.values {
    height: 10vh;
    width: 100%;
    background-color: whitesmoke;
    border-radius: 5px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 6px 0 6px;
  }

  .mobiledock.row h1 {
    color: #004a22;
  }

  .disableddock.node {
    min-height: 60px;
    min-width: 60px;
    max-height: 60px;
    max-width: 60px;
    border-radius: 50%;
    position: relative;
    margin: 0px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.25;
    transition: all 0.1s ease-in-out;
  }
}

@media (min-width: 1026px) {
  .yeardock--container {
    height: 8vh;
    width: 100%;
    /* background-color: rgba(0,0,0,0.1); */
    border-radius: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    z-index: 1000;
    padding: 0 48px 0 0;
  }

  .yeardock.node,
  .yeardock.node_save {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0px;
  }

  .yeardockwide.node,
  .yeardockwide.node_save {
    height: 40px;
    width: 50px;
    border-radius: 50%;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0px;
  }

  .yeardock.node.animate {
    min-height: 40px;
    min-width: 40px;
    max-height: 40px;
    max-width: 40px;
    border-radius: 50%;
    /* box-shadow: 0px 0px 10px rgba(0,0,0,0.3); */
    transform: scale3d(0.9, 0.9, 0.9);
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .yeardock.node_save.success::before,
  .yeardock.node:hover::before,
  .yeardockwide.node:hover::before,
  .yeardock.node_save:hover::before {
    content: attr(data-tooltip);
    background-color: rgba(0, 74, 35, 1);
    color: #e2dfcf;
    height: auto;
    width: auto;
    padding: 5px;
    margin: 6px;
    font-size: 0.8em;
    font-weight: 600;
    left: -20%;
    bottom: 44px;
    position: absolute;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    white-space: nowrap;
  }

  .yeardock.separator {
    height: 40px;
    width: 15px;
    border-radius: 50%;
    background-color: transports;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .yeardock.node:hover,
  .yeardockwide.node:hover {
    bottom: 6px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
  }

  .yeardock.node_save:hover,
  .yeardockwide.node_save:hover {
    cursor: pointer;
    transition: all 0.1s ease-in-out;
  }

  .yeardock.node:hover .icon {
    color: #e2dfcf;
    transition: all 0.1s ease-in-out;
  }

  .icon {
    color: #e2dfcf;
    transform: scale(1.2);
  }

  .separator {
    color: #004a22;
    transform: scale(0.9);
  }

  .yeardock.node.save:hover #saveIcon {
    color: #e2dfcf;
  }

  .yeardock.node.save:active #saveIcon,
  .yeardock.node.save:focus #saveIcon,
  .yeardock.node.save:visited #saveIcon {
    color: #e2dfcf;
  }

  .yeardock.node.inactive {
    display: none;
  }

  /* Format */
  .dock--section {
    height: 15%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    /* background-color: aqua; */
    /* position: relative; */
  }

  .dock--container {
    height: auto;
    width: auto;
    max-width: 90%;
    border-radius: 8px 8px 0 0;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    /* position: relative; */
    /* background-color: rgba(0, 74, 34, 0.05); */
    padding: 36px 0 12px 0;
  }

  .dock.node,
  .dock.tooltip_node {
    min-height: 40px;
    min-width: 40px;
    max-height: 40px;
    max-width: 40px;
    border-radius: 50%;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-in-out;
  }

  .jordandockwide.node,
  .jordandockwide.tooltip_node {
    min-height: 40px;
    min-width: 50px;
    max-height: 40px;
    max-width: 50px;
    /* border-radius: 50%; */
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0px;
    transition: all 0.2s ease-in-out;
    z-index: 150;
  }

  .jordandock.node,
  .jordandock.node a,
  .jordandock.node_save,
  .jordandock.tooltip_node {
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
    max-height: 40px;
    max-width: 40px;
    position: relative;
    margin: 0 4px;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in;
  }

  .jordandock.node_save.animate {
    min-height: 40px;
    min-width: 40px;
    max-height: 40px;
    max-width: 40px;
    border-radius: 50%;
    /* box-shadow: 0px 0px 10px rgba(0,0,0,0.3); */
    border: 4px solid rgba(0, 74, 35, 0.3);
    transform: scale3d(0.9, 0.9, 0.9);
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dock.node.indicate::before {
    content: attr(data-tooltip);
    background-color: rgba(103, 36, 131, 1);
    font-size: 0.8em;
    font-weight: 600;
    color: #fff;
    height: auto;
    width: auto;
    padding: 5px 7px;
    margin: 6px;
    position: absolute;
    bottom: 48px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    white-space: nowrap;
  }

  .disabledmobiledock.node {
    min-height: 40px;
    min-width: 40px;
    max-height: 40px;
    max-width: 40px;
    border-radius: 50%;
    background-color: #004a22;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.25;
    transition: all 0.1s ease-in-out;
  }

  .dock.separator {
    height: 40px;
    width: 15px;
    border-radius: 50%;
    background-color: transports;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #finalnode {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon {
    color: #e2dfcf;
    transform: scale(1.2);
  }

  .separator {
    color: #004a22;
    transform: scale(0.9);
  }

  #disabled {
    opacity: 0.3;
  }

  .dockNodeWrapper {
    min-height: 40px;
    min-width: 40px;
    max-width: 40px;
    position: relative;
    display: flex;
    margin: 0 4px;
    flex-direction: column;
    overflow: visible;
    justify-content: center;
    align-items: center;
  }

  .mini.node.inactive,
  .objectiveButton.inactive {
    display: none;
    transition: all 1s linear;
  }

  .mini.node {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: none;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    bottom: 0px;
    transition: all 0.1s ease-in-out;
  }

  .mini.node:hover {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: none;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    bottom: 3px;
    transition: all 0.1s ease-in-out;
  }

  .objectiveButton {
    height: 20px;
    width: auto;
    padding: 2px 6px;
    font-size: 8px;
    color: #e2dfcf;
    background-color: #004a22;
    border-radius: 5px;
    box-shadow: none;
    outline: none;
    border: none;
    white-space: nowrap;
  }

  #miniValuesIcon {
    color: #e2dfcf;
    transform: scale(0.6);
  }

  .nodeRow {
    display: flex;
    overflow: visible;
    margin-bottom: 6px;
    white-space: none;
  }

  /* Animation */

  /* Tooltip */
  .dock.tooltip_node::before {
    content: attr(data-tooltip);
    background-color: rgba(0, 74, 35, 1);
    font-size: 0.8em;
    font-weight: 600;
    color: #fff;
    height: auto;
    width: auto;
    padding: 5px 7px;
    margin: 6px;
    position: absolute;
    bottom: 48px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    white-space: nowrap;
  }

  .jordandock.node_save.indicate::before {
    content: attr(data-tooltip);
    background-color: rgba(103, 36, 131, 1);
    font-size: 0.8em;
    font-weight: 600;
    color: #e2dfcf;
    height: auto;
    width: auto;
    padding: 5px 7px;
    margin: 6px;
    position: absolute;
    bottom: 48px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    white-space: nowrap;
  }

  .jordandock.node:hover::before,
  .jordandock.node_save:hover::before,
  .jordandockwide.node:hover::before {
    content: attr(data-tooltip);
    background-color: rgba(0, 74, 35, 1);
    font-size: 0.8em;
    font-weight: 600;
    color: #e2dfcf;
    height: auto;
    width: auto;
    padding: 5px 7px;
    margin: 6px;
    position: absolute;
    bottom: 48px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    white-space: nowrap;
  }

  .jordandock.node_save:hover::before {
    content: attr(data-tooltip);
    background-color: rgba(103, 36, 131, 1);
    font-size: 0.8em;
    font-weight: 600;
    color: #e2dfcf;
    height: auto;
    width: auto;
    padding: 5px 7px;
    margin: 6px;
    position: absolute;
    bottom: 48px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    white-space: nowrap;
  }

  .jordandock.node:hover,
  .jordandock.tooltip_node:hover,
  .jordandockwide.node:hover,
  .jordandockwide.tooltip_node:hover {
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    bottom: 6px;
  }

  .jordandock.node_save:hover {
    cursor: pointer;
  }

  .dock.node:hover .icon,
  .dock.tooltip_node:hover .icon {
    color: #e2dfcf;
    transition: all 0.1s ease-in-out;
  }

  .dock.node.save:active,
  .dock.node.save:focus,
  .dock.node.save:visited {
    background-color: #004a22;
    border: 3px solid whitesmoke;
  }

  .dock.node.save:active #saveIcon,
  .dock.node.save:focus #saveIcon,
  .dock.node.save:visited #saveIcon {
    color: #e2dfcf;
  }

  /* Format */
  .disableddock--container {
    height: 8vh;
    width: auto;
    border-radius: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    position: fixed;
    bottom: 0%;
    margin-bottom: 12px;
  }

  .disableddock.node {
    min-height: 40px;
    min-width: 40px;
    max-height: 40px;
    max-width: 40px;
    border-radius: 50%;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.25;
    transition: all 2s ease-in-out;
  }

  .disableddockwide.node {
    min-height: 40px;
    min-width: 50px;
    max-height: 40px;
    max-width: 50px;
    border-radius: 50%;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.25;
    transition: all 2s ease-in-out;
  }

  .disableddock.node.animate {
    min-height: 40px;
    min-width: 40px;
    max-height: 40px;
    max-width: 40px;
    border-radius: 50%;
    transform: scale3d(0.9, 0.9, 0.9);
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  

  .disableddock.node.indicate {
    min-height: 40px;
    min-width: 40px;
    max-height: 40px;
    max-width: 40px;
    transform: scale3d(0.9, 0.9, 0.9);
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* animation: 1s ease-in-out .1s infinite alternate flipFlop; */
    animation: 8s ease-in-out growAnimation;
  }

  .disableddockwide.node.indicate {
    min-height: 40px;
    min-width: 50px;
    max-height: 40px;
    max-width: 50px;
    transform: scale3d(0.9, 0.9, 0.9);
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* animation: 1s ease-in-out .1s infinite alternate flipFlop; */
    animation: 8s ease-in-out growAnimation;
  }

  @keyframes dockLoading {
    0% {
      opacity: 0;
      background: radial-gradient(
        circle at center,
        rgba(0, 74, 35, 0.5) 0%,
        rgba(226, 223, 207, 1) 100%
      );
      border: 0px solid rgba(0, 74, 35, 0.3);
      box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    }
    20% {
      opacity: 0.2;
      background: radial-gradient(
        circle at center,
        rgba(0, 74, 35, 0.5) 10%,
        rgba(226, 223, 207, 1) 100%
      );
      border: 0px solid rgba(0, 74, 35, 0.8);
      box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    }
    40% {
      opacity: 0.4;
      background: radial-gradient(
        circle at center,
        rgba(0, 74, 35, 0.5) 20%,
        rgba(226, 223, 207, 1) 100%
      );
      border: 0px solid rgba(0, 74, 35, 0.8);
      box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    }
    60% {
      opacity: 0.6;
      background: radial-gradient(
        circle at center,
        rgba(0, 74, 35, 0.5) 30%,
        rgba(226, 223, 207, 1) 100%
      );
      border: 0px solid rgba(0, 74, 35, 0.8);
      box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    }
    80% {
      opacity: 0.8;
      background: radial-gradient(
        circle at center,
        rgba(0, 74, 35, 0.5) 40%,
        rgba(226, 223, 207, 1) 100%
      );
      border: 0px solid rgba(0, 74, 35, 0.8);
      box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    }
    100% {
      opacity: 1;
      background: radial-gradient(
        circle at center,
        rgba(0, 74, 35, 0.5) 50%,
        rgba(226, 223, 207, 1) 100%
      );
      border: 0px solid rgba(0, 74, 35, 0.8);
      box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.05);
    }
  }

  @keyframes growAnimation {
    0% {
      opacity: 0;
      transform: scale3d(0.6, 0.6, 0.6);
    }
    25% {
      opacity: 0.25;
      transform: scale3d(0.8, 0.8, 0.8);
    }
    50% {
      opacity: 0.5;
      transform: scale3d(0.9, 0.9, 0.9);
    }
    75% {
      opacity: 0.7;
      transform: scale3d(0.95, 0.95, 0.95);
    }
    100% {
      opacity: 1;
      transform: scale3d(1, 1, 1);
    }
  }

  .disableddock.separator.indicate {
    height: 40px;
    width: 15px;
    border-radius: 50%;
    background-color: transparent;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: 8s ease-in-out growAnimation;
  }

  #finalnode {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .disableddock.node .icon {
    color: #e2dfcf;
    transform: scale(1.2);
  }

  .separator {
    color: #004a22;
    transform: scale(0.9);
  }

  #disabled {
    opacity: 0.3;
  }

  .disableddockNodeWrapper {
    min-height: 40px;
    min-width: 40px;
    max-width: 40px;
    position: relative;
    display: flex;
    margin: 0 4px;
    flex-direction: column;
    overflow: visible;
    justify-content: center;
    align-items: center;
  }

  .disabledmini.node.inactive {
    display: none;
    transition: all 1s linear;
  }

  .disabledmini.node {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #004a22;
    position: relative;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    transition: all 0.1s ease-in-out;
  }

  #miniValuesIcon {
    color: #e2dfcf;
    transform: scale(0.6);
  }

  .nodeRow {
    display: flex;
    overflow: visible;
    margin-bottom: 6px;
  }

  /* Animation */

  /* Tooltip */
  .disableddock.node:hover::before {
    content: attr(data-tooltip);
    background-color: rgba(0, 74, 35, 0.7);
    font-weight: 600;
    font-size: 0.8em;
    color: #fff;
    height: auto;
    width: auto;
    padding: 5px 7px;
    margin: 6px;
    position: absolute;
    bottom: 48px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    white-space: nowrap;
  }

  .disableddock.node.save:active,
  .disableddock.node.save:focus,
  .disableddock.node.save:visited {
    background-color: #004a22;
    border: 3px solid whitesmoke;
  }

  .disableddock.node.save:active #saveIcon,
  .disableddock.node.save:focus #saveIcon,
  .disableddock.node.save:visited #saveIcon {
    color: #e2dfcf;
  }

  @keyframes confetti {
    0% {
      transform: translateY(0) rotate(0deg);
    }
    100% {
      transform: translateY(-500px) rotate(360deg);
    }
  }

  #confettiTest:focus {
    animation: confetti 2s ease-in-out;
    animation-iteration-count: 1;
  }

  .mobiledock--container.center {
    display: none;
  }

  .mobiledock--container {
    display: none;
  }

  .hidedock--container {
    display: none;
  }
}
