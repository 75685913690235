/* ProfileScreen.css */

.mainProfileScreen {
    padding: 20px;
    background-color: #e2dfcf;
    min-height: 50vh;
  }
  
  .tabContainer.profile {
    margin: 0 auto;
    max-width: 1200px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .tabs.profile .tab {
    padding: 10px;
  }
  
  .container.profile {
    padding: 20px;
  }
  
  .updateProfileForm {
    display: flex;
    flex-direction: column;
  }
  
  .container.name,
  .container.email,
  .container.password,
  .container.confirmPassword {
    margin-bottom: 12px;
  }
  
  .label {
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .textInput {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .profileButtonDiv {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .updateProfileButton {
    padding: 10px 20px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .updateProfileButton:hover {
    background-color: #0056b3;
  }
  
  .container.contact {
    padding: 20px;
  }
  
  .contact-form .row {
    margin-bottom: 15px;
  }
  
  .contactLabel {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .enquirySelect {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .messageArea {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;
  }

  .nav-tabs .nav-link {
    color: #004a22;
  }
  
  .sendButton {
    padding: 10px 20px;
    background-color: #004a22;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .sendButton:hover {
    background-color: #218838;
  }
  
  @media (max-width: 768px) {
    .tabContainer.profile {
      padding: 10px;
    }
  
    .updateProfileForm,
    .contact-form {
      flex-direction: column;
    }
  
    .container.name,
    .container.email,
    .container.password,
    .container.confirmPassword,
    .row {
      width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .updateProfileForm,
    .contact-form {
      padding: 0;
    }
  
    .container.profile {
      padding: 10px;
    }
  }
  