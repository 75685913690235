.ticker-container {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    background-color: #004a22;
  }
  
  .ticker-content {
    display: flex;
 
    animation: ticker-scroll 20s linear infinite;
  }

  .ticker-content svg {
    min-width: 50px; /* Adjust as needed */
    min-height: 50px;
    margin: 0 6px;
  }

  
  @keyframes ticker-scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-50%);
    }
  }
  