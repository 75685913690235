@media (max-width: 480px) {
  .video__component {
    border-radius: 10px;
    background-color: #004a22;
    box-shadow: 4px 4px 5px rgba(0, 74, 34, 0.5);
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .video__component {
    border-radius: 10px;
    background-color: #004a22;
    box-shadow: 4px 4px 5px rgba(0, 74, 34, 0.5);
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .video__component {
    border-radius: 10px;
    background-color: #004a22;
    box-shadow: 4px 4px 5px rgba(0, 74, 34, 0.5);
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .video__component {
    border-radius: 10px;
    background-color: #004a22;
    box-shadow: 4px 4px 5px rgba(0, 74, 34, 0.5);
  }
}

@media (min-width: 1201px) {
  .video__component {
    border-radius: 10px;
    background-color: #004a22;
    box-shadow: 4px 4px 5px rgba(0, 74, 34, 0.5);
  }
}
