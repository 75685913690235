@media (max-width: 480px) {
  /* Overall Page  */
  .landingpage__main {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    background-color: #004a22;
  }

  .landingpage__hero--header {
    padding: 24px;
    text-align: start;
  }

  .landingpage__hero--header h1 {
    font-size: 4rem;
    color: #e2dfcf;
  }

  .landingpage__hero--header h1:first-child {
   margin-bottom: 24px;
  }

  /* Header Section  */
  .header__section {
    min-height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #004a22;
    margin: 12px;
  }

  .header__row {
    width: 100%;
    height: auto;
    display: flex;
  }

  .header__row.header__row--testimony {
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(0, 74, 34, 0);
    width: 100%;
  }

  .header__row.header__row--upper {
    justify-content: center;
    align-items: flex-start;
    margin: 0 0;
    padding: 24px;
  }

  .header__row--action {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .header__row--action a::before {
    content: attr(data-tooltip);
    font-size: large;
    top: 24px;
  }

  .header__row--action h1 {
    color: #004a22;
    font-size: 3em;
  }

  .wave--bottom {
    z-index: 2;
    width: 100%;
  }

  .wave--top {
    z-index: 2;
    width: 100%;
    position: relative;
  }

  .header__image--hero {
    height: auto;
    z-index: 5;
    width: 40%;
    transform: translate(0, 40px) scale(1.5);
  }

  .testimony__card {
    width: 272px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #e2dfcf;
    border-radius: 10px;
    z-index: 5;
    padding: 30px;
    color: #004a22;
  }

  .name--plate {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: #004a22;
    margin: 6px 0;
  }

  .name--plate img {
    display: none;
  }

  .name--details {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: start;
    color: #004a22;
  }

  .name--details p:first-child {
    font-weight: bold;
    font-size: x-small;
    color: #004a22;
    line-height: 1;
  }

  .name--details p:last-child {
    font-size: x-small;
    color: #004a22;
    line-height: 1;
  }

  .testimony__card:not(.testimony__card:first-child) {
    display: none;
  }

  /* Reasons to Believe Section  */
  .rtb__section {
    width: 100%;
  }

  .rtb__section--header {
    width: 100%;
    font-size: 2.5em;
    background-color: #004a22;
    color: #e2dfcf;
    text-align: center;
    padding: 12px 0;
  }

  .rtb__block.one,
  .rtb__block.two {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 12px;
  }

  .rtb__image .img {
    padding: 12px;
  }

  .rtb__text {
    padding: 0 36px;
    height: auto;
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .text--div {
    height: auto;
    width: 100%;
    text-align: start;
    color: #004a22;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
  }

  .text--div p {
    font-size: 1.2em;
  }

  .text--div button {
    max-width: fit-content;
  }

  /* Final Story  */
  .story__section {
    padding-top: 24px;
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    background-color: #004a22;
  }

  .story__section .main--message {
    width: 80%;
    text-align: center;
  }

  .main--message  h1{
    font-size: clamp(4vw, 5vw, 6vw);
    color: #e2dfcf;
  }


  .footer__section {
    height: 100vh;
    position: relative;
  }

  .testimony--shift.mission {
    padding: 0 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #e2dfcf;
  }

  .fadeIn {
    display: block;
    opacity: 1;
    transition: all 1s ease-in-out;
  }

  .fadeOut {
    display: none;
    opacity: 0;
    transition: all 1s ease-in-out;
  }

  .landingpage--header {
    max-width: 100%;
    background-color: rgba(0,74,34,0.2);
    color: #e2dfcf;
    font-size: clamp(6vw, 7vw, 8vw);
    margin: 0;
    padding: 12px 24px;
    border-radius: 8px;
    transition: max-width 1s ease-in-out;
    z-index: 1500;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  /* Overall Page  */
  .landingpage__main {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    background-color: #004a22;
  }

  .landingpage__hero--header {
    padding: 24px;
    text-align: start;
  }

  .landingpage__hero--header h1 {
    font-size: 4rem;
    color: #e2dfcf;
  }

  .landingpage__hero--header h1:first-child {
   margin-bottom: 24px;
  }

  /* Header Section  */
  .header__section {
    min-height: 250px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .header__row {
    width: 100%;
    height: auto;
    display: flex;
  }

  .header__row.header__row--upper {
    justify-content: center;
    align-items: flex-start;
  }

  .header__row--action {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .header__row--action h1 {
    color: #004a22;
  }

  .header__row--action h1:nth-child(2) {
    padding-bottom: 24px;
  }

  .header__row.header__row--testimony {
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #004a22;
  }

  .wave--bottom {
    z-index: 2;
    height: 100px;
    width: 100%;
    position: relative;
    bottom: 50px;
  }

  .wave--top {
    z-index: 2;
    height: 100px;
    width: 100%;
    position: relative;
    top: 50px;
  }

  .header__image--hero {
    z-index: 5;
    width: 40%;
    transform: translate(0, 60px) scale(1.3);
  }

  .header__testimony {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
  }

  .testimony__card {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #e2dfcf;
    border-radius: 5px;
    z-index: 5;
    padding: 30px;
  }

  .testimony__card:not(.testimony__card:first-child) {
    display: none;
  }

  .name--plate {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-right: 24px;
  }

  /* .name--plate img {
    width: auto;
    height: 100%;
    margin-right: 24px;
  } */

  .name--plate img {
    display: none;
  }

  .name--details {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
  }

  .name--details p:first-child {
    font-weight: bold;
  }

  /* Reasons to Believe Section  */
  .rtb__section {
    height: auto;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
  }

  .rtb__section h1 {
    width: 100%;
    color: #e2dfcf;
    font-size: 3.5em;
    background-color: #004a22;
    padding: 12px 0;
  }

  .rtb__block.one {
    height: auto;
    max-height: 95vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .rtb__block.two {
    height: auto;
    max-height: 95vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .rtb__image {
    height: 350px;
    max-height: 500px;
    width: 50%;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rtb__image--video {
    height: auto;
    width: 100%;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rtb__image .img {
    position: absolute;
    width: 40%;
    border-radius: 5px;
  }

  .rtb__image .img--video {
    position: absolute;
    width: 75%;
    border-radius: 5px;
  }

  .rtb__text {
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .text--div {
    height: 250px;
    width: 60%;
    text-align: center;
    color: #004a22;
  }

  .story__section {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #004a22;
  }

  .story__section .main--message {
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #004a22;
    text-align: center;
  }

  .story__section h1 {
    font-size: clamp(3vw, 4vw, 5vw);
  }

  .testimony--shift.about {
    background-color: #004a22;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 48px;
  }

  .testimony--shift.mission {
    padding: 0 48px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #e2dfcf;
  }

  .testimony--shift.mission h1,
  .testimony--shift.mission p {
    width: 65%;
    font-size: xx-large;
  }

  .fadeIn {
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }

  .fadeOut {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  .landingpage--header {
    width: 100%;
    color: #004a22;
    font-size: clamp(5vw, 6vw, 7vw);
    margin: 0;
    padding: 0;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  /* Overall Page  */
  .landingpage__main {
    height: auto;
    width: 100%;
    /* display: grid; 
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr; */
    display: flex; 
    flex-direction: column;
  }

  .hero__section {
      grid-area: 1 / 1 / 2 / 2; 
      background-color: #004a22;
  }

  .landingpage__hero--header {
    padding: 36px;
    text-align: center;
  }

  .landingpage__hero--header h1 {
    font-size: 6rem;
    color: #e2dfcf;
  }

  .landingpage__hero--header h1:first-child {
   margin-bottom: 24px;
  }

   /* Final Story  */
   .story__section {
    
      grid-area: 1 / 2 / 2 / 3; 
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #004a22;
  }

  .story__section .main--message {
    width: 80%;
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #e2dfcf;
    text-align: center;
  }

  .story__section h1 {
    font-size: clamp(3vw, 4vw, 5vw);
  }


  /* Header Section  */
  .header__section {
    min-height: 450px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 72px;
    background-color: #004a22;
  }

  .header__row {
    width: 100%;
    height: auto;
    display: flex;
  }

  .header__row.header__row--upper {
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0 0 24px 0;
  }

  .header__row--action {
    width: 47.5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-right: 24px;
  }

  .header__row--action h1 {
    color: #004a22;
    margin: 0;
    font-size: 5em;
  }

  .header__row.header__row--testimony {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #004a22;
  }

  .wave--bottom {
    z-index: 2;
    width: 100%;
    position: relative;
    bottom: 24px;
  }

  .wave--top {
    z-index: 2;
    width: 100%;
    position: relative;
    top: 24px;
  }

  .header__image--hero {
    position: absolute;
    width: 50%;
    /* transform: translate(-150px, 125px) scale(1.75); */
    z-index: 5;
    max-width: 50%;
  }

  .header__testimony {
    height: auto;
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 0 36px;
  }

  .testimony--shift {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .testimony--shift.about {
    background-color: #004a22;
    width: 75%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 48px;
  }

  .testimony--shift.mission {
    height: auto;
    padding: 0 48px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #e2dfcf;
  }

  .testimony--shift.mission h1,
  .testimony--shift.mission p {
    width: 65%;
    font-size: xx-large;
  }

  .testimony__card {
    min-width: 250px;
    max-width: 300px;
    height: 412px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #e2dfcf;
    z-index: 5;
    padding: 30px;
    border: 4px solid #004a22;
    border-radius: 10px;
  }

  .testimony__card p:first-child {
    font-size: 1.2em;
  }

  .testimony__card:first-child {
    margin-right: 12px;
  }

  .name--plate {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  /* .name--plate img {
    width: auto;
    height: 100%;
    margin-right: 12px;
  } */

  .name--plate img {
    display: none;
  }

  .name--details {
    width: auto;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: start;
  }

  .name--details p:first-child {
    font-weight: bold;
  }

  /* Reasons to Believe Section  */
  .rtb__section {
    position: relative;
    width: 100%;
    padding: 48px 96px;
    text-align: center;
  }

  .rtb__section--header {
    font-size: 5em;
    color: #004a22;
    margin-bottom: 48px;
  }

  .rtb__block.one {
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-right: 60px;
  }

  .rtb__block.two {
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    padding-left: 60px;
  }

  .rtb__image {
    height: 500px;
    width: 50%;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rtb__image--video {
    height: auto;
    width: 100%;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rtb__image .img {
    position: absolute;
    width: 45%;
    border-radius: 5px;
  }

  .rtb__image .img--video {
    position: absolute;
    width: 45%;
    border-radius: 5px;
  }

  .rtb__text {
    height: 500px;
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .text--div {
    height: 250px;
    width: 400px;
    text-align: start;
    color: #004a22;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .text--div p {
    font-size: 1.2em;
  }

  .text--div button {
    max-width: fit-content;
  }
 

  .fadeIn {
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }

  .fadeOut {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  .landingpage--header {
    width: 100%;
    color: #004a22;
    font-size: clamp(5vw, 6vw, 7vw);
    margin: 0;
    padding: 0;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  /* Overall Page  */
  .landingpage__main {
    height: auto;
    width: 100%;
    /* display: grid; 
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr; */
    display: flex; 
    flex-direction: column;
  }

  .hero__section {
      grid-area: 1 / 1 / 2 / 2; 
      background-color: #004a22;
  }

  .landingpage__hero--header {
    padding: 36px;
    text-align: center;
  }

  .landingpage__hero--header h1 {
    font-size: 6rem;
    color: #e2dfcf;
  }

  .landingpage__hero--header h1:first-child {
   margin-bottom: 24px;
  }

   /* Final Story  */
   .story__section {
    
      grid-area: 1 / 2 / 2 / 3; 
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #004a22;
  }

  .story__section .main--message {
    width: 80%;
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #e2dfcf;
    text-align: center;
  }

  .story__section h1 {
    font-size: clamp(3vw, 4vw, 5vw);
  }


  /* Header Section  */
  .header__section {
    min-height: 450px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 72px;
    background-color: #004a22;
  }

  .header__row {
    width: 100%;
    height: auto;
    display: flex;
  }

  .header__row.header__row--upper {
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0 0 24px 0;
  }

  .header__row--action {
    width: 47.5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-right: 24px;
  }

  .header__row--action h1 {
    color: #004a22;
    margin: 0;
    font-size: 5em;
  }

  .header__row.header__row--testimony {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #004a22;
  }

  .wave--bottom {
    z-index: 2;
    width: 100%;
    position: relative;
    bottom: 24px;
  }

  .wave--top {
    z-index: 2;
    width: 100%;
    position: relative;
    top: 24px;
  }

  .header__image--hero {
    position: absolute;
    width: 50%;
    /* transform: translate(-150px, 125px) scale(1.75); */
    z-index: 5;
    max-width: 50%;
  }

  .header__testimony {
    height: auto;
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 0 36px;
  }

  .testimony--shift {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .testimony--shift.about {
    background-color: #004a22;
    width: 75%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 48px;
  }

  .testimony--shift.mission {
    height: auto;
    padding: 0 48px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #e2dfcf;
  }

  .testimony--shift.mission h1,
  .testimony--shift.mission p {
    width: 65%;
    font-size: xx-large;
  }

  .testimony__card {
    min-width: 250px;
    max-width: 300px;
    height: 412px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #e2dfcf;
    z-index: 5;
    padding: 30px;
    border: 4px solid #004a22;
    border-radius: 10px;
  }

  .testimony__card p:first-child {
    font-size: 1.2em;
  }

  .testimony__card:first-child {
    margin-right: 12px;
  }

  .name--plate {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  /* .name--plate img {
    width: auto;
    height: 100%;
    margin-right: 12px;
  } */

  .name--plate img {
    display: none;
  }

  .name--details {
    width: auto;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: start;
  }

  .name--details p:first-child {
    font-weight: bold;
  }

  /* Reasons to Believe Section  */
  .rtb__section {
    position: relative;
    width: 100%;
    padding: 48px 96px;
    text-align: center;
  }

  .rtb__section--header {
    font-size: 5em;
    color: #004a22;
    margin-bottom: 48px;
  }

  .rtb__block.one {
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-right: 60px;
  }

  .rtb__block.two {
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    padding-left: 60px;
  }

  .rtb__image {
    height: 500px;
    width: 50%;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rtb__image--video {
    height: auto;
    width: 100%;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rtb__image .img {
    position: absolute;
    width: 45%;
    border-radius: 5px;
  }

  .rtb__image .img--video {
    position: absolute;
    width: 45%;
    border-radius: 5px;
  }

  .rtb__text {
    height: 500px;
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .text--div {
    height: 250px;
    width: 400px;
    text-align: start;
    color: #004a22;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .text--div p {
    font-size: 1.2em;
  }

  .text--div button {
    max-width: fit-content;
  }
 

  .fadeIn {
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }

  .fadeOut {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  .landingpage--header {
    width: 100%;
    color: #004a22;
    font-size: clamp(5vw, 6vw, 7vw);
    margin: 0;
    padding: 0;
  }
}

@media (min-width: 1201px) and (max-width: 1325px) {
   /* Overall Page  */
   .landingpage__main {
    height: auto;
    width: 100%;
    /* display: grid; 
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr; */
    display: flex; 
    flex-direction: column;
  }

  .hero__section {
      grid-area: 1 / 1 / 2 / 2; 
      background-color: #004a22;
  }

  .landingpage__hero--header {
    padding: 36px;
    text-align: center;
  }

  .landingpage__hero--header h1 {
    font-size: 6rem;
    color: #e2dfcf;
  }

  .landingpage__hero--header h1:first-child {
   margin-bottom: 24px;
  }

   /* Final Story  */
   .story__section {
    
      grid-area: 1 / 2 / 2 / 3; 
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #004a22;
  }

  .story__section .main--message {
    width: 80%;
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #e2dfcf;
    text-align: center;
  }

  .story__section h1 {
    font-size: clamp(3vw, 4vw, 5vw);
  }


  /* Header Section  */
  .header__section {
    min-height: 450px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 72px;
    background-color: #004a22;
  }

  .header__row {
    width: 100%;
    height: auto;
    display: flex;
  }

  .header__row.header__row--upper {
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0 0 24px 0;
  }

  .header__row--action {
    width: 47.5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-right: 24px;
  }

  .header__row--action h1 {
    color: #004a22;
    margin: 0;
    font-size: 5em;
  }

  .header__row.header__row--testimony {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #004a22;
  }

  .wave--bottom {
    z-index: 2;
    width: 100%;
    position: relative;
    bottom: 24px;
  }

  .wave--top {
    z-index: 2;
    width: 100%;
    position: relative;
    top: 24px;
  }

  .header__image--hero {
    position: absolute;
    width: 50%;
    /* transform: translate(-150px, 125px) scale(1.75); */
    z-index: 5;
    max-width: 50%;
  }

  .header__testimony {
    height: auto;
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 0 36px;
  }

  .testimony--shift {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .testimony--shift.about {
    background-color: #004a22;
    width: 75%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 48px;
  }

  .testimony--shift.mission {
    height: auto;
    padding: 0 48px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #e2dfcf;
  }

  .testimony--shift.mission h1,
  .testimony--shift.mission p {
    width: 65%;
    font-size: xx-large;
  }

  .testimony__card {
    min-width: 250px;
    max-width: 300px;
    height: 412px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #e2dfcf;
    z-index: 5;
    padding: 30px;
    border: 4px solid #004a22;
    border-radius: 10px;
  }

  .testimony__card p:first-child {
    font-size: 1.2em;
  }

  .testimony__card:first-child {
    margin-right: 12px;
  }

  .name--plate {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  /* .name--plate img {
    width: auto;
    height: 100%;
    margin-right: 12px;
  } */

  .name--plate img {
    display: none;
  }

  .name--details {
    width: auto;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: start;
  }

  .name--details p:first-child {
    font-weight: bold;
  }

  /* Reasons to Believe Section  */
  .rtb__section {
    position: relative;
    width: 100%;
    padding: 48px 96px;
    text-align: center;
  }

  .rtb__section--header {
    font-size: 5em;
    color: #004a22;
    margin-bottom: 48px;
  }

  .rtb__block.one {
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-right: 60px;
  }

  .rtb__block.two {
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    padding-left: 60px;
  }

  .rtb__image {
    height: 500px;
    width: 50%;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rtb__image--video {
    height: auto;
    width: 100%;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rtb__image .img {
    position: absolute;
    width: 45%;
    border-radius: 5px;
  }

  .rtb__image .img--video {
    position: absolute;
    width: 45%;
    border-radius: 5px;
  }

  .rtb__text {
    height: 500px;
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .text--div {
    height: 250px;
    width: 400px;
    text-align: start;
    color: #004a22;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .text--div p {
    font-size: 1.2em;
  }

  .text--div button {
    max-width: fit-content;
  }
 

  .fadeIn {
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }

  .fadeOut {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  .landingpage--header {
    width: 100%;
    color: #004a22;
    font-size: clamp(5vw, 6vw, 7vw);
    margin: 0;
    padding: 0;
  }
}

@media (min-width: 1325px) and (max-width: 1999px) {
  /* Overall Page  */
  .landingpage__main {
    height: auto;
    width: 100%;
    /* display: grid; 
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr; */
    display: flex; 
    flex-direction: column;
  }

  .hero__section {
      grid-area: 1 / 1 / 2 / 2; 
      background-color: #004a22;
  }

  .landingpage__hero--header {
    padding: 36px;
    text-align: center;
  }

  .landingpage__hero--header h1 {
    font-size: 6rem;
    color: #e2dfcf;
  }

  .landingpage__hero--header h1:first-child {
   margin-bottom: 24px;
  }

   /* Final Story  */
   .story__section {
    
      grid-area: 1 / 2 / 2 / 3; 
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #004a22;
  }

  .story__section .main--message {
    width: 80%;
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #e2dfcf;
    text-align: center;
  }

  .story__section h1 {
    font-size: clamp(3vw, 4vw, 5vw);
  }


  /* Header Section  */
  .header__section {
    min-height: 450px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 72px;
    background-color: #004a22;
  }

  .header__row {
    width: 100%;
    height: auto;
    display: flex;
  }

  .header__row.header__row--upper {
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0 0 24px 0;
  }

  .header__row--action {
    width: 47.5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-right: 24px;
  }

  .header__row--action h1 {
    color: #004a22;
    margin: 0;
    font-size: 5em;
  }

  .header__row.header__row--testimony {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #004a22;
  }

  .wave--bottom {
    z-index: 2;
    width: 100%;
    position: relative;
    bottom: 24px;
  }

  .wave--top {
    z-index: 2;
    width: 100%;
    position: relative;
    top: 24px;
  }

  .header__image--hero {
    position: absolute;
    width: 50%;
    /* transform: translate(-150px, 125px) scale(1.75); */
    z-index: 5;
    max-width: 50%;
  }

  .header__testimony {
    height: auto;
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 0 36px;
  }

  .testimony--shift {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .testimony--shift.about {
    background-color: #004a22;
    width: 75%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 48px;
  }

  .testimony--shift.mission {
    height: auto;
    padding: 0 48px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #e2dfcf;
  }

  .testimony--shift.mission h1,
  .testimony--shift.mission p {
    width: 65%;
    font-size: xx-large;
  }

  .testimony__card {
    min-width: 250px;
    max-width: 300px;
    height: 412px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #e2dfcf;
    z-index: 5;
    padding: 30px;
    border: 4px solid #004a22;
    border-radius: 10px;
  }

  .testimony__card p:first-child {
    font-size: 1.2em;
  }

  .testimony__card:first-child {
    margin-right: 12px;
  }

  .name--plate {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  /* .name--plate img {
    width: auto;
    height: 100%;
    margin-right: 12px;
  } */

  .name--plate img {
    display: none;
  }

  .name--details {
    width: auto;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: start;
  }

  .name--details p:first-child {
    font-weight: bold;
  }

  /* Reasons to Believe Section  */
  .rtb__section {
    position: relative;
    width: 100%;
    padding: 48px 96px;
    text-align: center;
  }

  .rtb__section--header {
    font-size: 5em;
    color: #004a22;
    margin-bottom: 48px;
  }

  .rtb__block.one {
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-right: 60px;
  }

  .rtb__block.two {
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    padding-left: 60px;
  }

  .rtb__image {
    height: 500px;
    width: 50%;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rtb__image--video {
    height: auto;
    width: 100%;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rtb__image .img {
    position: absolute;
    width: 45%;
    border-radius: 5px;
  }

  .rtb__image .img--video {
    position: absolute;
    width: 45%;
    border-radius: 5px;
  }

  .rtb__text {
    height: 500px;
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .text--div {
    height: 250px;
    width: 400px;
    text-align: start;
    color: #004a22;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .text--div p {
    font-size: 1.2em;
  }

  .text--div button {
    max-width: fit-content;
  }
 

  .fadeIn {
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }

  .fadeOut {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  .landingpage--header {
    width: 100%;
    color: #004a22;
    font-size: clamp(5vw, 6vw, 7vw);
    margin: 0;
    padding: 0;
  }
}

@media (min-width: 2000px) {
  /* Overall Page  */
  .landingpage__main {
    height: 80vh;
    width: 100%;
    /* display: grid; 
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr; */
    display: flex; 
    flex-direction: column;
    background-color: #004a22;
  }

  .faq-container {
    display: none; 
  }

  .hero__section {
      background-color: #004a22;
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
  }

  .landingpage__hero--header {
    padding: 36px;
    text-align: center;
  }

  .landingpage__hero--header h1 {
    font-size: 6rem;
    color: #e2dfcf;
  }

  .landingpage__hero--header h1:first-child {
   margin-bottom: 24px;
  }

   /* Final Story  */
   .story__section {
    
      grid-area: 1 / 2 / 2 / 3; 
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #004a22;
  }

  .story__section .main--message {
    width: 80%;
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #e2dfcf;
    text-align: center;
  }

  .story__section h1 {
    font-size: clamp(3vw, 4vw, 5vw);
  }


  /* Header Section  */
  .header__section {
    min-height: 450px;
    width: 100%;
    /* display: flex; */
    display: none;
    flex-direction: column;
    justify-content: center;
    padding: 0 72px;
    background-color: #004a22;
  }

  .header__row {
    width: 100%;
    height: auto;
    display: flex;
  }

  .header__row.header__row--upper {
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0 0 24px 0;
  }

  .header__row--action {
    width: 47.5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-right: 24px;
  }

  .header__row--action h1 {
    color: #004a22;
    margin: 0;
    font-size: 5em;
  }

  .header__row.header__row--testimony {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #004a22;
  }

  .wave--bottom {
    z-index: 2;
    width: 100%;
    position: relative;
    bottom: 24px;
  }

  .wave--top {
    z-index: 2;
    width: 100%;
    position: relative;
    top: 24px;
  }

  .header__image--hero {
    position: absolute;
    width: 50%;
    /* transform: translate(-150px, 125px) scale(1.75); */
    z-index: 5;
    max-width: 50%;
  }

  .header__testimony {
    height: auto;
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 0 36px;
  }

  .testimony--shift {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .testimony--shift.about {
    background-color: #004a22;
    width: 75%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 48px;
  }

  .testimony--shift.mission {
    height: auto;
    padding: 0 48px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #e2dfcf;
  }

  .testimony--shift.mission h1,
  .testimony--shift.mission p {
    width: 65%;
    font-size: xx-large;
  }

  .testimony__card {
    min-width: 250px;
    max-width: 300px;
    height: 412px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: #e2dfcf;
    z-index: 5;
    padding: 30px;
    border: 4px solid #004a22;
    border-radius: 10px;
  }

  .testimony__card p:first-child {
    font-size: 1.2em;
  }

  .testimony__card:first-child {
    margin-right: 12px;
  }

  .name--plate {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  /* .name--plate img {
    width: auto;
    height: 100%;
    margin-right: 12px;
  } */

  .name--plate img {
    display: none;
  }

  .name--details {
    width: auto;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: start;
  }

  .name--details p:first-child {
    font-weight: bold;
  }

  /* Reasons to Believe Section  */
  .rtb__section {
    position: relative;
    width: 100%;
    padding: 48px 96px;
    text-align: center;
  }

  .rtb__section--header {
    font-size: 5em;
    color: #004a22;
    margin-bottom: 48px;
  }

  .rtb__block.one {
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-right: 60px;
  }

  .rtb__block.two {
    height: 500px;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    align-items: center;
    padding-left: 60px;
  }

  .rtb__image {
    height: 500px;
    width: 50%;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rtb__image--video {
    height: auto;
    width: 100%;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rtb__image .img {
    position: absolute;
    width: 45%;
    border-radius: 5px;
  }

  .rtb__image .img--video {
    position: absolute;
    width: 45%;
    border-radius: 5px;
  }

  .rtb__text {
    height: 500px;
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .text--div {
    height: 250px;
    width: 400px;
    text-align: start;
    color: #004a22;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .text--div p {
    font-size: 1.2em;
  }

  .text--div button {
    max-width: fit-content;
  }
 

  .fadeIn {
    opacity: 1;
    transition: opacity 1s ease-in-out;
  }

  .fadeOut {
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  .landingpage--header {
    width: 100%;
    color: #004a22;
    font-size: clamp(5vw, 6vw, 7vw);
    margin: 0;
    padding: 0;
  }
}