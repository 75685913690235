/* contact.css */

.contactPage {
  padding: 20px;
  background-color: #e2dfcf;
  min-height: 50vh;
}

.container.send {
  margin: 0 auto;
  max-width: 800px;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.row {
  margin-bottom: 15px;
}

.label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #004a22;
}

.formInput.register {
  width: 100%;
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.enquirySelect {
  width: 100%;
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.messageArea {
  width: 100%;
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
}

.registerButton {
  padding: 10px 20px;
  background-color: #004a22;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

.registerButton:hover {
  background-color: #218838;
}

@media (max-width: 768px) {
  .container.send {
    padding: 10px;
  }

  .row {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .container.send {
    padding: 10px;
  }

  .row {
    margin-bottom: 10px;
  }
}
