.sideBySide {
  display: flex;
}

.toggleButtonDiv {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.toggleButtonDiv img {
  margin-bottom: 12px;
}

.toggleSpread {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#scoreHere {
  margin-right: 24px;
}



#waveContent {
  display: inline-block;
}

.waveContentChart {
  border: 3px solid #004a22;
  border-radius: 10px;
}