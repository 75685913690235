#debriefColDiv {
  height: 100%;
  display: grid;
  width: 100%;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  margin: 0;
  padding: 0;
  border: none;
}

.positiveInControl {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  color: #004a22;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-right: 2px solid lightgrey;
  border-bottom: 2px solid lightgrey;
  padding: 6px;
}

.positiveInControl img,
.positiveOutControl img,
.negativeInControl img,
.negativeOutControl img
 {
  border-radius: 5px;
}

.positiveOutControl {
  grid-column: 2 / 2;
  grid-row: 1 / 1;
  color: #1D1F20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-left: 2px solid lightgrey;
  border-bottom: 2px solid lightgrey;
  padding: 6px;
}

.negativeInControl {
  grid-column: 1 / 1;
  grid-row: 2 / 2;
  color: #1D1F20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-right: 2px solid lightgrey;
  border-top: 2px solid lightgrey;
  padding: 6px;
  overflow: hidden;
}

.negativeOutControl {
  grid-column: 2 / 2;
  grid-row: 2 / 2;
  color: #1D1F20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-left: 2px solid lightgrey;
  border-top: 2px solid lightgrey;
  padding: 6px;
}

.debriefButton {
  background-color: #004a22;
  color: whitesmoke;
  border: 1px solid #004a22;
padding: 0 6px 0 6px;
  border-radius: 5px;
  margin-top: 12px;
}

.why span {
  font-size: 0.8em;
}