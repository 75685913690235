/* Styling for BioSite component */
@media (min-width: 0px) and (max-width: 426px) {
  .bio__site--main {
    margin: 24px;
  }

  .bio-container {
    height: auto;
    width: 100%;
    border-radius: 5px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.1);
  }

  .links-section {
    width: auto;
    /* background-color: #f5f5f5; */
    padding: 6px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
  }

  .links-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .links-section ul li {
    margin-bottom: 16px;
    padding: 6px 0;
    font-size: 1.25em;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  }

  .links-section li div {
    width: auto;
    height: 24px;
    margin-right: 12px;
    /* box-shadow: -7.5px 7.5px 7.5px rgba(0, 0, 0, 0.1); */
  }

  .links-section ul li a {
    padding: 6px;
    background-color: #e2dfcf;
    color: #004a22;
    text-decoration: none;
    /* font-size: 1.1em; */
    border-radius: 5px;
    box-shadow: 7.5px 7.5px 7.5px rgba(0, 0, 0, 0.1);
  }

  .social-links {
    width: 100%;
    display: flex;
  }

  .social-links ul {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    list-style: none;
    padding: 0;
    margin: 24px 0 0 0;
  }

  .social-links li {
    /* height: 50px; */
    width: 50px;
    padding: 6px 9px;
    background-color: #004a22;
    border-radius: 5px;
    box-shadow: -7.5px 7.5px 7.5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
  }

  .social-links a {
    text-decoration: none;
    font-size: 1.2em;
    color: #e2dfcf;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .bio__site--main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bio-container {
    height: auto;
    width: 65%;
    border-radius: 5px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.1);
  }

  .links-section {
    width: auto;
    /* background-color: #f5f5f5; */
    padding: 6px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
  }

  .links-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .links-section ul li {
    margin-bottom: 16px;
    padding: 6px 0;
    font-size: 1.25em;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  }

  .links-section li div {
    width: 15%;
    height: 24px;
    margin-right: 12px;
    /* box-shadow: -7.5px 7.5px 7.5px rgba(0, 0, 0, 0.1); */
  }

  .links-section ul li a {
    width: auto;
    padding: 6px;
    background-color: #e2dfcf;
    color: #004a22;
    text-decoration: none;
    /* font-size: 1.1em; */
    border-radius: 5px;
    box-shadow: 7.5px 7.5px 7.5px rgba(0, 0, 0, 0.1);
  }

  .social-links {
    width: 65%;
    display: flex;
  }

  .social-links ul {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    list-style: none;
    padding: 0;
    margin: 24px 0;
  }

  .social-links li {
    /* height: 50px; */
    width: 50px;
    padding: 6px 9px;
    background-color: #004a22;
    border-radius: 5px;
    box-shadow: -7.5px 7.5px 7.5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
  }

  .social-links a {
    text-decoration: none;
    font-size: 1.2em;
    color: #e2dfcf;
  }
}

@media (min-width: 769px) {
  .bio__site--main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 24px 0;
  }

  .bio-container {
    height: auto;
    width: 550px;
    border-radius: 5px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow: -15px 15px 15px rgba(0, 0, 0, 0.1);
  }

  .links-section {
    width: auto;
    /* background-color: #f5f5f5; */
    padding: 6px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
  }

  .links-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .links-section ul li {
    margin-bottom: 16px;
    padding: 6px 0;
    font-size: 1.25em;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
  }

  .links-section li div {
    width: auto;
    height: 24px;
    margin-right: 12px;
    /* box-shadow: -7.5px 7.5px 7.5px rgba(0, 0, 0, 0.1); */
  }

  .links-section ul li a {
    padding: 6px;
    background-color: #e2dfcf;
    color: #004a22;
    text-decoration: none;
    /* font-size: 1.1em; */
    border-radius: 5px;
    box-shadow: 7.5px 7.5px 7.5px rgba(0, 0, 0, 0.1);
  }

  .social-links {
    width: 550px;
    display: flex;
  }

  .social-links ul {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    list-style: none;
    padding: 0;
    margin: 24px 0;
  }

  .social-links li {
    /* height: 50px; */
    width: 50px;
    padding: 6px 9px;
    background-color: #004a22;
    border-radius: 5px;
    box-shadow: -7.5px 7.5px 7.5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
  }

  .social-links a {
    text-decoration: none;
    font-size: 1.2em;
    color: #e2dfcf;
  }
}
