@media (max-width: 480px) {
}

@media (min-width: 481px) and (max-width: 767px) {
}

@media (min-width: 768px) and (max-width: 1024px) {
}

@media (min-width: 1025px) and (max-width: 1200px) {
}

@media (min-width: 1201px) {
  .matrix__div {
    width: 100%;
    height: 50vh;
  }

  .matrix__div table {
    height: 100%;
    width: 100%;
  }

  .matrix__cell {
    height: 24px;
    width: 96px;
    background-color: aquamarine;
  }
}
