@media (max-width: 480px) {
  .carousel {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .carousel--item1 {
    height: auto;
    width: 85%;
    position: absolute;
    z-index: 1010;
    opacity: 1;
    transform: translateY(-36px);
    box-shadow: 5px -5px 15px rgba(0, 74,34,0.25);
    border-radius: 10px;
    transition:
      opacity 0.5 ease-in-out;
  }

  .carousel--item0,
  .carousel--item2 {
    height: auto;
    width: 45%;
    z-index: 1000;
    margin: 0 12px;
    opacity: 0.5;
    position: relative;
    border-radius: 5px;
    transition:
      opacity 0.5 ease-in-out;
  }

  .carousel__outer {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 6px 12px;
    position: relative;
  }

  .carousel--item0 .carousel--header,
  .carousel--item2 .carousel--header {
    opacity: 0;
  }

  .carousel--item1 .carousel--header {
    color: #e2dfcf;
    opacity: 1;
    padding: 12px 0 0 0;
    transition: opacity 0.5s ease-in-out;
  }

  .carousel--button {
    height: 24px;
    width: 24px;
  }

  .flipped {
    transform: rotate(180deg);
  }

   /* Review Carousel */ 
 .review__section {
  width: 100%;
  min-height: 600px;
  height: auto;
  max-height: 750px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e2dfcf;
}

.button__wrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.review__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
}

.review__outer {
  width: 100%;
  padding: 30px 36px;
  border-radius: 30px;
  background-color: #004a22;
  display: flex;
  flex-direction: column;
  margin: 12px 0;
}

.review__row {
  width: 100%;
  display: flex;
  align-items: center;
}

.review__row:first-child {
  padding: 0 0 12px 0;
}

.review__row:last-child {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.review__row:first-child span {
  color: #e2dfcf; 
  opacity: 0.75;
}

.review__row span {
  color: #e2dfcf;
}

.review__img {
  width: 30%;
}

.review__img img {
  border-radius: 10px;
  padding: 0 6px 0 0;
}

.review__name {
  color: #e2dfcf;
  text-align: start;
  padding: 6px;
}

.review__name p {
  font-size: x-large;
  margin: 0;
  padding: 0;
}


.review__main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
}

.review__text {
  color: #e2dfcf;
  /* font-weight: 800; */
  font-size: larger;
}
}

@media (min-width: 481px) and (max-width: 767px) {
  .carousel {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .carousel--item1 {
    height: auto;
    width: 75%;
    margin: 0 12px;
    position: absolute;
    z-index: 1010;
    opacity: 1;
    transform: translateY(-60px);
    transition: width 1s ease-in-out;
  }

  .carousel--item0,
  .carousel--item2 {
    height: auto;
    width: 45%;
    z-index: 1000;
    margin: 0 12px;
    opacity: 0.5;
    position: relative;
    border-radius: 5px;
    transition:
      width 1s ease-in-out,
      opacity 0.5 ease-in-out;
  }

  .carousel--item0:hover,
  .carousel--item2:hover {
    cursor: pointer;
    opacity: 0.75;
    transition: opacity 0.5s ease-in-out;
  }

  .carousel__outer {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 12px 24px;
    position: relative;
  }

  .carousel--item0 .carousel--header,
  .carousel--item2 .carousel--header {
    opacity: 0;
  }

  .carousel--item1 .carousel--header {
    color: #e2dfcf;
    opacity: 1;
    padding: 12px 0 0 0;
    transition: opacity 0.5s ease-in-out;
  }

  .carousel--button {
    height: 36px;
    width: 36px;
  }

  .flipped {
    transform: rotate(180deg);
  }

   /* Review Carousel */ 
 .review__section {
  width: 100%;
  min-height: 600px;
  height: auto;
  max-height: 750px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e2dfcf;
}

.button__wrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.review__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 36px;
}

.review__outer {
  width: 100%;
  padding: 36px;
  border-radius: 30px;
  background-color: #004a22;
  display: flex;
  flex-direction: column;
  margin: 12px 0 24px 0;
}

.review__row {
  width: 100%;
  display: flex;
  align-items: center;
}

.review__row:first-child {
  padding: 0 0 12px 0;
}

.review__row:last-child {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.review__row:first-child span {
  color: #e2dfcf; 
  opacity: 0.75;
}

.review__row span {
  color: #e2dfcf;
}

.review__img {
  width: 30%;
}

.review__img img {
  border-radius: 10px;
  padding: 0 6px 0 0;
}

.review__name {
  color: #e2dfcf;
  text-align: start;
  padding: 6px;
}

.review__name p {
  font-size: x-large;
  margin: 0;
  padding: 0;
}


.review__main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
}

.review__text {
  color: #e2dfcf;
  /* font-weight: 800; */
  font-size: larger;
}

}

@media (min-width: 768px) and (max-width: 1024px) {
  .carousel {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .carousel--item1 {
    height: auto;
    width: 75%;
    margin: 0 12px;
    position: absolute;
    z-index: 1010;
    opacity: 1;
    transform: translateY(-72px);
    transition: width 1s ease-in-out;
  }

  .carousel--item0,
  .carousel--item2 {
    height: auto;
    width: 45%;
    z-index: 1000;
    margin: 0 12px;
    opacity: 0.5;
    position: relative;
    border-radius: 5px;
    transition:
      width 1s ease-in-out,
      opacity 0.5 ease-in-out;
  }

  .carousel--item0:hover,
  .carousel--item2:hover {
    cursor: pointer;
    opacity: 0.75;
    transition: opacity 0.5s ease-in-out;
  }

  .carousel__outer {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 12px 24px;
    position: relative;
  }

  .carousel--item0 .carousel--header,
  .carousel--item2 .carousel--header {
    opacity: 0;
  }

  .carousel--item1 .carousel--header {
    color: #e2dfcf;
    opacity: 1;
    padding: 12px 0 0 0;
    transition: opacity 0.5s ease-in-out;
  }

  .carousel--button {
    height: 36px;
    width: 36px;
  }

  .flipped {
    transform: rotate(180deg);
  }

   /* Review Carousel */ 
 .review__section {
  width: 100%;
  min-height: 600px;
  height: auto;
  max-height: 750px;
  padding: 48px 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e2dfcf;
}

.button__wrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.review__wrapper {
  width: 80%;
  display: flex;
  justify-content: center;
}

.review__outer {
  min-width: 400px;
  max-width: 500px;
  padding: 30px 36px;
  border-radius: 30px;
  background-color: #004a22;
  display: flex;
  flex-direction: column;
  margin: 48px 18px;
}

.review__row {
  width: 100%;
  display: flex;
  align-items: center;
}

.review__row:first-child {
  padding: 0 0 12px 0;
}

.review__row:last-child {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.review__row:first-child span {
  color: #e2dfcf; 
  opacity: 0.75;
}

.review__row span {
  color: #e2dfcf;
}

.review__img {
  width: 30%;
}

.review__img img {
  border-radius: 10px;
  padding: 0 6px 0 0;
}

.review__name {
  color: #e2dfcf;
  text-align: start;
  padding: 6px;
}

.review__name p {
  font-size: x-large;
  margin: 0;
  padding: 0;
}


.review__main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
}

.review__text {
  color: #e2dfcf;
  /* font-weight: 800; */
  font-size: larger;
}

}

@media (min-width: 1025px) and (max-width: 1200px) {
  .carousel {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .carousel--item1 {
    height: auto;
    width: 60%;
    margin: 0 12px;
    position: absolute;
    z-index: 1010;
    opacity: 1;
    transform: translateY(-48px);
    transition: width 1s ease-in-out;
  }

  .carousel--item0,
  .carousel--item2 {
    height: auto;
    width: 45%;
    z-index: 1000;
    margin: 0 12px;
    opacity: 0.5;
    position: relative;
    border-radius: 5px;
    transition:
      width 1s ease-in-out,
      opacity 0.5 ease-in-out;
  }

  .carousel--item0:hover,
  .carousel--item2:hover {
    cursor: pointer;
    opacity: 0.75;
    transition: opacity 0.5s ease-in-out;
  }

  .carousel__outer {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 24px 48px;
    position: relative;
  }

  .carousel--item0 .carousel--header,
  .carousel--item2 .carousel--header {
    opacity: 0;
  }

  .carousel--item1 .carousel--header {
    color: #e2dfcf;
    opacity: 1;
    padding: 12px 0 0 0;
    transition: opacity 0.5s ease-in-out;
  }

  .carousel--button {
    height: 36px;
    width: 36px;
  }

  .flipped {
    transform: rotate(180deg);
  }

  /* Review Carousel */ 
 .review__section {
  width: 100%;
  min-height: 500px;
  height: auto;
  max-height: 600px;
  padding: 48px 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e2dfcf;
}

.button__wrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.review__wrapper {
  width: 80%;
  display: flex;
  justify-content: center;
}

.review__outer {
  min-width: 400px;
  max-width: 500px;
  padding: 30px 36px;
  border-radius: 30px;
  background-color: #004a22;
  display: flex;
  flex-direction: column;
  margin: 48px 18px;
}

.review__row {
  width: 100%;
  display: flex;
  align-items: center;
}

.review__row:first-child {
  padding: 0 0 12px 0;
}

.review__row:last-child {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.review__row:first-child span {
  color: #e2dfcf; 
  opacity: 0.75;
}

.review__row span {
  color: #e2dfcf;
}

.review__img {
  width: 30%;
}

.review__img img {
  border-radius: 10px;
  padding: 0 6px 0 0;
}

.review__name {
  color: #e2dfcf;
  text-align: start;
  padding: 6px;
}

.review__name p {
  font-size: x-large;
  margin: 0;
  padding: 0;
}


.review__main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
}

.review__text {
  color: #e2dfcf;
  /* font-weight: 800; */
  font-size: larger;
}
}

@media (min-width: 1201px) and (max-width: 1325px) {
  .carousel {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .carousel--item1 {
    height: auto;
    width: 60%;
    margin: 0 12px;
    position: absolute;
    z-index: 1010;
    opacity: 1;
    transform: translateY(-48px);
    transition: width 1s ease-in-out;
  }

  .carousel--item0,
  .carousel--item2 {
    height: auto;
    width: 45%;
    z-index: 1000;
    margin: 0 12px;
    opacity: 0.5;
    position: relative;
    border-radius: 5px;
    transition:
      width 1s ease-in-out,
      opacity 0.5 ease-in-out;
  }

  .carousel--item0:hover,
  .carousel--item2:hover {
    cursor: pointer;
    opacity: 0.75;
    transition: opacity 0.5s ease-in-out;
  }

  .carousel__outer {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 24px 48px;
    position: relative;
  }

  .carousel--item0 .carousel--header,
  .carousel--item2 .carousel--header {
    opacity: 0;
  }

  .carousel--item1 .carousel--header {
    color: #e2dfcf;
    opacity: 1;
    padding: 12px 0 0 0;
    transition: opacity 0.5s ease-in-out;
  }

  .carousel--button {
    height: 36px;
    width: 36px;
  }

  .flipped {
    transform: rotate(180deg);
  }

  /* Review Carousel */ 
 .review__section {
  width: 100%;
  min-height: 500px;
  height: auto;
  max-height: 600px;
  padding: 48px 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e2dfcf;
}

.button__wrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.review__wrapper {
  width: 80%;
  display: flex;
  justify-content: center;
}

.review__outer {
  min-width: 400px;
  max-width: 500px;
  padding: 30px 36px;
  border-radius: 30px;
  background-color: #004a22;
  display: flex;
  flex-direction: column;
  margin: 48px 18px;
}

.review__row {
  width: 100%;
  display: flex;
  align-items: center;
}

.review__row:first-child {
  padding: 0 0 12px 0;
}

.review__row:last-child {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.review__row:first-child span {
  color: #e2dfcf; 
  opacity: 0.75;
}

.review__row span {
  color: #e2dfcf;
}

.review__img {
  width: 30%;
}

.review__img img {
  border-radius: 10px;
  padding: 0 6px 0 0;
}

.review__name {
  color: #e2dfcf;
  text-align: start;
  padding: 6px;
}

.review__name p {
  font-size: x-large;
  margin: 0;
  padding: 0;
}


.review__main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
}

.review__text {
  color: #e2dfcf;
  /* font-weight: 800; */
  font-size: larger;
}
}

@media (min-width: 1325px) and (max-width: 1999px) {
  .carousel {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .carousel--item1 {
    height: auto;
    width: 60%;
    margin: 0 12px;
    position: absolute;
    z-index: 1010;
    opacity: 1;
    transform: translateY(-72px);
    transition: width 1s ease-in-out;
  }

  .carousel--item0,
  .carousel--item2 {
    height: auto;
    width: 45%;
    z-index: 1000;
    margin: 0 12px;
    opacity: 0.5;
    position: relative;
    border-radius: 5px;
    transition:
      width 1s ease-in-out,
      opacity 0.5 ease-in-out;
  }

  .carousel--item0:hover,
  .carousel--item2:hover {
    cursor: pointer;
    opacity: 0.75;
    transition: opacity 0.5s ease-in-out;
  }

  .carousel__outer {
    height: min-content;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
  }

  .carousel--item0 .carousel--header,
  .carousel--item2 .carousel--header {
    opacity: 0;
  }

  .carousel--item1 .carousel--header {
    color: #e2dfcf;
    opacity: 1;
    padding: 12px 0 0 0;
    transition: opacity 0.5s ease-in-out;
  }

  .carousel--button {
    height: 72px;
    width: 72px;
  }

  .flipped {
    transform: rotate(180deg);
  }

 /* Review Carousel */ 
 .review__section {
  width: 100%;
  min-height: 600px;
  height: auto;
  max-height: 750px;
  padding: 48px 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e2dfcf;
}

.button__wrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.review__wrapper {
  width: 80%;
  display: flex;
  justify-content: center;
}

.review__outer {
  min-width: 400px;
  max-width: 500px;
  padding: 30px 36px;
  border-radius: 30px;
  background-color: #004a22;
  display: flex;
  flex-direction: column;
  margin: 48px 18px;
}

.review__row {
  width: 100%;
  display: flex;
  align-items: center;
}

.review__row:first-child {
  padding: 0 0 12px 0;
}

.review__row:last-child {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.review__row:first-child span {
  color: #e2dfcf; 
  opacity: 0.75;
}

.review__row span {
  color: #e2dfcf;
}

.review__img {
  width: 30%;
}

.review__img img {
  border-radius: 10px;
  padding: 0 6px 0 0;
}

.review__name {
  color: #e2dfcf;
  text-align: start;
  padding: 6px;
}

.review__name p {
  font-size: x-large;
  margin: 0;
  padding: 0;
}


.review__main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
}

.review__text {
  color: #e2dfcf;
  /* font-weight: 800; */
  font-size: larger;
}

}

@media (min-width: 2000px) {
  .carousel {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .carousel--item1 {
    height: auto;
    width: 60%;
    margin: 0 12px;
    position: absolute;
    z-index: 1010;
    opacity: 1;
    transform: translateY(-96px);
    transition: width 1s ease-in-out;
  }

  .carousel--item0,
  .carousel--item2 {
    height: auto;
    width: 45%;
    z-index: 1000;
    margin: 0 12px;
    opacity: 0.5;
    position: relative;
    border-radius: 5px;
    transition:
      width 1s ease-in-out,
      opacity 0.5 ease-in-out;
  }

  .carousel--item0:hover,
  .carousel--item2:hover {
    cursor: pointer;
    opacity: 0.75;
    transition: opacity 0.5s ease-in-out;
  }

  .carousel__outer {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
  }

  .carousel--item0 .carousel--header,
  .carousel--item2 .carousel--header {
    opacity: 0;
  }

  .carousel--item1 .carousel--header {
    color: #e2dfcf;
    opacity: 1;
    padding: 12px 0 0 0;
    transition: opacity 0.5s ease-in-out;
  }

  .carousel--button {
    height: 72px;
    width: 72px;
  }

  .flipped {
    transform: rotate(180deg);
  }

 /* Review Carousel */ 
 .review__section {
  width: 100%;
  min-height: 600px;
  height: auto;
  max-height: 600px;
  padding: 48px 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e2dfcf;
}

.button__wrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.review__wrapper {
  width: 80%;
  display: flex;
  justify-content: center;
}

.review__outer {
  min-width: 400px;
  max-width: 500px;
  padding: 30px 36px;
  border-radius: 30px;
  background-color: #004a22;
  display: flex;
  flex-direction: column;
  margin: 48px 18px;
}

.review__row {
  width: 100%;
  display: flex;
  align-items: center;
}

.review__row:first-child {
  padding: 0 0 12px 0;
}

.review__row:last-child {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.review__row:first-child span {
  color: #e2dfcf; 
  opacity: 0.75;
}

.review__row span {
  color: #e2dfcf;
}

.review__img {
  width: 30%;
}

.review__img img {
  border-radius: 10px;
  padding: 0 6px 0 0;
}

.review__name {
  color: #e2dfcf;
  text-align: start;
  padding: 6px;
}

.review__name p {
  font-size: x-large;
  margin: 0;
  padding: 0;
}


.review__main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: start;
}

.review__text {
  color: #e2dfcf;
  /* font-weight: 800; */
  font-size: larger;
}

}