@media (max-width: 480px) {
  .questionContainer {
    height: 71vh;
    border-top: 3px solid whitesmoke;
    border-bottom: 3px solid whitesmoke;
    overflow: scroll;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #004a22;
  }

  .question {
    font-size: 2em;
    margin: 18px 24px 12px 24px;
    text-align: center;
    color: #e2dfcf;
  }

  .optionContainer {
    display: grid;
    height: auto;
    width: 95%;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    row-gap: 12px;
    grid-template-columns: 1fr;
    column-gap: 6px;
    margin: 24px 0 12px 0;
  }

  .optionOne {
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
    background-color: rgb(245, 245, 245);
    margin: 8px 12px 8px 12px;
    padding: 6px 10px 6px 10px;
    border-radius: 10px;
    font-size: 0.8em;
    color: #000;
  }

  /* .option:hover {
      background-color: green;
      color: #fff;
      cursor: pointer;
      box-shadow: 5px 5px 5px #fff;
  } */

  .optionTwo {
    grid-row: 2 / 2;
    grid-column: 1 / 1;
    background-color: rgb(245, 245, 245);
    margin: 8px 12px 8px 12px;
    padding: 6px 10px 6px 10px;
    border-radius: 10px;
    font-size: 0.8em;
    color: #000;
  }

  .optionThree {
    grid-row: 3 / 3;
    grid-column: 1 / 1;
    background-color: rgb(245, 245, 245);
    margin: 8px 12px 8px 12px;
    padding: 6px 10px 6px 10px;
    border-radius: 10px;
    font-size: 0.8em;
    color: #000;
  }

  .optionFour {
    grid-row: 4 / 4;
    grid-column: 1 / 1;
    background-color: rgb(245, 245, 245);
    margin: 8px 12px 8px 12px;
    padding: 6px 10px 6px 10px;
    border-radius: 10px;
    font-size: 0.8em;
    color: #000;
  }

  .checkAnswerButton {
    background-color: whitesmoke;
    color: #004a22;
    border: 1px solid #004a22;
    margin: 12px 6px 0 6px;
    font-weight: 600;
  }

  .btn-primary:hover {
    color: #e2dfcf;
    background-color: #004a22;
    border-color: #004a22;
    font-weight: 600;
  }

  .failingSpan {
    font-size: 0.8em;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .questionContainer {
    height: 71vh;
    border-top: 3px solid whitesmoke;
    border-bottom: 3px solid whitesmoke;
    overflow: scroll;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #004a22;
  }

  .titleDiv {
    min-height: 30vh;
    height: auto;
    width: 90%;
    background-color: whitesmoke;
    padding: 16px 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.2);
    margin: 1em;
    z-index: 10;
    color: #004a22;
    /* box-shadow: 0 10px 10px rgba(0,0,0,0.2); */
  }

  .question {
    font-size: 2em;
    margin: 18px 24px 12px 24px;
    text-align: center;
    color: #e2dfcf;
  }

  .optionContainer {
    display: grid;
    height: auto;
    width: 95%;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    row-gap: 12px;
    grid-template-columns: 1fr;
    column-gap: 6px;
    margin: 24px 0 12px 0;
  }

  .optionOne {
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
    background-color: rgb(245, 245, 245);
    margin: 8px 12px 8px 12px;
    padding: 6px 10px 6px 10px;
    border-radius: 10px;
    font-size: 0.8em;
    color: #000;
  }

  /* .option:hover {
      background-color: green;
      color: #fff;
      cursor: pointer;
      box-shadow: 5px 5px 5px #fff;
  } */

  .optionTwo {
    grid-row: 2 / 2;
    grid-column: 1 / 1;
    background-color: rgb(245, 245, 245);
    margin: 8px 12px 8px 12px;
    padding: 6px 10px 6px 10px;
    border-radius: 10px;
    font-size: 0.8em;
    color: #000;
  }

  .optionThree {
    grid-row: 3 / 3;
    grid-column: 1 / 1;
    background-color: rgb(245, 245, 245);
    margin: 8px 12px 8px 12px;
    padding: 6px 10px 6px 10px;
    border-radius: 10px;
    font-size: 0.8em;
    color: #000;
  }

  .optionFour {
    grid-row: 4 / 4;
    grid-column: 1 / 1;
    background-color: rgb(245, 245, 245);
    margin: 8px 12px 8px 12px;
    padding: 6px 10px 6px 10px;
    border-radius: 10px;
    font-size: 0.8em;
    color: #000;
  }

  .checkAnswerButton {
    background-color: whitesmoke;
    color: #004a22;
    border: 1px solid #004a22;
    margin: 12px 6px 0 6px;
    font-weight: 600;
  }

  .btn-primary:hover {
    color: #e2dfcf;
    background-color: #004a22;
    border-color: #004a22;
    font-weight: 600;
  }

  .failingSpan {
    font-size: 0.8em;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .questionContainer {
    height: 71vh;
    border-top: 3px solid whitesmoke;
    border-bottom: 3px solid whitesmoke;
    overflow: scroll;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #004a22;
  }

  .titleDiv {
    min-height: 30vh;
    height: auto;
    width: 90%;
    background-color: whitesmoke;
    padding: 16px 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.2);
    margin: 1em;
    z-index: 10;
    color: #004a22;
    /* box-shadow: 0 10px 10px rgba(0,0,0,0.2); */
  }

  .question {
    font-size: 2em;
    margin: 18px 24px 12px 24px;
    text-align: center;
    color: #e2dfcf;
  }

  .optionContainer {
    display: grid;
    height: auto;
    width: 95%;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    row-gap: 12px;
    grid-template-columns: 1fr;
    column-gap: 6px;
    margin: 24px 0 12px 0;
  }

  .optionOne {
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
    background-color: rgb(245, 245, 245);
    margin: 8px 12px 8px 12px;
    padding: 6px 10px 6px 10px;
    border-radius: 10px;
    font-size: 0.8em;
    color: #000;
  }

  /* .option:hover {
      background-color: green;
      color: #fff;
      cursor: pointer;
      box-shadow: 5px 5px 5px #fff;
  } */

  .optionTwo {
    grid-row: 2 / 2;
    grid-column: 1 / 1;
    background-color: rgb(245, 245, 245);
    margin: 8px 12px 8px 12px;
    padding: 6px 10px 6px 10px;
    border-radius: 10px;
    font-size: 0.8em;
    color: #000;
  }

  .optionThree {
    grid-row: 3 / 3;
    grid-column: 1 / 1;
    background-color: rgb(245, 245, 245);
    margin: 8px 12px 8px 12px;
    padding: 6px 10px 6px 10px;
    border-radius: 10px;
    font-size: 0.8em;
    color: #000;
  }

  .optionFour {
    grid-row: 4 / 4;
    grid-column: 1 / 1;
    background-color: rgb(245, 245, 245);
    margin: 8px 12px 8px 12px;
    padding: 6px 10px 6px 10px;
    border-radius: 10px;
    font-size: 0.8em;
    color: #000;
  }

  .checkAnswerButton {
    background-color: whitesmoke;
    color: #004a22;
    border: 1px solid #004a22;
    margin: 12px 6px 0 6px;
    font-weight: 600;
  }

  .btn-primary:hover {
    color: #e2dfcf;
    background-color: #004a22;
    border-color: #004a22;
    font-weight: 600;
  }

  .failingSpan {
    font-size: 0.8em;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .gridQUIZ {
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(200px, 1fr)
    ); /* Adjust as needed */
    gap: 10px; /* Adjust the gap between images */
    padding: 6px;
  }

  #rcQuiz {
    overflow: hidden;
  }

  .gridItem {
    position: relative;
    overflow: hidden;
  }

  .gridItem img {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }

  .questionContainer {
    grid-row: 1 / 11;
    grid-column: 1 / 51;
    border-top: 3px solid whitesmoke;
    border-bottom: 3px solid whitesmoke;
    overflow: scroll;
    border-radius: 5px 5px 0 0;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #004a22;
  }

  .question {
    font-size: 3em;
    margin: 0 48px;
    text-align: center;
  }

  .optionContainer {
    display: grid;
    height: auto;
    width: 75%;
    grid-template-rows: 1fr 1fr;
    row-gap: 12px;
    grid-template-columns: 1fr 1fr;
    column-gap: 12px;
    margin: 24px;
  }

  .optionOne {
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
    background-color: rgb(245, 245, 245);
    margin: 8px 12px 8px 12px;
    padding: 6px 10px 6px 10px;
    border-radius: 10px;
    font-size: 0.8em;
    color: #000;
  }

  .optionOne:hover,
  .optionTwo:hover,
  .optionThree:hover,
  .optionFour:hover {
    cursor: pointer;
  }

  .optionTwo {
    grid-row: 1 / 1;
    grid-column: 2 / 2;
    background-color: rgb(245, 245, 245);
    margin: 8px 12px 8px 12px;
    padding: 6px 10px 6px 10px;
    border-radius: 10px;
    font-size: 0.8em;
    color: #000;
  }

  .optionThree {
    grid-row: 2 / 2;
    grid-column: 1 / 1;
    background-color: rgb(245, 245, 245);
    margin: 8px 12px 8px 12px;
    padding: 6px 10px 6px 10px;
    border-radius: 10px;
    font-size: 0.8em;
    color: #000;
  }

  .optionFour {
    grid-row: 2 / 2;
    grid-column: 2 / 2;
    background-color: rgb(245, 245, 245);
    margin: 8px 12px 8px 12px;
    padding: 6px 10px 6px 10px;
    border-radius: 10px;
    font-size: 0.8em;
    color: #000;
  }

  .checkAnswerButton {
    background-color: rgb(245, 245, 245);
    color: #004a22;
    border: 1px solid #004a22;
    margin: 0 6px 0 6px;
    font-weight: bold;
  }

  .checkAnswerButton:hover {
    background-color: rgba(103, 36, 131);
    color: rgb(245, 245, 245);
    border: 1px solid #004a22;
    margin: 0 6px 0 6px;
  }

  .failingSpan {
    font-size: 0.8em;
  }
}

@media (min-width: 1201px) {
  .gridQUIZ {
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(200px, 1fr)
    ); /* Adjust as needed */
    gap: 10px; /* Adjust the gap between images */
    padding: 6px;
  }

  #rcQuiz {
    overflow: hidden;
  }

  .gridItem {
    position: relative;
    overflow: hidden;
  }

  .gridItem img {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }

  .questionContainer {
    grid-row: 1 / 11;
    grid-column: 1 / 51;
    border-top: 3px solid whitesmoke;
    border-bottom: 3px solid whitesmoke;
    overflow: scroll;
    border-radius: 5px 5px 0 0;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #004a22;
  }

  .question {
    font-size: 3em;
    margin: 0 48px;
    text-align: center;
    color: rgb(226, 223, 207);
  }

  .optionContainer {
    display: grid;
    height: auto;
    width: 75%;
    grid-template-rows: 1fr 1fr;
    row-gap: 12px;
    grid-template-columns: 1fr 1fr;
    column-gap: 12px;
    margin: 24px;
  }

  .optionOne {
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
    background-color: rgb(226, 223, 207);
    margin: 8px 12px 8px 12px;
    padding: 6px 10px 6px 10px;
    border-radius: 10px;
    font-size: 0.8em;
    color: #004a22;
  }

  .optionOne:hover,
  .optionTwo:hover,
  .optionThree:hover,
  .optionFour:hover {
    cursor: pointer;
  }

  .optionTwo {
    grid-row: 1 / 1;
    grid-column: 2 / 2;
    background-color: rgb(226, 223, 207);
    margin: 8px 12px 8px 12px;
    padding: 6px 10px 6px 10px;
    border-radius: 10px;
    font-size: 1em;
    color: #004a22;
  }

  .optionThree {
    grid-row: 2 / 2;
    grid-column: 1 / 1;
    background-color: rgb(226, 223, 207);
    margin: 8px 12px 8px 12px;
    padding: 6px 10px 6px 10px;
    border-radius: 10px;
    font-size: 0.8em;
    color: #004a22;
  }

  .optionFour {
    grid-row: 2 / 2;
    grid-column: 2 / 2;
    background-color: rgb(226, 223, 207);
    margin: 8px 12px 8px 12px;
    padding: 6px 10px 6px 10px;
    border-radius: 10px;
    font-size: 0.8em;
    color: #004a22;
  }

  .checkAnswerButton {
    background-color: rgb(226, 223, 207);
    color: #004a22;
    border: 1px solid #004a22;
    margin: 0 6px 0 6px;
    font-weight: bold;
  }

  .checkAnswerButton:hover {
    background-color: rgba(103, 36, 131);
    color: rgb(226, 223, 207);
    border: 1px solid #004a22;
    margin: 0 6px 0 6px;
  }

  .failingSpan {
    font-size: 0.8em;
  }
}
